import React from 'react';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

export const UserLimit = ({userLimitPopupShow, setuserLimitPopupShow, handleUserLimitPopupClose, ...rest}) => {
  
    return (
        <Modal show={userLimitPopupShow} onHide={handleUserLimitPopupClose} dialogClassName="">
            <form>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">Upgrade Plan</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleUserLimitPopupClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid container spacing={12}>
                        <Grid item className='fs-13'> 
                            You have reached the max users limit as per your current plan &nbsp;
                            <Link className='ft-color ft' to={'/profile?activeTab=info'}><span> Update Plan</span></Link> 
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='small' type='reset' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleUserLimitPopupClose}>
                        Close
                    </Button>             
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default UserLimit;
UserLimit.propTypes = {
    userLimitPopupShow: PropTypes.bool.isRequired,
    setuserLimitPopupShow: PropTypes.func.isRequired,
    handleUserLimitPopupClose: PropTypes.func.isRequired    
};