import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { read, utils } from 'xlsx';

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

const validationSchema = yup.object({
    name: yup.string('Enter your name').required('Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required')
});

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {
    const [users, setUsers] = useState([]);
    const initialValues = {
        id: editId,
        managerId: 0,
        name: '',
        email: '',
        phone: '',
        
    }

    const formik = useFormik({
        initialValues: initialValues,
        //validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });
    
    const handleSubmit = (values)=>{
        // setLoading(true);
        const file = values.users_list;
        console.log(file,'file')
        if(file){
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                const fileName = file.name;
                const fileExtension = fileName.split('.').pop();
                // console.log(fileExtension);
                // return false;
                if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        // console.log(rows,'row');
                        rows.push({
                            parentId: users.id,
                        });
                            axios.post(`api/users/multiplecreate`, {users: rows}).then(res => {
                            if(res.data.success){
                                setShow(false);
                                Swal.fire(res.data.message, '', 'success').then(result => {
                                    getList();  
                                })                
                            } 
                            else{
                                Swal.fire(res.data.message, '', 'error')
                            }
                        }).catch((error) => {
                            Swal.fire(error.response.data.message, '', 'error')
                        });
                    
                    }
                } else {
                    Swal.fire('Must be Upload excel file', '', 'error')
                }
            }
            reader.readAsArrayBuffer(file);
        }
    
    }
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">Upload Excel</h5>
                    <a href="./documents/userlist.xlsx" className="fs-14" download>Download Sample File</a>
                    
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid className='text-center border-0' container spacing={12}>
                        <Grid  item xs={12} sm={6}>
                            <TextField fullWidth
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                size='small'
                                type="file"
                                accept={".csv, .xlsx, .xls"}
                                name= 'users_list'
                                placeholder="Please select the product list"
                                className={formik.touched.users_list && formik.errors.users_list ?'is-invalid':''}
                                onChange={(e) => {
                                    formik.setFieldValue('users_list', e.currentTarget.files[0]);
                                    
                                    }}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='small' type='reset' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleClose}>
                        Close
                    </Button>
                    <Button  size='small' type="submit" variant='contained'>
                       Upload
                    </Button>              
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};