import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';

// ** MUI Imports
import {Button, Grid, MenuItem, TextField, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import * as yup from 'yup';

// const validationSchema = yup.object({
//     questionTypeId: yup.string().required('Question Type is required'),
//     name: yup.string('Enter your name').required('Name is required')
// });
const validationSchema = yup.object({
    questionTypeId: yup.string().required('Question Type is required'),
    name: yup.string('Enter your name').required('Name is required'),
    // options: yup.array().of(
    //     yup.object({
    //         optionName: yup.string().required('Option Name is required'),
    //         optionScore: yup.number().required('Score is required')//.positive('Score must be positive'),
    //     })
    //   ).when('questionTypeId', {
    //     is: (typeId) => [3, 4].includes(typeId),
    //     then: yup.array().min(1, 'At least one option is required'),
    //   }),
    options: yup.array().when('questionTypeId', {
        is: (typeId) => [3, 4].includes(Number(typeId)),
        then: yup.array().of(
            yup.object({
                optionName: yup.string().required('Option Name is required'),
                optionScore: yup.number().required('Score is required')
            })
        ).min(1, 'At least one option is required'),
        otherwise: yup.array().notRequired()
    })
  });
// const initialRows = [{ id: 1 }];
export const EditQuestion = ({showQuestion, setShowQuestion, handleQuestionClose, getList, editQuestionId, setEditQuestionId, ...rest}) => {     

    const [questionTypes, setQuestionTypes] = useState([]);
    // const [rows, setRows] = useState(initialRows);

    const isQuestionTypeWithOptions = (questionTypeId) => {
        // Replace this logic with your own check to determine if the question type requires options
        return [3, 4].includes(Number(questionTypeId));
    };
    const initialValues = {
        id: editQuestionId,
        questionTypeId: '',
        name: '',
        // options: isQuestionTypeWithOptions(editQuestionId) ? [{ optionName: '', optionScore: '' }] : []
        options: [{ optionName: '', optionScore: '' }]
      };
    

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });
  
    useEffect(() => {
        if(editQuestionId > 0){
            getQuestionTypes();
            axios.get(`api/question?id=${editQuestionId}`).then(async res => {
                if(res.data.success){
                    formik.setFieldValue('id', res.data.data.id)
                    formik.setFieldValue('name', res.data.data.name)
                    formik.setFieldValue('questionTypeId', res.data.data.questionTypeId)
                    formik.setFieldValue('options', isQuestionTypeWithOptions(res.data.data.questionTypeId) ? res.data.data.question_options : [] )
                }
            })
        }
        else{
            formik.setFieldValue('id', 0)
            formik.setFieldValue('name', '')
            formik.setFieldValue('questionTypeId', '')
            formik.setFieldValue('options', [])
        }
    },[showQuestion]);

    const getQuestionTypes = async() => {
        await axios.get('/api/questions/types')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setQuestionTypes(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }

    const handleSubmit = (values) =>{
        if(editQuestionId > 0){
            console.log(values);
            axios.put(`api/questions/update`, values).then(res => {
                if(res.data.success){
                    setShowQuestion(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/questions/create`, values).then(res => {
                if(res.data.success){
                    setShowQuestion(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }

    const handleAddMore = () => {
        // const newRow = { id: new Date().getTime() };
        // setRows((prevRows) => [...prevRows, newRow]);
        const newOption = [...formik.values.options];
        newOption.push({ optionName: '', optionScore: '' });
        formik.setFieldValue('options', newOption);
      };

    const handleRemoveOption = (index) => {
        // console.log(formik.values.options)
        const newOptions = [...formik.values.options];
        newOptions.splice(index, 1);
        formik.setFieldValue('options', newOptions);
    };

    return (
        <Modal show={showQuestion} onHide={handleQuestionClose} dialogClassName="modal-md">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">Edit Question</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleQuestionClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid item xs={12} sm={12} sx={{ marginBottom: 5.5 }}>
                        <TextField fullWidth
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            size='small'
                            id='name'
                            label='Question' 
                            placeholder='Question' 
                            value={formik.values.name} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>              
                    <Grid item xs={12} sm={12} sx={{ marginBottom: 5.5 }}>
                        <TextField fullWidth select
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            size='small'
                            label='Question Type'
                            id='questionTypeId'
                            value={formik.values.questionTypeId}
                            onChange={(e) => formik.setFieldValue('questionTypeId', e.target.value)}
                            error={formik.touched.questionTypeId && Boolean(formik.errors.questionTypeId)}
                            helperText={formik.touched.questionTypeId && formik.errors.questionTypeId}
                        >
                            <MenuItem value={""}>Please Select</MenuItem>
                            {
                                questionTypes.length > 0 ? 
                                questionTypes.map(questionType => (
                                    <MenuItem key={questionType.id} value={questionType.id}>{questionType.name}</MenuItem>
                                ))
                                : ''
                            }
                        </TextField>
                    </Grid> 
                    {[3, 4].includes(formik.values.questionTypeId) && (
                    <>
                    {formik.values.options.map((option,index) => (
                        <div key={index}>
                        <Grid id={`choice_${index}`} item xs={12} sm={12} sx={{ marginBottom: 2 }}>
                            <TextField
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                                marginLeft: 2
                            }}
                            size='small'
                            item
                            xs={6}
                            sm={6}
                            id={`optionName_${index}`}
                            label='Name'
                            value={option.optionName}
                            onChange={(e) => formik.setFieldValue(`options[${index}].optionName`, e.target.value)}
                            error={formik.touched.options && formik.touched.options[index] && Boolean(formik.errors.options?.[index]?.optionName)}
                            helperText={formik.touched.options && formik.touched.options[index] && formik.errors.options?.[index]?.optionName}
                  
                           />
                            <TextField
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                                marginLeft: 2
                            }}
                            type='number'
                            size='small'
                            item
                            xs={6}
                            sm={6}
                            id={`optionScore_${index}`}
                            label='Score'
                            value={option.optionScore}
                            onChange={(e) => formik.setFieldValue(`options[${index}].optionScore`, e.target.value)}
                            error={formik.touched.options && formik.touched.options[index] && Boolean(formik.errors.options?.[index]?.optionScore)}
                            helperText={formik.touched.options && formik.touched.options[index] && formik.errors.options?.[index]?.optionScore}                  
                           />
                           { index > 0 ? 
                                <Tooltip size='small' className='bg-theme text-white' title="Delete Option" sx={{ marginLeft: 2 }}> 
                                    <IconButton size='small' onClick={() => handleRemoveOption(index)}><DeleteIcon /></IconButton> 
                                </Tooltip>
                            :""
                           }
                        </Grid>
                        </div>
                    ))}
                    <Button size='small'sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleAddMore}>
                        add more
                    </Button>
                    </>
                )}
                </Modal.Body>
                <Modal.Footer>
                    <Button size='small'type='reset' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleQuestionClose}>
                        Close
                    </Button>
                    <Button size='small'type='submit' variant='contained'>Update</Button>               
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default EditQuestion;
EditQuestion.propTypes = {
    showQuestion: PropTypes.bool.isRequired,
    setShowQuestion: PropTypes.func.isRequired,
    handleQuestionClose: PropTypes.func.isRequired    
};