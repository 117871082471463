import { useEffect } from "react";
import { useParams, Link, useNavigate, } from 'react-router-dom';
import axios from 'axios';
import BlankLayout from '../Layout/BlankLayout'
import {Box, CardContent, Typography, Button, styled} from '@mui/material'
import MuiCard from '@mui/material/Card'
import FooterIllustrationsV1 from './FooterIllustration'
import Swal from 'sweetalert2';

const Card = styled(MuiCard)(({ theme }) => ({    
  [theme.breakpoints.up('sm')]: { width: '28rem' }
}))


const CheckoutSuccess = () => {
  const { sessionId } = useParams();  
  const navigate = useNavigate();

  useEffect(() => {
    console.log("sessionId==",sessionId)
    getList()
  }, [sessionId])

  const getList = async() => {
    await axios.put(`/api/plans/saveStripePaymentResponse`,{sessionId:sessionId})
    .then(function (response) {
        if(response.data.success){
            // Swal.fire('Registration Process Completed Successfully', '', 'success').then(result => {
            Swal.fire(response.data.message, '', 'success').then(result => {
                localStorage.setItem('authUser', JSON.stringify(response.data.data));
                navigate('/dashboard');
            })                
        }
        else{
            Swal.fire(response.data.message, '', 'info')
        }
      }).catch((error) => {
          Swal.fire(error.message, '', 'info')        
      });
  }

  return (
    <BlankLayout>
      <Box className='content-center'>
          <Card sx={{ zIndex: 1 }}>
              <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link to="/"><img src={process.env.PUBLIC_URL+"/images/logo.png"} alt="..." width="150"/></Link>
                </Box>
                <Box sx={{ mb: 6 }}>
                    <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                    Checkout Successful
                    </Typography>
                    <Typography variant='body2'>Incase of any inqueries contact the support at{" "}
                      <strong>support@actiknow.com</strong>
                    </Typography>
                </Box>
                <Link to="/" > Home </Link>
              </CardContent>
          </Card>
          {/* <FooterIllustrationsV1 /> */}
      </Box>
    </BlankLayout>
  );
};

export default CheckoutSuccess;

