import { Link } from "react-router-dom";

export const Process = () => {
    return (
     <section className="py-5 bg-theme map-bg">
        <div className="container text-center">
            <div className="row mb-4">
                <div className="col-lg-9 mx-auto text-white">
                    <p className="h6 text-uppercase">Ready to Transform Your Feedback Process?</p>
                    <h2 className="mb-3">Schedule a Demo Today</h2>
                    <p className="mb-0">Experience the power of PeopleAssay firsthand. Schedule a personalized demo with one of our experts and see how we can help you unlock your organization's full potential.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 mx-auto">
                    <Link href="#" className="btn btn-secondary reg_btn w-100 py-3">Get Started!</Link>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-lg-4">
                    <div className="card border-0">
                        <div className="card-body p-lg-5">
                             <img src="./images/4.png" alt="..."/>
                            <h4>Idea </h4>
                            <p className="fs-13 fw-500 text-muted mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card border-0">
                        <div className="card-body p-lg-5">
                             <img src="./images/5.png" alt="..."/>
                            <h4>Wireframing </h4>
                            <p className="fs-13 fw-500 text-muted mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card border-0">
                        <div className="card-body p-lg-5">
                             <img src="./images/5.png" alt="..."/>
                            <h4>Development </h4>
                            <p className="fs-13 fw-500 text-muted mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
    )
}

export default Process;