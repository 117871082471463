// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import KeyOutline from 'mdi-material-ui/KeyOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import LockOpenOutline from 'mdi-material-ui/LockOpenOutline'


import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';

const handleCheckCurrentPassword = async (password) => {
  let data = {}
  let userData = localStorage.getItem('authUser');
  userData = JSON.parse(userData);
  if(password){
    data.id = userData.id;
    data.password = password;
    let response = await axios.post(`/api/users/checkvalidatepassword`, data)
    let result = response.data;
    if(result.success && result.exists === true){
      return true
    }
    else {
      return false
    }
  }
  else{
    return false
  }
}

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Current Password is required')
    .test('is-valid', 'Current Password is wrong!', value => handleCheckCurrentPassword(value)),
    password: Yup.string()
    .min(8, 'New Password must be at least 8 characters')
    .required('New Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm New Password is required'),
});


const TabSecurity = () => {

  const [changePasswordStatus, setChangePasswordStatus] = useState('');
  const [changePasswordMsg, setChangePasswordMsg] = useState('');
  
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
      formik.resetForm(); 
    }
  });

  const handleSubmit = async (values) => {
    try {
      let res = await axios.post('/api/users/changepassword', values);
      if(res.data.success){
        Swal.fire(res.data.message, '', 'success')
      }
      else{
        Swal.fire(res.data.message, '', 'info')
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to update password', 'error');
    }
  }

  
  
  // ** States
  const [values, setValues] = useState({
    password: '',
    currentPassword: '',
    showNewPassword: false,
    confirmNewPassword: '',
    showCurrentPassword: false,
    showConfirmNewPassword: false
  })

  // // Handle Current Password
  // const handleCurrentPasswordChange = prop => event => {
  //   setValues({ ...values, [prop]: event.target.value })
  // }

  const handleClickShowCurrentPassword = () => {
    setValues({ ...values, showCurrentPassword: !values.showCurrentPassword })
  }

  // const handleMouseDownCurrentPassword = event => {
  //   event.preventDefault()
  // }

  // // Handle New Password
  // const handleNewPasswordChange = prop => event => {
  //   setValues({ ...values, [prop]: event.target.value })
  // }

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword })
  }

  // const handleMouseDownNewPassword = event => {
  //   event.preventDefault()
  // }

  // // Handle Confirm New Password
  // const handleConfirmNewPasswordChange = prop => event => {
  //   setValues({ ...values, [prop]: event.target.value })
  // }

  const handleClickShowConfirmNewPassword = () => {
    setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword })
  }

  // const handleMouseDownConfirmNewPassword = event => {
  //   event.preventDefault()
  // }

  return (
    <FormikProvider value={formik}>
    <form onSubmit={formik.handleSubmit}>
      <CardContent sx={{ paddingBottom: 0 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <FormControl fullWidth
                  ListboxProps={{
                    sx: { fontSize: 13,
                        fontFamily: 'Poppins' },
                  }}
                  sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: 13,
                        fontFamily: 'Poppins', // Add your desired font family
                        fontWeight: '500', // Add your desired font weight
                    },
                  }}
                >
                  <InputLabel size='small' htmlFor='account-settings-current-password'>Current Password</InputLabel>
                  <OutlinedInput
                    label='Current Password'
                    size='small'
                    name='oldPassword'
                    value={formik.values.oldPassword}
                    id='account-settings-current-password'
                    type={values.showCurrentPassword ? 'text' : 'password'}
                    // onChange={handleCurrentPasswordChange('currentPassword')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // onKeyUp={(e) => handleCheckCurrentPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          aria-label='toggle password visibility'
                          onClick={handleClickShowCurrentPassword}
                          // onMouseDown={handleMouseDownCurrentPassword}
                        >
                          {values.showCurrentPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                  />
                </FormControl>
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className='text-danger fs-13'>{formik.errors.oldPassword}</div>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth
                  ListboxProps={{
                    sx: { fontSize: 13,
                        fontFamily: 'Poppins' },
                  }}
                  sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: 13,
                        fontFamily: 'Poppins', // Add your desired font family
                        fontWeight: '500', // Add your desired font weight
                    },
                  }}
                >
                  <InputLabel size='small' htmlFor='account-settings-new-password'>New Password</InputLabel>
                  <OutlinedInput
                    label='New Password'
                    size='small'
                    name='password'
                    value={formik.values.password}
                    id='account-settings-new-password'
                    // onChange={handleNewPasswordChange('password')}
                    type={values.showNewPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onClick={handleClickShowNewPassword}
                          aria-label='toggle password visibility'
                          // onMouseDown={handleMouseDownNewPassword}
                        >
                          {values.showNewPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    }                    
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </FormControl>
                {formik.touched.password && formik.errors.password && (
                  <div className='text-danger fs-13'>{formik.errors.password}</div>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth
                  ListboxProps={{
                    sx: { fontSize: 13,
                        fontFamily: 'Poppins' },
                  }}
                  sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: 13,
                        fontFamily: 'Poppins', // Add your desired font family
                        fontWeight: '500', // Add your desired font weight
                    },
                  }}
                >
                  <InputLabel size='small' htmlFor='account-settings-confirm-new-password'>Confirm New Password</InputLabel>
                  <OutlinedInput
                    label='Confirm New Password'
                    size='small'
                    name='confirmNewPassword'
                    value={formik.values.confirmNewPassword}
                    id='account-settings-confirm-new-password'
                    type={values.showConfirmNewPassword ? 'text' : 'password'}
                    // onChange={handleConfirmNewPasswordChange('confirmNewPassword')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          aria-label='toggle password visibility'
                          onClick={handleClickShowConfirmNewPassword}
                          // onMouseDown={handleMouseDownConfirmNewPassword}
                        >
                          {values.showConfirmNewPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    }                   
                    error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                    helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                  />
                </FormControl>
                {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword && (
                  <div className='text-danger fs-13 mb-3'>{formik.errors.confirmNewPassword}</div>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid className='mb-3'
            item
            sm={6}
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img alt='avatar' height={200} src='/images/pages/pose-m-1.png' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <Button type='submit' size='small' variant='contained'>
              Save Changes
            </Button>           
          </Grid> 
      </CardContent>           
    </form>
    </FormikProvider>
  )
}

export default TabSecurity
