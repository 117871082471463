// ** React Imports
import { forwardRef, useEffect, useState } from 'react'

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import CardContent from '@mui/material/CardContent'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import axios from 'axios';
import Swal from 'sweetalert2'
// ** Third Party Imports
import DatePicker from 'react-datepicker'
import Carousel from 'react-bootstrap/Carousel';

// ** Styled Components
import DatePickerWrapper from '../styles/libs/react-datepicker'
import { useNavigate } from 'react-router-dom'
// var util = require('util');

const CustomInput = forwardRef((props, ref) => {
  return <TextField inputRef={ref} label='Birth Date' fullWidth {...props} />
})

const TabInfo = () => {
  // ** State  
  const navigate = useNavigate();
  const AuthUser = localStorage.getItem('authUser');
  const user = JSON.parse(AuthUser);
  // const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(user.user_plan  && Array.isArray(user.user_plan) ? user.user_plan[0] : (user.user_plan ? user.user_plan : []));
  const [isUpgrading, setIsUpgrading] = useState(false)  

  // useEffect(() => {
  //   getList()
  // }, [])

  // const getList = async() => {
  //   await axios.get('/api/plans')
  //   .then(function (response) {
  //       let result = response.data;
  //       if(result.success){
  //         setPlans(result.data)
  //       }
  //   })
  // }

  const handlePlanUpgrade = () => { 
    
    if(currentPlan && currentPlan.plan && currentPlan.plan.price === "0.00"){
      setIsUpgrading(true)
      axios.post(`/api/auth/reSelectPlan`, { userId: user.id })
      .then((response) => {
          console.log("reSelectPlan response===",response.data)
          if(response.data.success){
            if (response.data.data.verification_token) {
              navigate("/select_plan?id="+response.data.data.verification_token)
            }
          }
          else{
            setIsUpgrading(false)
            Swal.fire(response.data.message, '', 'info')
          }
      })
      .catch((err) =>{ 
        setIsUpgrading(false)
        console.log(err.message)
      });
    }
    else{    
      setIsUpgrading(true)
      axios.post(`/api/plans/create-customer-portal-session`, {
          userId: user.id,
          email: user.email,
          name: user.name,
      })
      .then((response) => {
          console.log("payment response===",response.data)
          if(response.data.success){
            if (response.data.session.url) {
              window.location.href = response.data.session.url;
            }
          }
          else{
            setIsUpgrading(false)
            Swal.fire(response.data.message, '', 'info')
          }
      })
      .catch((err) =>{ 
        setIsUpgrading(false)
        console.log(err.message)
      });
    }
  } 
  return (
    <Container>
      <Row className='align-items-center justify-content-md-between'>
        <Col md="7">
          <CardContent>
            <Row className='d-flex align-items-center justify-content-md-center'>
              <Col md="6">
                <div className='mb-3'>
                  <p className='fs-13 mb-0'><strong>Name:</strong></p>
                  <span className='fs-13'>{currentPlan && currentPlan.plan ? currentPlan.plan.name : ""}</span>
                </div>
              </Col>
              <Col md="6">
                <div className='mb-3'>
                    <p className='fs-13 mb-0'><strong>Price:</strong></p>
                  <span className='fs-13'>
                    {currentPlan && currentPlan.plan ? currentPlan.plan.currency=='usd'?"$":"₹" : ""}
                    {currentPlan && currentPlan.plan ? currentPlan.plan.price : 0}</span>
                </div>
              </Col>
              <Col md="6">
                <div className='mb-3'>
                  <p className='fs-13 mb-0'><strong>User:</strong></p>
                  <span className='fs-13'>
                    {
                      currentPlan && currentPlan.plan ? 
                      currentPlan.plan.noOfUsers > 1 ? currentPlan.plan.noOfUsers + " Users" : 
                        (currentPlan.plan.noOfUsers === 1 ? currentPlan.plan.noOfUsers : 0)+ " User"
                      :""
                    }</span>
                </div>
              </Col>
              <Col md="6">
                <div className='mb-3'>
                  <p className='fs-13 mb-0'><strong>Expire:</strong></p>
                  <span className='fs-13'>
                    {currentPlan && currentPlan.plan ? 
                      currentPlan.plan.price === "0.00" ? "NA" :
                        new Date(currentPlan.endDate).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric"
                        })                   
                    : ""}
                  </span>
                </div>
              </Col>
              <Col md="12">
                <div className='mb-3'>
                  <p className='fs-13 mb-0'><strong>Description:</strong></p>
                  <span className='fs-13'>{currentPlan && currentPlan.plan ? currentPlan.plan.description : ""}</span>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Col>
        <Col className='text-center mb-3'  md="5"> 
          {/* <Carousel data-bs-theme="dark">  
          {
            plans.map((plan) =>{          
              return <Carousel.Item key={plan.id}>
                <CardContent className='px-5'>
                    <label className="card ss-label rounded mb-3">
                      <span className="plan-details">
                          <span className='d-flex align-items-center justify-content-between border-bottom border-2'>
                            <span className="plan-type fs-20">{plan.name}</span>
                            <span className="plan-cost fs-20">{plan.currency=='usd'?"$":"₹"}{plan.price}
                                <span className="slash">/</span><span className="plan-cycle">month
                                </span>
                            </span>
                          </span>
                          <span className="fs-13 my-3 text-center">{plan.description}</span>
                          <span className="fs-13 fw-semibold text-center">Upto {plan.noOfUsers} User(s) </span>
                      </span>
                    </label>
                    <Grid item xs={12} className='text-center d-grid'>
                      <Button variant='contained' className='fw-semibold' onClick={()=>handlePlanUpgrade(plan.id)} disabled={isUpgrading}>
                        Upgrade Now!
                      </Button>
                    </Grid>
                </CardContent>
              </Carousel.Item>
            })
          }
          </Carousel> */}
            <Button variant='contained' className='fw-semibold px-5 py-3' onClick={handlePlanUpgrade} disabled={isUpgrading}>
              Upgrade Plan!
            </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default TabInfo
