// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import BlankLayout from '../Layout/BlankLayout'
import FooterIllustrationsV1 from './FooterIllustration'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom'
import axios from "axios";
import Swal from 'sweetalert2';
// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({    
    [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const LinkStyled = styled('a')(({ theme }) => ({
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: theme.palette.primary.main
}))

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required')
});

export default function ForgotPassword() {
    const navigate = useNavigate();
    const initialValues = {
        email: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        axios.post('/api/users/forgotpassword', values).then((res) =>{
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate("/login")                 
                })                
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    return (
        
            <BlankLayout>
            <Box className='content-center'>
                <Card sx={{ zIndex: 1 }}>
                    <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                        <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Link to="/"><img src="./images/logo.png" alt="..." width="150"/></Link>              
                        </Box>
                        <Box sx={{ mb: 6 }}>
                            <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                            Recover your password
                            </Typography>
                            {/* <Typography variant='body2'>We have just sent verification code to your email id</Typography> */}
                        </Box>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <TextField autoFocus fullWidth 
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                    marginBottom: 4
                                }}
                                id='Email ID'
                                name='email'
                                label='Enter your email id' 
                                value={formik.values.email} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <Button 
                                fullWidth
                                type='submit'
                                size='large'
                                variant='contained'
                                sx={{ marginBottom: 7 }}
                                >
                                Submit
                            </Button>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                                {/* <Typography variant='body2' sx={{ marginRight: 2 }}>
                                    Already have an account?
                                </Typography> */}
                                <Typography variant='body2'>
                                    <Link to='/login'>
                                        <LinkStyled>Back to login</LinkStyled>
                                    </Link>
                                </Typography>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
                <FooterIllustrationsV1 />
            </Box>
        </BlankLayout>
        
        
        
    )
}