import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import { Grid, TextField, Checkbox} from '@mui/material';

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {  
    const [form, setForm] = useState({
        id: editId,
        name: '',
        noOfUsers: '',
        price: '',
        description: ''
    });   
    
    useEffect(() => {
        if(editId > 0){
            axios.get(`api/plan?id=${editId}`).then(res => {
                // console.log(res);
                if(res.data.success){
                    setForm({...form, id: editId, name: res.data.data.name,noOfUsers: res.data.data.noOfUsers,price: res.data.data.price,description: res.data.data.description})
                }
            })
        }
        else{
            setForm({...form, id: editId, name: '',noOfUsers: '',price: '',description: ''})
        }
        
    },[editId]);
    
    const handleSave = () =>{
        console.log(form)
        // return
        if(editId > 0){
            axios.put(`api/plans/update`, form).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/plans/create`, form).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                        setForm({name: ''});
                        setForm({noOfUsers: ''});
                        setForm({price: ''});
                        setForm({description: ''});
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9999}}>
            <form>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">{type} Subscription Plans</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: 5.5 }}>
                        <TextField
                        ListboxProps={{
                            sx: { fontSize: 13,
                                fontFamily: 'Poppins' },
                        }}
                        sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: 13,
                                fontFamily: 'Poppins', // Add your desired font family
                                fontWeight: '500', // Add your desired font weight
                            },
                        }}
                        fullWidth label='Plan Name' placeholder='Plan Name' size='small' value={form.name} onChange={(e) => setForm({...form, name: e.target.value})} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: 5.5 }}>
                        <TextField
                        ListboxProps={{
                            sx: { fontSize: 13,
                                fontFamily: 'Poppins' },
                        }}
                        sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: 13,
                                fontFamily: 'Poppins', // Add your desired font family
                                fontWeight: '500', // Add your desired font weight
                            },
                        }}
                        fullWidth label='Price' placeholder='Price' size='small' value={form.price} onChange={(e) => setForm({...form, price: e.target.value})} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: 5.5 }}>
                        <TextField
                        ListboxProps={{
                            sx: { fontSize: 13,
                                fontFamily: 'Poppins' },
                        }}
                        sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: 13,
                                fontFamily: 'Poppins', // Add your desired font family
                                fontWeight: '500', // Add your desired font weight
                            },
                        }}
                        fullWidth type="number" label='No of Users' placeholder='No of Users' size='small' value={form.noOfUsers} onChange={(e) => setForm({...form, noOfUsers: e.target.value})}/>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginBottom: 5.5 }}>
                        <TextField
                        ListboxProps={{
                            sx: { fontSize: 13,
                                fontFamily: 'Poppins' },
                        }}
                        sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: 13,
                                fontFamily: 'Poppins', // Add your desired font family
                                fontWeight: '500', // Add your desired font weight
                            },
                        }}
                        fullWidth label='Description' placeholder='Description' size='small' value={form.description} onChange={(e) => setForm({...form, description: e.target.value})}/>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='reset' sx={{ marginRight: 3.5 }} size='small' variant='outlined' onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant='contained' size='small' onClick={handleSave}>
                        {type == 'Add' ? 'Add' : 'Update'}
                    </Button>              
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};