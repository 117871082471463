// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import TextField from "@mui/material/TextField";

const CardNavigationCenter = () => {
  // ** State
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [rating, setRating] = useState(2);
  const [hoverRating, setHoverRating] = useState(-1);
  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  return (
    <TabContext value={value}>
      <TabList
        centered
        onChange={handleChange}
        aria-label="card navigation example"
      >
        <Tab value="1" label="Competency 1" />
        <Tab value="2" label="Competency 2" />
        <Tab value="3" label="Competency 3" />
      </TabList>
      <CardContent>
        <TabPanel value="1" sx={{ p: 3 }}>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 1 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <Rating
                name="hover-feedback"
                value={rating}
                precision={1}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHoverRating(newHover);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {rating !== null && (
                <Box sx={{ ml: 2 }}>
                  {labels[hoverRating !== -1 ? hoverRating : rating]}
                </Box>
              )}
            </div>
          </div>
          <hr></hr>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 2 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <TextField id="outlined-basic" label="Type here" variant="outlined" /> 
            </div>
          </div>
          <hr></hr>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 3 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="yes-button" id="yes-button"/>
                <label className="button-label" for="yes-button">
                  <span>Yes</span>
                </label>
                <input className="hidden radio-label" type="radio" name="yes-button" id="no-button"/>
                <label className="button-label" for="no-button">
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="text-end mt-5">
            <Button variant="contained">Save & Next</Button>
          </div>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 3 }}>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 1 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <Rating
                name="hover-feedback"
                value={rating}
                precision={1}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHoverRating(newHover);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {rating !== null && (
                <Box sx={{ ml: 2 }}>
                  {labels[hoverRating !== -1 ? hoverRating : rating]}
                </Box>
              )}
            </div>
          </div>
          <hr></hr>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 2 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <TextField id="outlined-basic" label="Type here" variant="outlined" /> 
            </div>
          </div>
          <hr></hr>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 3 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="yes-button" id="yes-button"/>
                <label className="button-label" for="yes-button">
                  <span>Yes</span>
                </label>
                <input className="hidden radio-label" type="radio" name="yes-button" id="no-button"/>
                <label className="button-label" for="no-button">
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="text-end mt-5">            
            <Button variant="contained">Save & Next</Button>
          </div>
        </TabPanel>
        <TabPanel value="3" sx={{ p: 3 }}>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 1 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <Rating
                name="hover-feedback"
                value={rating}
                precision={1}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHoverRating(newHover);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {rating !== null && (
                <Box sx={{ ml: 2 }}>
                  {labels[hoverRating !== -1 ? hoverRating : rating]}
                </Box>
              )}
            </div>
          </div>
          <hr></hr>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 2 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <TextField id="outlined-basic" label="Type here" variant="outlined" /> 
            </div>
          </div>
          <hr></hr>
          <div className="row justify-content-between">
            <div className="col-md-7">
              <Typography variant="body" sx={{ marginBottom: 2 }}>
                <span className="que-title">Question 3 :</span> Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                dummy text of the printing and typesetting
              </Typography>
            </div>
            <div className="col-md-3 text-end">
              <div className="button-wrap">
                <input className="hidden radio-label" type="radio" name="yes-button" id="yes-button"/>
                <label className="button-label" for="yes-button">
                  <span>Yes</span>
                </label>
                <input className="hidden radio-label" type="radio" name="yes-button" id="no-button"/>
                <label className="button-label" for="no-button">
                  <span>No</span>
                </label>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="text-end mt-5">
             {/* <Button variant="contained" sx={{ marginRight: 3 }}>
                Previous
            </Button> */}
            <Button variant="contained">Submit</Button>
          </div>
        </TabPanel>
      </CardContent>
    </TabContext>
  );
};

export default CardNavigationCenter;