// ** React Imports
import { useState, useEffect } from "react";
import axios from "axios";

import TextField from "@mui/material/TextField";

const FreeTextQuestions = ({ index, answerKey, survey_competency_question, currentSessionDetails, filledAnswers, setTextAnswers, currentSurveyUserId}) => {
    const authUser = localStorage.getItem("authUser");
    const loginUser = JSON.parse(authUser);
    // const [textAnswers, setTextAnswers] = useState({});

    // this is temprarry user id 
    const user_id = currentSurveyUserId ? currentSurveyUserId : loginUser.id;

    const handleTextChange = (questionId, event) => {
        setTextAnswers(filledAnswers => {
            const existingAnswerIndex = filledAnswers.findIndex(answer => answer.questionId === questionId);
    
            if (existingAnswerIndex !== -1) {
                const updatedAnswers = [...filledAnswers];
                updatedAnswers[existingAnswerIndex] = {
                    userId: user_id,
                    sessionId: currentSessionDetails.id,
                    surveyId: currentSessionDetails.surveyId,
                    questionId: questionId,
                    surveyAnswer: event.target.value,
                    createdBy: loginUser.id
                };
                return updatedAnswers;
            } else {
                const newAnswer = {
                    userId: user_id,
                    sessionId: currentSessionDetails.id,
                    surveyId: currentSessionDetails.surveyId,
                    questionId: questionId,
                    surveyAnswer: event.target.value,
                    createdBy: loginUser.id
                };
                return [...filledAnswers, newAnswer];
            }
        });
    };

    const filledValue = (questionId) => {
        const existingAnswerIndex = filledAnswers.findIndex(answer => answer.questionId === questionId);
        if (existingAnswerIndex !== -1) {
            return filledAnswers[existingAnswerIndex].surveyAnswer
        }
        else{
            return 'N/A'
        }
    }  
    
    return (<div className="col-md-12 mt-2">
    <TextField
        id={`text-field-${answerKey}`}
        label="Type here"
        variant="outlined"
        size="small"
        multiline
        fullWidth
        rows={3}
        listboxprops={{
            sx: { fontSize: 13,
                fontFamily: 'Poppins' },
        }}
        sx={{
            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                fontSize: 13,
                fontFamily: 'Poppins', // Add your desired font family
                fontWeight: '500', // Add your desired font weight
            },
        }}
        value={filledValue(survey_competency_question.question.id)}
        onChange={(event, value) => handleTextChange(survey_competency_question.question.id, event)}
    />
</div>);
};

export default FreeTextQuestions;