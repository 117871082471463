import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import ThemeComponent from '../../theme/ThemeComponent'

import themeConfig from '../../configs/themeConfig'
import VerticalLayout from './VerticalLayout'
// ** Navigation Imports
import VerticalNavItems from '../../navigation/vertical'
import VerticalAppBarContent from './components/vertical/AppBarContent'

const UserLayout = ({ children }) => {
  const authUser = localStorage.getItem('authUser');
  const user = JSON.parse(authUser);
  
  const initialSettings = {
    themeColor: 'primary',
    mode: user.themeMode ? user.themeMode : themeConfig.mode,
    contentWidth: themeConfig.contentWidth
  }
    
  const [settings, setSettings] = useState({...initialSettings})

  // const hidden = useMediaQuery(theme => theme.breakpoints.down('lg') )
  const hidden = false;
    
  return (
    <ThemeComponent settings={settings}>
      <VerticalLayout
        hidden={hidden}
        settings={settings}
        saveSettings={setSettings}
        verticalNavItems={VerticalNavItems()} // Navigation Items
        // afterVerticalNavMenuContent={UpgradeToProImg}
        verticalAppBarContent={(
          props // AppBar Content
        ) => (
          <VerticalAppBarContent
            hidden={hidden}
            settings={settings}
            saveSettings={setSettings}
            toggleNavVisibility={props.toggleNavVisibility}
          />
        )}
      >
        {children}
      </VerticalLayout>
    </ThemeComponent>
  )
}

export default UserLayout
