// ** React Imports
// import { useState } from "react";
import { useState, useEffect } from "react";
// ** MUI Imports
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import BarChart from './charts/barchart'
import axios from 'axios'
import DoughnutChart from './charts/doughnutchart'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import StrongestComponent from './charts/StrongestComponent';
import WeekestComponent from './charts/WeekestComponent';
import PieComponent from "./charts/PieComponent";
import SmallChartComponent from "./charts/SmallChartComponent";
import SmallBarChart from "./charts/SmallBarChart";
import NewRadialBar from "./charts/NewRadialBar";

const CardNavigationCenter = ({ currentSessionId, currentUserId }) => {
  // ** State
  const [value, setValue] = useState("1");

  const [executiveSummary, setExecutiveSummary] = useState([]);
  const [highLowScore, setHighLowScore] = useState([]);
  const [positiveNegativeGap, setPositiveNegativeGap] = useState([]);
  const [detailedResults, setDetailedResults] = useState([]);
  const [domainSummary, setDomainSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    getExecutiveSummaryList();
    getDomainSummary();
    getHighandLowScoreList();
    getPositiveandNegativeGapList();
    getDetailedResults();
    // getSessionSettings();
  }, [currentSessionId, currentUserId]);

  const getExecutiveSummaryList = async () => {
    try {
      let conditions = `?session_id=${currentSessionId}`;
      conditions += currentUserId ? `&userId=${currentUserId}` : '';
      const response = await axios.get(`/api/excutive_summary${conditions}`);
      const result = response.data;
      if (result.success) {
        setExecutiveSummary(result.data);
        // console.log('result', result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getHighandLowScoreList = async () => {
    try {
      let conditions = `?session_id=${currentSessionId}`;
      conditions += currentUserId ? `&userId=${currentUserId}` : '';
      const response = await axios.get(`/api/high_low_score${conditions}`);
      const result = response.data;
      if (result.success) {
        setHighLowScore(result.data);
        // console.log('result', result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getPositiveandNegativeGapList = async () => {
    try {
      let conditions = `?session_id=${currentSessionId}`;
      conditions += currentUserId ? `&userId=${currentUserId}` : '';
      const response = await axios.get(`/api/possitive_negative_gap${conditions}`);
      const result = response.data;
      if (result.success) {
        setPositiveNegativeGap(result.data);
        // console.log('result', result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getDetailedResults = async () => {
    try {
      let conditions = `?session_id=${currentSessionId}`;
      conditions += currentUserId ? `&userId=${currentUserId}` : '';
      const response = await axios.get(`/api/detailed_result${conditions}`);
      const result = response.data;
      if (result.success) {
        setDetailedResults(result.data);
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const getDomainSummary = async () => {
    try {
      let conditions = `?session_id=${currentSessionId}`;
      conditions += currentUserId ? `&userId=${currentUserId}` : '';
      const response = await axios.get(`/api/summary_domain${conditions}`);
      const result = response.data;
      if (result.success) {
        setDomainSummary(result.data);
      }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSessionSettings = async () => {
    try {
      const response = await axios.get(`/api/surveyratingsettings/findSessionSettings?sessionId=${currentSessionId}`);
      const result = response.data;
      console.log("========result=============", result)
      // if (result.success) {
      //   setDomainSummary(result.data);
      // }
    } catch (error) {
      console.error('Error fetching executive summary:', error);
    }
  }

  return (
    isLoading ?
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "150px" }}>
        <CircularProgress />
      </Box>
      :
      <TabContext value={value}>
        <TabList className="mb-3" centered onChange={handleChange} aria-label="card navigation example">
          <Tab value="1" className="text-capitalize" label="Overview" />
          <Tab value="2" className="text-capitalize" label="Key Insights" />
          <Tab value="3" className="text-capitalize" label="Domain Overview" />
          <Tab value="4" className="text-capitalize" label="Strengths and Areas for Improvement" />
          <Tab value="5" className="text-capitalize" label="Variance Review" />
          <Tab value="6" className="text-capitalize" label="In-Depth Analysis" />
          {/* <Tab value="7" label="Comments" /> */}
        </TabList>
        <TabPanel value="1">
          <h4 className="mb-3 mt-1 fs-20 d-flex align-items-center"><div className="bg-theme ft-light content_number">1</div> Overview</h4>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="card shadow-none bg-white">
                <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
                  Understanding Your Results
                </div>
                <div className="card-body py-0">
                  <p>This report provides a comprehensive overview of your personal and professional competencies, highlighting both your strengths and areas that may benefit from further development. By understanding these aspects, you can strategically focus on enhancing your skills and performance.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="card shadow-none bg-transparent">
                <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
                  Key Benefits
                </div>
                <div className="card-body py-0">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="card shadow">
                        <div className="card-body text-center">
                          <div className="p-3">
                            <div className="my-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24"><path fill="none" stroke="#eb5151" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7" d="M7 15V7a5 5 0 0 1 10 0v8c0 1.655-.345 2-2 2H9c-1.655 0-2-.345-2-2m3-9l1-1m0 3.5l2-2M20 8v14M4 8v14m0-2h16M4 12h3m10 0h3" color="#eb5151" /></svg>
                            </div>
                            <span className="fw-semibold mb-5">Self-Awareness</span>
                            <p className="mt-2">Gain a clear understanding of your personal strengths and potential areas for improvement.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="card shadow bg_color">
                        <div className="card-body text-center">
                          <div className="p-3">
                            <div className="my-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 32 32"><path fill="#ffffff" d="M20.488 7.511a3.5 3.5 0 0 1 .837 1.363l.548 1.682a.664.664 0 0 0 1.254 0l.548-1.682a3.47 3.47 0 0 1 2.197-2.196l1.684-.547a.665.665 0 0 0 0-1.254l-.034-.008l-1.683-.547a3.47 3.47 0 0 1-2.198-2.196L23.094.444a.665.665 0 0 0-1.255 0l-.547 1.682l-.014.042a3.47 3.47 0 0 1-2.15 2.154l-1.684.547a.665.665 0 0 0 0 1.254l1.684.546c.513.171.979.46 1.36.842m9.333 4.847l.918.298l.019.004a.362.362 0 0 1 0 .684l-.919.299a1.9 1.9 0 0 0-1.198 1.197l-.299.918a.363.363 0 0 1-.684 0l-.299-.918a1.89 1.89 0 0 0-1.198-1.202l-.919-.298a.362.362 0 0 1 0-.684l.919-.299a1.9 1.9 0 0 0 1.18-1.197l.299-.918a.363.363 0 0 1 .684 0l.298.918a1.89 1.89 0 0 0 1.199 1.197M13.5 3c1.146 0 2.25.184 3.282.523a2.17 2.17 0 0 0-1.266 1.718a8.5 8.5 0 1 0 6.414 7.161a2.16 2.16 0 0 0 1.82-.322a1.9 1.9 0 0 0-.25.9a1.84 1.84 0 0 0 .476 1.238a10.45 10.45 0 0 1-2.378 5.966l7.11 7.109a1 1 0 0 1-1.415 1.414l-7.109-7.109A10.46 10.46 0 0 1 13.5 24C7.701 24 3 19.299 3 13.5S7.701 3 13.5 3" /></svg>
                            </div>
                            <span className="fw-semibold mb-5 ft-light">Comparative Insights</span>
                            <p className="mt-2 ft-light">See how your competency levels compare to those of your peers, supervisors, and direct reports.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="card shadow">
                        <div className="card-body text-center">
                          <div className="p-3">
                            <div className="my-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 32 32"><path fill="#eb5151" d="M15.586 10.414L19.166 14l-3.582 3.587L17 19l5-5l-5-5z" /><path fill="#eb5151" d="M20.586 10.414L24.166 14l-3.582 3.587L22 19l5-5l-5-5zM10 9l1.593 3l3.407.414l-2.5 2.253L13 18l-3-1.875L7 18l.5-3.333L5 12.414L8.5 12z" /><path fill="#eb5151" d="M17.736 30L16 29l4-7h6a1.997 1.997 0 0 0 2-2V8a1.997 1.997 0 0 0-2-2H6a1.997 1.997 0 0 0-2 2v12a1.997 1.997 0 0 0 2 2h9v2H6a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4h-4.835Z" /></svg>
                            </div>
                            <span className="fw-semibold">Actionable Feedback</span>
                            <p className="mt-2">Use the detailed insights to create targeted development plans and set achievable goals.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="card shadow-none bg-white">
                <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
                  Terminology Guide
                </div>
                <div className="card-body py-0">
                  <p>To assist you in interpreting your results, we have provided definitions for key terms used throughout the report:</p>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="card shadow-none bglight_color">
                        <div className="card-body">
                          <p className="mb-0"><span className="fw-semibold">Current: </span>The average of responses given during the current feedback cycle for each behavior, competency, or domain.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card shadow-none">
                        <div className="card-body">
                          <p className="mb-0"><span className="fw-semibold">Previous: </span>The average of responses given during the current feedback cycle for each behavior, competency, or domain.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card shadow-none bglight_color">
                        <div className="card-body">
                          <p className="mb-0"><span className="fw-semibold">Rater Group: </span>A group of raters who are at the same professional level relative to you (e.g., PEERS, SUPERVISORS, DIRECT REPORTS).</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card shadow-none">
                        <div className="card-body">
                          <p className="mb-0"><span className="fw-semibold">Previous: </span>The average of responses given during the previous feedback cycle for each behavior, competency, or domain.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card shadow-none bglight_color">
                        <div className="card-body">
                          <p className="mb-0"><span className="fw-semibold">GAP: </span>The difference between the scores of two rater groups or between two time periods.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card shadow-none">
                        <div className="card-body">
                          <p className="mb-0"><span className="fw-semibold">Total: </span>The average of all responses given by all raters (excluding self) for each behavior, competency, or domain.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card shadow-none bglight_color">
                        <div className="card-body">
                          <p className="mb-0"><span className="fw-semibold">Self: </span>How you rated yourself for each behavior or the average of all your scores that pertain to a specific competency or domain.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>By familiarizing yourself with these terms, you will be better equipped to analyze and understand the feedback provided in this report, enabling you to make informed decisions about your personal and professional development.</p>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="2">
          <h4 className="mb-3 mt-1 fs-20 d-flex align-items-center"><div className="bg-theme ft-light content_number">2</div> Key Insights</h4>
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card shadow-none">
                <div className="card-body bg-white">
                  <p className="fw-semibold mb-0 text-center">Strongest Competency</p>
                </div>
              </div>
              <div className="card shadow-none bg-transparent">
                <div className="card-body">
                  <StrongestComponent domain={executiveSummary && executiveSummary.strongest_domain} />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card shadow-none">
                <div className="card-body bg-white">
                  <p className="fw-semibold mb-0 text-center">Weakest Competency</p>
                </div>
              </div>
              <div className="card shadow-none bg-transparent">
                <div className="card-body">
                  <WeekestComponent domain={executiveSummary && executiveSummary.weakest_domain}/>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <Table size="small" aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell className="bg-theme ft-light">TOP THREE RATED QUESTIONS</TableCell>
                    <TableCell className="bg-theme ft-light" align='right'>SCORE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {executiveSummary && executiveSummary.top_question && executiveSummary.top_question.length > 0 ? (
                    executiveSummary.top_question.map((excutivedata, index) => (

                      <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"top_rated_questons" + index}>
                        <TableCell component='th' scope='row'>{excutivedata.question.name}</TableCell>
                        <TableCell align='right' className="fw-semibold">{excutivedata.avg_surveyAnswer}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>No Record Found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div className="col-md-12 mb-3">
              <Table size="small" aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell className="bg-theme ft-light">TOP THREE LARGEST RATING GAPS</TableCell>
                    <TableCell className="bg-theme ft-light" align='right'>OTHERS</TableCell>
                    <TableCell className="bg-theme ft-light" align='right'>SELF</TableCell>
                    <TableCell className="bg-theme ft-light" align='right'>GAP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {executiveSummary && executiveSummary.rating_gap && executiveSummary.rating_gap.length > 0 ? (
                    executiveSummary.rating_gap.map((excutivedata, index) => (
                      <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"largest_rating_gap" + index}>
                        <TableCell component='th' scope='row'>{excutivedata.domain}	</TableCell>
                        <TableCell align='right' className="fw-semibold">{excutivedata.other_rate}</TableCell>
                        <TableCell align='right' className="fw-semibold">{excutivedata.self_rate}</TableCell>
                        <TableCell align='right' className="fw-semibold">{excutivedata.gap}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>No Record Found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div className="col-md-12 mb-3">
              <Table size="small" aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell className="bg-theme ft-light">SUMMARY OF SCORES</TableCell>
                    <TableCell className="bg-theme ft-light" align='right'>SCORE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>Total Score</TableCell>
                    <TableCell align='right' className="fw-semibold">
                      {executiveSummary && executiveSummary.overallScore ? executiveSummary.overallScore.score : ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            {/*<div className="col-md-12">
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-theme ft-light" colSpan="2">KEY INSIGHTS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>Strongest Competency</TableCell>
                        <TableCell align='right'>{executiveSummary?.strongest_domain}</TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>Weakest Competency</TableCell>
                        <TableCell align='right'>{executiveSummary?.weakest_domain}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-secondary ft-light">TOP THREE RATED QUESTIONS</TableCell>
                        <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {executiveSummary && executiveSummary.top_question && executiveSummary.top_question.length > 0 ? (
                        executiveSummary.top_question.map((excutivedata, index) => (

                          <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"top_rated_questons" + index}>
                            <TableCell component='th' scope='row'>{excutivedata.question.name}</TableCell>
                            <TableCell align='right'>{excutivedata.avg_surveyAnswer}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2}>No Record Found</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-secondary ft-light">TOP THREE LARGEST RATING GAPS</TableCell>
                        <TableCell className="bg-secondary ft-light" align='right'>OTHERS</TableCell>
                        <TableCell className="bg-secondary ft-light" align='right'>SELF</TableCell>
                        <TableCell className="bg-secondary ft-light" align='right'>GAP</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {executiveSummary && executiveSummary.rating_gap && executiveSummary.rating_gap.length > 0 ? (
                        executiveSummary.rating_gap.map((excutivedata, index) => (
                          <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"largest_rating_gap" + index}>
                            <TableCell component='th' scope='row'>{excutivedata.domain}	</TableCell>
                            <TableCell align='right'>{excutivedata.other_rate}</TableCell>
                            <TableCell align='right'>{excutivedata.self_rate}</TableCell>
                            <TableCell align='right'>{excutivedata.gap}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>No Record Found</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-secondary ft-light">SUMMARY OF SCORES</TableCell>
                        <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>Total Score</TableCell>
                        <TableCell align='right'>
                          {executiveSummary && executiveSummary.overallScore ? executiveSummary.overallScore.score : ''}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
              {/* <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">AREAS OF IMPROVEMENT</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>CURRENT</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>PREVIOUS</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>GAP</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>No Record available	</TableCell>
                              <TableCell align='right'>0.00</TableCell>                                       
                              <TableCell align='right'>0.00</TableCell>                                       
                              <TableCell align='right'>0.00</TableCell>                                       
                            </TableRow>                       
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>   
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">AREAS OF DECLINE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>CURRENT</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>PREVIOUS</TableCell>                    
                            <TableCell className="bg-secondary ft-light" align='right'>GAP</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>No Record available	</TableCell>
                              <TableCell align='right'>0.00</TableCell>                                       
                              <TableCell align='right'>0.00</TableCell>                                       
                              <TableCell align='right'>0.00</TableCell>                                       
                            </TableRow>                       
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>    
            </div>*/}
            {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> */}
          </div>
        </TabPanel>
        <TabPanel value="3">
          <h4 className="mb-3 mt-1 fs-20 d-flex align-items-center"><div className="bg-theme ft-light content_number">3</div> Domain Overview</h4>
          <div className="row">
            <div className="col-md-12">
              {/* <PieComponent /> */}
              <div className="row">
                {domainSummary && domainSummary.length > 0 ? (
                  domainSummary.map((summary, index) => (
                    <>
                      <div className="col-md-3 mb-3">
                        <div className="summary-chart">
                          <PieComponent chartData={summary} />
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <div className="col-md-12 mb-3">
                    <TableCell>No Record Found</TableCell>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-3">
              <div className="card shadow-none">
                <div className="card-body">
                  <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
                    Description
                  </div>
                  <div className="card-body py-0">
                    <p>These charts provide a visual summary of each domain for self, others and company average.</p>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="col-md-12 mb-3">
               <div className="row">
                {domainSummary && domainSummary.length > 0 ? (
                  domainSummary.map((summary, index) => (
                    <>
                      <div className="col-md-3">
                        <div className="summary-chart">
                          <DoughnutChart chartData={summary} />
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  // <div className="row">
                  <div className="col-md-12 mb-3">
                    <TableCell>No Record Found</TableCell>
                  </div>
                  // </div>
                  // <div className="col-md-12">
                  // <TableContainer component={Paper}>
                  //   <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                  //     <TableCell component='th' scope='row'>No Record Found</TableCell>
                  //   </TableRow>
                  // </TableContainer>
                  // </div>
                )}
              </div> */}
            {/* <hr></hr> */}
            {/*<div className="col-md-12 mb-3">
                <div className="card shadow-none">
                  <div className="card-body">
                    <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
                      Description
                    </div>
                    <div className="card-body py-0">
                      <p>These charts provide a visual summary of each domain for self, others and company average.</p>
                    </div>
                  </div>
                </div>
                 <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-theme ft-light">DESCRIPTION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>These charts provide a visual summary of each domain for self, others and company average.</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer> *
              </div>/}
              {/* <hr></hr> 
            </div>*/}
            {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> */}
          </div>
        </TabPanel>
        <TabPanel value="4">
          <h4 className="mb-3 mt-1 fs-20 d-flex align-items-center"><div className="bg-theme ft-light content_number">4</div> Strengths and Areas for Improvement</h4>
          <div className="card shadow-none mb-3">
            <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
              Five Heighest Score
            </div>
            <div className="card-body pt-0">
              <div className="row align-items-center mb-3">
                <div className="col-md-12">
                  <div className="card shadow-none">
                    <div className="card-body bg-theme">
                      <div className="row">
                        <div className="col-md-2">
                          <p className="fw-semibold mb-0 ft-light">Domain </p>
                        </div>
                        <div className="col-md-9">
                          <p className="mb-0 ft-light fw-semibold">Question</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold">Total</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {highLowScore && highLowScore.heighest_score && highLowScore.heighest_score.length > 0 ? (
                  highLowScore.heighest_score.map((highlowdata, index) => (
                    <div className="col-md-12">
                      <div className={`card shadow-none ${(index + 1) % 2 === 0 ? "" : "bglight_color"}`}>
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <p className="fw-semibold mb-0">{highlowdata.question.competency.name}</p>
                            </div>
                            <div className="col-md-9">
                              <p className="mb-0">{highlowdata.question.name}</p>
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{highlowdata.avg_surveyAnswer}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : ''}
              </div>
              {/* <TableContainer component={Paper}>
                <Table size="small" aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell width='25%' className="bg-theme ft-light">Domain</TableCell>
                      <TableCell width='65%' className="bg-theme ft-light">Question</TableCell>
                      <TableCell width='10%' className="bg-theme ft-light" align='right'>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {highLowScore && highLowScore.heighest_score && highLowScore.heighest_score.length > 0 ? (
                      highLowScore.heighest_score.map((highlowdata, index) => (

                        <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"highest_score" + highlowdata.question.id}>
                          <TableCell component='th' scope='row'>{highlowdata.question.competency.name}	</TableCell>
                          <TableCell>{highlowdata.question.name}	</TableCell>
                          <TableCell align='right' className="fw-semibold">{highlowdata.avg_surveyAnswer}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Record Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </div>
          </div>
          <div className="card shadow-none mb-3">
            <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
              Five Lowest Score
            </div>
            <div className="card-body pt-0">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="card shadow-none">
                    <div className="card-body bg-theme">
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <p className="fw-semibold mb-0 ft-light">Domain </p>
                        </div>
                        <div className="col-md-9">
                          <p className="mb-0 ft-light fw-semibold">Question</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold">Total</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {highLowScore && highLowScore.lowest_score && highLowScore.lowest_score.length > 0 ? (
                  highLowScore.lowest_score.map((highlowdata, index) => (
                    <div className="col-md-12">
                      <div className={`card shadow-none ${(index + 1) % 2 === 0 ? "" : "bglight_color"}`}>
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <p className="fw-semibold mb-0">{highlowdata.question.competency.name}</p>
                            </div>
                            <div className="col-md-9">
                              <p className="mb-0">{highlowdata.question.name}</p>
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{highlowdata.avg_surveyAnswer}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : ''}
              </div>
              {/* <div className="card-body pt-0">
              <TableContainer component={Paper}>
                <Table size="small" aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell width='25%' className="bg-theme ft-light">Domain</TableCell>
                      <TableCell width='65%' className="bg-theme ft-light">Question</TableCell>
                      <TableCell width='10%' className="bg-theme ft-light" align='right'>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {highLowScore && highLowScore.lowest_score && highLowScore.lowest_score.length > 0 ? (
                      highLowScore.lowest_score.map((highlowdata, index) => (

                        <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"lowest_score" + highlowdata.question.id}>
                          <TableCell component='th' scope='row'>{highlowdata.question.competency.name}	</TableCell>
                          <TableCell>{highlowdata.question.name}	</TableCell>
                          <TableCell align='right' className="fw-semibold">{highlowdata.avg_surveyAnswer}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Record Found</TableCell>
                      </TableRow>
                    )}

                  </TableBody>
                </Table>
              </TableContainer>
            </div> */}
            </div>
            {/* <div className="row">
            <div className="col-md-12">
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-theme ft-light" colSpan="3">FIVE HIGHEST SCORES</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width='25%' className="bg-secondary ft-light">Domain</TableCell>
                        <TableCell width='65%' className="bg-secondary ft-light">Question</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {highLowScore && highLowScore.heighest_score && highLowScore.heighest_score.length > 0 ? (
                        highLowScore.heighest_score.map((highlowdata, index) => (

                          <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"highest_score" + highlowdata.question.id}>
                            <TableCell component='th' scope='row'>{highlowdata.question.competency.name}	</TableCell>
                            <TableCell>{highlowdata.question.name}	</TableCell>
                            <TableCell align='right'>{highlowdata.avg_surveyAnswer}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>No Record Found</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-theme ft-light" colSpan="3">FIVE LOWEST SCORES</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width='25%' className="bg-secondary ft-light">Domain</TableCell>
                        <TableCell width='65%' className="bg-secondary ft-light">Question</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {highLowScore && highLowScore.lowest_score && highLowScore.lowest_score.length > 0 ? (
                        highLowScore.lowest_score.map((highlowdata, index) => (

                          <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"lowest_score" + highlowdata.question.id}>
                            <TableCell component='th' scope='row'>{highlowdata.question.competency.name}	</TableCell>
                            <TableCell>{highlowdata.question.name}	</TableCell>
                            <TableCell align='right'>{highlowdata.avg_surveyAnswer}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>No Record Found</TableCell>
                        </TableRow>
                      )}

                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
            </div> */}
            {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> */}
          </div>
        </TabPanel>
        <TabPanel value="5">
          <h4 className="mb-3 mt-1 fs-20 d-flex align-items-center"><div className="bg-theme ft-light content_number">5</div> Variance Review</h4>
          <div className="card shadow-none mb-3">
            <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
              Positive Gaps
            </div>
            <div className="card-body pt-0">
              <div className="row align-items-center mb-3">
                <div className="col-md-12">
                  <div className="card shadow-none">
                    <div className="card-body bg-theme">
                      <div className="row">
                        <div className="col-md-2">
                          <p className="fw-semibold mb-0 ft-light">Domain </p>
                        </div>
                        <div className="col-md-7">
                          <p className="mb-0 ft-light fw-semibold">Question</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold text-center">Others</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold text-center">Self</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold text-center">Gap</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {positiveNegativeGap && positiveNegativeGap.possitive && positiveNegativeGap.possitive.length > 0 ? (
                  positiveNegativeGap.possitive.map((ratinggapdata, index) => (
                    <div className="col-md-12">
                      <div className={`card shadow-none ${(index + 1) % 2 === 0 ? "" : "bglight_color"}`}>
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <p className="fw-semibold mb-0">{ratinggapdata.domain}</p>
                            </div>
                            <div className="col-md-7">
                              <p className="mb-0">{ratinggapdata.question}</p>
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{ratinggapdata.other_rate}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{ratinggapdata.self_rate}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{ratinggapdata.gap}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : ''}
              </div>
            </div>
          </div>
          <div className="card shadow-none mb-3">
            <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
              Negative GAPS
            </div>
            <div className="card-body pt-0">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="card shadow-none">
                    <div className="card-body bg-theme">
                      <div className="row align-items-center">
                        <div className="col-md-2">
                          <p className="fw-semibold mb-0 ft-light">Domain </p>
                        </div>
                        <div className="col-md-7">
                          <p className="mb-0 ft-light fw-semibold">Question</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold text-center">Others</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold text-center">Self</p>
                        </div>
                        <div className="col-md-1">
                          <p className="mb-0 ft-light fw-semibold text-center">Gap</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {positiveNegativeGap && positiveNegativeGap.negative && positiveNegativeGap.negative.length > 0 ? (
                  positiveNegativeGap.negative.map((ratinggapdata, index) => (
                    <div className="col-md-12">
                      <div className={`card shadow-none ${(index + 1) % 2 === 0 ? "" : "bglight_color"}`}>
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <p className="fw-semibold mb-0">{ratinggapdata.domain}</p>
                            </div>
                            <div className="col-md-7">
                              <p className="mb-0">{ratinggapdata.question}</p>
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{ratinggapdata.other_rate}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{ratinggapdata.self_rate}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                            <div className="col-md-1">
                              <div className="reviewvalue">{ratinggapdata.gap}</div>
                              {/* <SmallBarChart /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : ''}
              </div>
            </div>
          </div>
          {/*<div className="row">
            <div className="col-md-12">
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-theme ft-light" colSpan="5">Positive Gaps</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell width='20%' className="bg-secondary ft-light">Domain</TableCell>
                        <TableCell width='50%' className="bg-secondary ft-light">Question</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Others</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Self</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Gap</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {positiveNegativeGap && positiveNegativeGap.possitive && positiveNegativeGap.possitive.length > 0 ? (
                        positiveNegativeGap.possitive.map((ratinggapdata, index) => (

                          <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"positive_gap" + index}>
                            <TableCell component='th' scope='row'>{ratinggapdata.domain}</TableCell>
                            <TableCell>{ratinggapdata.question}</TableCell>
                            <TableCell align='right'>{ratinggapdata.other_rate}</TableCell>
                            <TableCell align='right'>{ratinggapdata.self_rate}</TableCell>
                            <TableCell align='right'>{ratinggapdata.gap}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>No Record Found</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
              <div className="col-md-12">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-theme ft-light" colSpan="5">Negative Gaps</TableCell>

                      </TableRow>
                      <TableRow>
                        <TableCell width='20%' className="bg-secondary ft-light">Domain</TableCell>
                        <TableCell width='50%' className="bg-secondary ft-light">Question</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Others</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Self</TableCell>
                        <TableCell width='10%' className="bg-secondary ft-light" align='right'>Gap</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {positiveNegativeGap && positiveNegativeGap.negative && positiveNegativeGap.negative.length > 0 ? (
                        positiveNegativeGap.negative.map((ratinggapdata, index) => (

                          <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }} key={"negative_gap" + index}>
                            <TableCell component='th' scope='row'>{ratinggapdata.domain}</TableCell>
                            <TableCell>{ratinggapdata.question}</TableCell>
                            <TableCell align='right'>{ratinggapdata.other_rate}</TableCell>
                            <TableCell align='right'>{ratinggapdata.self_rate}</TableCell>
                            <TableCell align='right'>{ratinggapdata.gap}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>No Record Found</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <hr></hr>
            </div> 
             <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> 
          </div>*/}
        </TabPanel>
        <TabPanel value="6">
          <h4 className="mb-3 mt-1 fs-20 d-flex align-items-center"><div className="bg-theme ft-light content_number">6</div> In-Depth Analysis</h4>
          <div className="row">
          {detailedResults && detailedResults.length > 0 ? detailedResults.map((competency, index) => (
            <div className="col-md-12 mb-3">
              <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
                {competency.name}
              </div>
              
              <TableContainer component={Paper}>
                <Table size="small" aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell width={'60%'} className="">Question</TableCell>
                      {/* <TableCell className="">
                        <div className="color-code">
                          <span className="table-danger"></span>
                          <span className="table-orange"></span>
                          <span className="table-yellow"></span>
                          <span className="table-lightgreen"></span>
                          <span className="table-darkgreen"></span>
                        </div>
                      </TableCell> */}
                      <TableCell className="">Self</TableCell>
                      <TableCell className="">Manager</TableCell>
                      <TableCell className="">Direct Reports</TableCell>
                      <TableCell className="">Peers</TableCell>
                      <TableCell className="">Others</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {competency.questions.map((question, i) => (
                    question.questionTypeId === 1 ?
                    <TableRow>
                      <TableCell>{question.name}</TableCell>
                      {/* <TableCell><div className="tablecell table-lightgreen"></div></TableCell> */}
                      <TableCell 
                        className={
                          question.surveyAnsers.self > 0 && question.surveyAnsers.self <= 1 ? "table-danger ft-light text-center" : 
                          question.surveyAnsers.self > 1 && question.surveyAnsers.self <= 2  ? "table-orange ft-light text-center" : 
                          question.surveyAnsers.self > 2 && question.surveyAnsers.self <= 4  ? "table-yellow ft-light text-center" : 
                          question.surveyAnsers.self > 4 && question.surveyAnsers.self <= 5.5  ? "table-lightgreen ft-light text-center" : 
                          question.surveyAnsers.self > 5.5 && question.surveyAnsers.self <= 7  ? "table-darkgreen ft-light text-center" : ''
                        }
                      >
                        <div className="fw-semibold">{question.surveyAnsers?.self}</div>
                        {/* <p className="mb-0 fs-8">Very Good</p> */}
                      </TableCell>
                      <TableCell 
                        className={
                          question.surveyAnsers.manager > 0 && question.surveyAnsers.manager <= 1 ? "table-danger ft-light text-center" : 
                          question.surveyAnsers.manager > 1 && question.surveyAnsers.manager <= 2  ? "table-orange ft-light text-center" : 
                          question.surveyAnsers.manager > 2 && question.surveyAnsers.manager <= 4  ? "table-yellow ft-light text-center" : 
                          question.surveyAnsers.manager > 4 && question.surveyAnsers.manager <= 5.5  ? "table-lightgreen ft-light text-center" : 
                          question.surveyAnsers.manager > 5.5 && question.surveyAnsers.manager <= 7  ? "table-darkgreen ft-light text-center" : ''
                        }
                      >
                        <div className="fw-semibold">{question.surveyAnsers?.manager}</div>
                        {/* <p className="mb-0 fs-8">Good</p> */}
                      </TableCell>
                      <TableCell 
                        className={
                          question.surveyAnsers.directReportees > 0 && question.surveyAnsers.directReportees <= 1 ? "table-danger ft-light text-center" : 
                          question.surveyAnsers.directReportees > 1 && question.surveyAnsers.directReportees <= 2  ? "table-orange ft-light text-center" : 
                          question.surveyAnsers.directReportees > 2 && question.surveyAnsers.directReportees <= 4  ? "table-yellow ft-light text-center" : 
                          question.surveyAnsers.directReportees > 4 && question.surveyAnsers.directReportees <= 5.5  ? "table-lightgreen ft-light text-center" : 
                          question.surveyAnsers.directReportees > 5.5 && question.surveyAnsers.directReportees <= 7  ? "table-darkgreen ft-light text-center" : ''
                        }
                      >
                        <div className="fw-semibold">{question.surveyAnsers?.directReportees}</div>
                        {/* <p className="mb-0 fs-8">Neutral</p> */}
                      </TableCell>
                      <TableCell 
                        className={
                          question.surveyAnsers.peers > 0 && question.surveyAnsers.peers <= 1 ? "table-danger ft-light text-center" : 
                          question.surveyAnsers.peers > 1 && question.surveyAnsers.peers <= 2  ? "table-orange ft-light text-center" : 
                          question.surveyAnsers.peers > 2 && question.surveyAnsers.peers <= 4  ? "table-yellow ft-light text-center" : 
                          question.surveyAnsers.peers > 4 && question.surveyAnsers.peers <= 5.5  ? "table-lightgreen ft-light text-center" : 
                          question.surveyAnsers.peers > 5.5 && question.surveyAnsers.peers <= 7  ? "table-darkgreen ft-light text-center" : ''
                        }
                      >
                        <div className="fw-semibold">{question.surveyAnsers?.peers}</div>
                        {/* <p className="mb-0 fs-8">Bad</p> */}
                      </TableCell>
                      <TableCell 
                        className={
                          question.surveyAnsers.others > 0 && question.surveyAnsers.others <= 1 ? "table-danger ft-light text-center" : 
                          question.surveyAnsers.others > 1 && question.surveyAnsers.others <= 2  ? "table-orange ft-light text-center" : 
                          question.surveyAnsers.others > 2 && question.surveyAnsers.others <= 4  ? "table-yellow ft-light text-center" : 
                          question.surveyAnsers.others > 4 && question.surveyAnsers.others <= 5.5  ? "table-lightgreen ft-light text-center" : 
                          question.surveyAnsers.others > 5.5 && question.surveyAnsers.others <= 7  ? "table-darkgreen ft-light text-center" : ''
                        }
                      >
                        <div className="fw-semibold">{question.surveyAnsers?.others}</div>
                        {/* <p className="mb-0 fs-8">Very Bad</p> */}
                      </TableCell>
                    </TableRow>
                    : 
                    <TableRow>
                      <TableCell>{question.name}</TableCell>
                      <TableCell colSpan={5}>
                        {
                          question.surveyAnsers.map(answer => (
                            answer.trim() !== 'N/A' ?
                            <p>{answer}</p> : ''
                          ))
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                    
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )) : ''}
            

            {/* <TableContainer component={Paper} className="mb-3">
              <Table size="small" aria-label='simple table'>
                <TableHead>
                  <TableCell className="">Question</TableCell>
                  <TableCell className="">
                    <div className="color-code">
                      <span className="table-danger"></span>
                      <span className="table-orange"></span>
                      <span className="table-yellow"></span>
                      <span className="table-lightgreen"></span>
                      <span className="table-darkgreen"></span>
                    </div>
                  </TableCell>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the industry's standard dummy text </TableCell>
                    <TableCell><div className="tablecell table-darkgreen"></div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the industry's standard dummy text </TableCell>
                    <TableCell><div className="tablecell table-lightgreen"></div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the industry's standard dummy text </TableCell>
                    <TableCell><div className="tablecell table-yellow"></div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the industry's standard dummy text </TableCell>
                    <TableCell><div className="tablecell table-orange"></div></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the industry's standard dummy text </TableCell>
                    <TableCell><div className="tablecell table-danger"></div></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer> */}
            {/* <div className="col-md-12 mb-3">
              <div className="card shadow-none mb-3">
                <div className="card-header bg-transparent fw-semibold fs-16 py-3 border-0">
                  Discipline
                </div>
                <div className="card-body pt-0">
                  <div className="row align-items-center mb-3">
                    <div className="col-md-12">
                      <div className="card shadow-none border-top border-bottom">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-md-9">
                              <p className="fw-semibold mb-0 fs-11">Question</p>
                            </div>
                            <div className="col-md-3">
                              <p className="fw-semibold mb-0 fs-11">Total</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="card shadow-none">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-md-8">
                              <p className="mb-0">Fills Timesheet on time, every time, in the right projects</p>
                            </div>
                            <div className="col-md-4">
                              <NewRadialBar />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/*<div className="row">
               <div className="col-md-12">
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell className="bg-theme ft-light">WHAT THE REPORT MEANS TO YOU</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                          <TableCell component='th' scope='row'>This information enables you to understand personal strengths as well as identify areas for potential improvement. The report also provides insights on your personal competency level in relation to others. The Description terms will allow you to interpret your results with additional detail.</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell className="bg-theme ft-light">DESCRIPTION OF TERMS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                          <TableCell component='th' scope='row'>To help you better understand your report, we have provided the following definitions for terms used throughout the report</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                {detailedResults && detailedResults.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label='simple table'>
                          {detailedResults.map((competency, index) => (
                            <>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="bg-theme ft-light" colSpan={2}>{competency.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell width="60%" className="bg-secondary ft-light">Question</TableCell>
                                  <TableCell width="40%" className="bg-secondary ft-light" align='right'>Result</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {competency.questions.map((ques, i) => (
                                  <TableRow key={i} sx={{ '&:last-of-type td, &:last-of-type th': { border: 0 } }}>
                                    <TableCell width="60%">{ques.name}</TableCell>
                                    {Array.isArray(ques.surveyAnsers) ?
                                      <TableCell width="40%"><ul>
                                        {
                                          ques.surveyAnsers.map((ans) => {
                                            return <li>{ans}</li>
                                          })
                                        }
                                      </ul></TableCell>
                                      :
                                      <TableCell align='right'>
                                        <div className="details-chart">
                                          <BarChart chartData={ques.surveyAnsers} />
                                        </div>
                                      </TableCell>
                                    }
                                  </TableRow>
                                ))}
                              </TableBody>
                            </>
                          ))}
                        </Table>
                      </TableContainer>
                    </div>
                    <hr></hr>
                  </>
                ) : ('')
                }
              </div> */}
          {/* <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Total</TableCell>
                                <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Self</TableCell>
                                <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                            </TableRow>                     
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
            </div> 
            </div>*/}
        </TabPanel>
        {/* <TabPanel value="7">
          <h4 className="mb-3 mt-1">Interpreting your results</h4>
          <div className="row">
            <div className="col-md-7">
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light">WHAT THE REPORT MEANS TO YOU</TableCell>                                        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>This information enables you to understand personal strengths as well as identify areas for potential improvement. The report also provides insights on your personal competency level in relation to others. The Description terms will allow you to interpret your results with additional detail.</TableCell>
                            </TableRow>                        
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr> 
                  <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-theme ft-light">DESCRIPTION OF TERMS</TableCell>                                        
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>To help you better understand your report, we have provided the following definitions for terms used throughout the report</TableCell>
                            </TableRow>                        
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>                   
            </div>
            <div className="col-md-5">
              <div className="col-md-12">
                  <TableContainer component={Paper}>
                      <Table size="small" aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                            <TableCell className="bg-secondary ft-light">SCALE</TableCell>
                            <TableCell className="bg-secondary ft-light" align='right'>SCORE</TableCell>                    
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Almost Never</TableCell>
                                <TableCell align='right'>1</TableCell>                                       
                                <TableCell component='th' scope='row'>Frequently</TableCell>
                                <TableCell align='right'>5</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Rarely</TableCell>
                                <TableCell align='right'>2</TableCell>                                       
                                <TableCell component='th' scope='row'>Usually</TableCell>
                                <TableCell align='right'>6</TableCell>                                       
                            </TableRow>
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Occasionally</TableCell>
                                <TableCell align='right'>3</TableCell>                                       
                                <TableCell component='th' scope='row'>Almost Always</TableCell>
                                <TableCell align='right'>7</TableCell>                                       
                            </TableRow>  
                            <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                                <TableCell component='th' scope='row'>Sometimes</TableCell>
                                <TableCell align='right'>4</TableCell>
                                <TableCell component='th' scope='row'></TableCell>
                                <TableCell align='right'></TableCell>
                            </TableRow>                      
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell className="bg-secondary ft-light" colSpan="2">RATERS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>Total</TableCell>
                              <TableCell>The average of all answers given by all raters (excluding self) for each behavior, competency, or domain.</TableCell>                                       
                          </TableRow>
                          <TableRow sx={{'&:last-of-type td, &:last-of-type th': {border: 0}}}>
                              <TableCell component='th' scope='row'>Self</TableCell>
                              <TableCell>How you rated yourself for each behavior or the average of all of your scores that pertain to a specific competency or domain.</TableCell>                                       
                          </TableRow>                     
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <hr></hr>
              </div>
            </div>                         
        </TabPanel> */}
      </TabContext>
  );
};

export default CardNavigationCenter;
