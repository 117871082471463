export const Loader = ({loading}) => {
    return (
        loading ?
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        :""
    )
}
export default Loader;
