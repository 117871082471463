import { useEffect } from "react";
import { Link } from 'react-router-dom';
import BlankLayout from '../Layout/BlankLayout'
import {Box, CardContent, Typography, Button, styled} from '@mui/material'
import MuiCard from '@mui/material/Card'
import FooterIllustrationsV1 from './FooterIllustration'

const Card = styled(MuiCard)(({ theme }) => ({    
  [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const CheckoutCancel = () => {

  return (
    <BlankLayout>
      <Box className='content-center'>
          <Card sx={{ zIndex: 1 }}>
              <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link to="/"><img src={process.env.PUBLIC_URL+"/images/logo.png"} alt="..." width="150"/></Link>
                </Box>
                <Box sx={{ mb: 6 }}>
                    <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}> Checkout Canceled </Typography>                    
                </Box>
                <Link to="/" > Home </Link>
              </CardContent>
          </Card>
          <FooterIllustrationsV1 />
      </Box>
    </BlankLayout>
  );
};

export default CheckoutCancel;