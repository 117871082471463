// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'

// ** Icons Imports
import Menu from 'mdi-material-ui/Menu'

import ArrowBack from '@mui/icons-material/ArrowBack'
// ** Components
import ModeToggler from '../shared-components/ModeToggler'
import UserDropdown from '../shared-components/UserDropdown'

import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  // ** Hook
  const hiddenSm = useMediaQuery(theme => theme.breakpoints.down('sm'))
  
  const authUser = localStorage.getItem('authUser');
  const user = JSON.parse(authUser);
  const navigate = useNavigate();

  const goBack = () => {
    axios.post('/api/auth/goBack/login', { isSuperAdmin: user.isSuperAdmin, isAdmin: user.isAdmin })
          .then((res) => {
            if (res.data.success) {
              console.log("res.data.datagoBack/login", res.data.data)
                // res.data.data.isSuperAdmin = '0';
                // res.data.data.isAdmin = '0';
                // if( res.data.data.role === 'SuperAdmin'){
                //     res.data.data.isSuperAdmin = '0';
                // }
                // if( res.data.data.role === 'Admin'){
                //     res.data.data.isAdmin = '0';
                // }
              localStorage.clear();
              localStorage.setItem('authUser', JSON.stringify(res.data.data));
              window.location.href = ('/dashboard');
            } else {
              Swal.fire(res.data.message, '', 'info');
            }
          })
          .catch((error) => {
            Swal.fire(error.message, '', 'info');
          });

  }
  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton
            color='inherit'
            onClick={toggleNavVisibility}
            sx={{ ml: -2.75, ...(hiddenSm ? {} : { mr: 3.5 }) }}
          >
            <Menu />
          </IconButton>
        ) : null}
        
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}> 
        {
          ((user.isSuperAdmin > 0 && user.role === 'Admin') || (user.isAdmin > 0 && user.role === 'User')) ? 
            <Box><Button className="" onClick={goBack}><ArrowBack />Go Back To Your Account</Button></Box>  
          :""
        }
             
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <UserDropdown />
      </Box>
    </Box>
  )
}

export default AppBarContent
