// ** React Imports
import { useState, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Alert from '@mui/material/Alert'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'

// ** Icons Imports
import Close from 'mdi-material-ui/Close'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';

const ImgStyled = styled('img')(({ theme }) => ({
  width: 120,
  height: 120,
  marginRight: theme.spacing(6.25),
  borderRadius: theme.shape.borderRadius
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center'
  }
}))

const ResetButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4.5),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
    textAlign: 'center',
    marginTop: theme.spacing(4)
  }
}))



const TabAccount = ({users, ...rest}) => {
  // ** State
  const [openAlert, setOpenAlert] = useState(true)
  const [imgSrc, setImgSrc] = useState('./images/avatars/1.png')
  const authUser = localStorage.getItem('authUser');
  const user = JSON.parse(authUser);

  const onFileChange = (file) => {
    console.log("filefilefile",file.name.split('.').pop())
    formik.setFieldValue('image', file);
    const reader = new FileReader();
    reader.onload = () => {
      setImgSrc(reader.result);
    // console.log(reader.result)
      // formik.setFieldValue('image', reader.result);
    }
    reader.readAsDataURL(file);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    companyName: Yup.string().when('role', {
      is: 'Admin',
      then: Yup.string().required('Company Name is required'),
      otherwise: Yup.string().notRequired()
    })
  });

 const initialValues = {
    id: user.id,
    name: '',
    email: '',
    companyName: '',
    image: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });
 
  useEffect(() => {
    if (Object.keys(users).length !== 0) {
      setImgSrc(users.image ? users.image : './images/avatars/1.png');
      formik.setValues({
        id: users.id,
        name: users.name,
        email: users.email,
        companyName: users.role === 'Admin' ? users.companyName : '',
        image: '',
      });
    }
  }, [users, formik.setValues]);

  const handleSubmit = async (values) => {
    try {
      if (users.role === 'Admin' && !values.companyName) {
        Swal.fire('Company Name is required');
        return;
      }

      const formData = new FormData();
      formData.append('id', values.id);
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('companyName', values.companyName || '');
      formData.append('image', values.image);
      // console.log("formData====",formData);

      let res = await axios.post('/api/users/updateProfile', formData);
      if(res.data.success){
          const authUserJSON = localStorage.getItem('authUser');
          let authUser = JSON.parse(authUserJSON);
          if (authUser) {            
            if(values.image != ''){
              authUser.image = res.data.data.image
            }
            authUser.name = res.data.data.name;
            authUser.companyName = res.data.data.companyName;
            try {
              localStorage.setItem('authUser', JSON.stringify(authUser));
            } catch (error) {
              console.error("Error storing authUser:", error);
            }
          } else {
            console.error("authUserJSON is not valid JSON:", authUserJSON);
          }
        Swal.fire(res.data.message, '', 'success').then(result => {
          window.location.reload();
        })
      }
      else{
        Swal.fire(res.data.message, '', 'info')
      }
    } catch (error) {
      Swal.fire('Error updating profile:'+ error);
    }
  }
  return (
    <CardContent>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data" method="post">
        <Grid container spacing={7}>
          <Grid item xs={12} sx={{ marginTop: 4.8, marginBottom: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ImgStyled src={imgSrc} alt='Profile Pic' />
              <Box>
                <ButtonStyled component='label' variant='contained' htmlFor='account-settings-upload-image'>
                  Upload New Photo
                  <input
                    hidden
                    name='image'
                    type='file'
                    onChange={(e) => onFileChange(e.target.files[0])}
                    accept='image/png, image/jpeg'
                    id='account-settings-upload-image'
                  />
                </ButtonStyled>
                <ResetButtonStyled color='error' variant='outlined' onClick={() => setImgSrc('./images/avatars/1.png')}>
                  Reset
                </ResetButtonStyled>
                <Typography variant='body2' sx={{ marginTop: 5 }}>
                  Allowed PNG or JPEG. Max size of 800K.
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <TextField fullWidth label='Username' defaultValue={users.name}/>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              ListboxProps={{
                sx: { fontSize: 13,
                    fontFamily: 'Poppins' },
              }}
              sx={{
                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                    fontSize: 13,
                    fontFamily: 'Poppins', // Add your desired font family
                    fontWeight: '500', // Add your desired font weight
                },
              }}
              fullWidth
              label='Name'
              size='small'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              ListboxProps={{
                sx: { fontSize: 13,
                    fontFamily: 'Poppins' },
              }}
              sx={{
                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                    fontSize: 13,
                    fontFamily: 'Poppins', // Add your desired font family
                    fontWeight: '500', // Add your desired font weight
                },
              }}
              type='email'
              label='Email'
              size='small'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Role</InputLabel>
              <Select label='Role' defaultValue='admin'>
                <MenuItem value='admin'>Admin</MenuItem>
                <MenuItem value='author'>Author</MenuItem>
                <MenuItem value='editor'>Editor</MenuItem>
                <MenuItem value='maintainer'>Maintainer</MenuItem>
                <MenuItem value='subscriber'>Subscriber</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select label='Status' defaultValue='active'>
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
                <MenuItem value='pending'>Pending</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          
          {users.role === 'Admin' ? 
            <>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                ListboxProps={{
                  sx: { fontSize: 13,
                      fontFamily: 'Poppins' },
                }}
                sx={{
                  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                      fontSize: 13,
                      fontFamily: 'Poppins', // Add your desired font family
                      fontWeight: '500', // Add your desired font weight
                  },
                }}
                label='Company Name'
                name='companyName'
                size='small'
                placeholder='ABC Pvt. Ltd.'
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                helperText={formik.touched.companyName && formik.errors.companyName}
              />
            </Grid>
            </> : 
            null }

          {/* {openAlert ? (
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Alert
                severity='warning'
                sx={{ '& a': { fontWeight: 400 } }}
                action={
                  <IconButton size='small' color='inherit' aria-label='close' onClick={() => setOpenAlert(false)}>
                    <Close fontSize='inherit' />
                  </IconButton>
                }
              >
                <AlertTitle>Your email is not confirmed. Please check your inbox.</AlertTitle>
                <Link href='/' onClick={e => e.preventDefault()}>
                  Resend Confirmation
                </Link>
              </Alert>
            </Grid>
          ) : null} */}

          <Grid item xs={12}>
            <Button type='submit' size='small' variant='contained' sx={{ marginRight: 3.5 }}>
              Save Changes
            </Button>
            {/* <Button type='reset' variant='outlined' color='secondary'>
              Reset
            </Button> */}
          </Grid>
        </Grid>
      </form>
    </CardContent>
  )
}

export default TabAccount
