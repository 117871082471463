// ** React Imports
import { useState, useEffect } from "react";
import axios from "axios";

// ** MUI Imports
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import SquareIcon from "@mui/icons-material/Square";
import CircleIcon from "@mui/icons-material/Circle";

const RatingQuestions = ({ index, answerKey, survey_competency_question, currentSessionDetails, surveyRatings, labels, filledAnswers, setFilledAnswers,currentSurveyUserId}) => {

    const authUser = localStorage.getItem("authUser");
    const loginUser = JSON.parse(authUser);

    const [value, setValue] = useState(0)
    const [hover, setHover] = useState(-1)
    // this is temprarry user id 
    const user_id = currentSurveyUserId ? currentSurveyUserId : loginUser.id;

    const RatingTotal = surveyRatings ? surveyRatings.rating : 5;
    // console.log("surveyRatingssurveyRatingssurveyRatings",surveyRatings)
    const getLabelText = (value) => {
        return `${value} ${surveyRatings.ratingIcon}${value !== 1 ? "s" : ""}, ${labels[value]}`;
    };

    const handleRatingChange = (questionId, newValue) => {        
        setValue(newValue);
        setFilledAnswers(filledAnswers => {
            const existingAnswerIndex = filledAnswers.findIndex(answer => answer.questionId === questionId);
    
            if (existingAnswerIndex !== -1) {
                const updatedAnswers = [...filledAnswers];
                
                if(newValue === null ){
                    updatedAnswers.splice(existingAnswerIndex, 1);
                }
                else{
                    updatedAnswers[existingAnswerIndex] = {
                        userId: user_id,
                        sessionId: currentSessionDetails.id,
                        surveyId: currentSessionDetails.surveyId,
                        questionId: questionId,
                        surveyAnswer: newValue,
                        createdBy: loginUser.id
                    };
                }
                return updatedAnswers;
            } else {
                const newAnswer = {
                    userId: user_id,
                    sessionId: currentSessionDetails.id,
                    surveyId: currentSessionDetails.surveyId,
                    questionId: questionId,
                    surveyAnswer: newValue,
                    createdBy: loginUser.id
                };
                return [...filledAnswers, newAnswer];
            }
        });
        // console.log("filledAnswers=======",filledAnswers)

    };
    
    const filledValue = (questionId) => {
        const existingAnswerIndex = filledAnswers.findIndex(answer => answer.questionId === questionId);
        if (existingAnswerIndex !== -1) {
            return filledAnswers[existingAnswerIndex].surveyAnswer
        }
        else{
            return 0
        }
    }; 
    
    const selectEmptyIcon = () => {
        switch (surveyRatings.ratingIcon) {
            case "Square":
                return <SquareIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            case "Circle":
                return <CircleIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            case "Star":
                return <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            default:
                return '';
        }
    }
    const selectRatingIcon = () => {
        switch (surveyRatings.ratingIcon) {
            case "Square":
                return <SquareIcon />;
            case "Circle":
                return <CircleIcon />;
            case "Star":
                return <StarIcon />;
            default:
                return '';
        }
    }

    return (
        <div className="col-md-3 text-end">
            <Rating
                name={`rating-feedback-${answerKey}`}
                value={filledValue(survey_competency_question.question.id)}
                precision={1}
                max={RatingTotal}
                getLabelText={getLabelText}
                onChange={(event, newValue) => handleRatingChange(survey_competency_question.question.id, newValue)}
                emptyIcon={selectEmptyIcon()}
                icon={selectRatingIcon()}
                title={(value) => getLabelText(value)}
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
            />
            {value !== null && (
                <Box sx={{fontSize:'12px', marginLeft: '30px', textAlign:'right'}}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
        </div>
    );
};

export default RatingQuestions;