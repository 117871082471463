import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
// ** MUI Imports
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';

import { Checkbox, FormControlLabel, FormGroup, Chip} from "@mui/material";

import Autocomplete from '@mui/material/Autocomplete';

export const InviteCoworker = ({ show, handleClose, coworkers, selectedEmployee, selectedUsersToInvite, setSelectedUsersToInvite, currentSession, getOtherUsersInvitedForSurvey, invitedUsers, setInvitedUsers, getSessionDetails, ...rest }) => {

    
    const [error, setError] = useState('');
    const { sessionId } = useParams();
    // useEffect(() => {
    //     getUsersList();
    // }, []);

    const handleManagerChange = (event) => {
        setError('');  
        const managerId = Number(event.target.value);
        setSelectedUsersToInvite((prevSelectedusers) => {
            if (prevSelectedusers.includes(managerId)) {
                return prevSelectedusers.filter((id) => id !== managerId);
            } else {
                return [...prevSelectedusers, managerId];
            }
        });
    };

    const handleDirectReporteeChange = (event) => {
        setError('');  
        const directReporteeId = Number(event.target.value);
        setSelectedUsersToInvite((prevSelectedusers) => {
            if (prevSelectedusers.includes(directReporteeId)) {
                return prevSelectedusers.filter((id) => id !== directReporteeId);
            } else {
                return [...prevSelectedusers, directReporteeId];
            }
        });
    };

    const handlePeerChange = (event) => {
        setError('');  
        const peerId = Number(event.target.value);
        if (!event.target.disabled) {
            setSelectedUsersToInvite((prevSelectedusers) => {
                if (prevSelectedusers.includes(peerId)) {
                    return prevSelectedusers.filter((id) => id !== peerId);
                } else {
                    return [...prevSelectedusers, peerId];
                }
            });
        }          
    };

    const handleInvitation = () => {
        console.log("selectedUsersToInviteselectedUsersToInvite",selectedUsersToInvite)
        // return
        if(selectedUsersToInvite.length <= 0){
            setError('*Please select at least one user.')
            return false
        }
        else{
            setError('');    
            axios.post(`api/userselfevaluation/evaluateOthers`, {users: selectedUsersToInvite, sessionId: sessionId, userId:selectedEmployee, surveyId:currentSession.surveyId }).then(res => {
                if(res.data.success){
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getOtherUsersInvitedForSurvey();
                        getSessionDetails()
                        handleClose();
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
    };

    const handleAutocompleteChange = (event, newValues) => {
        console.log("newValue",newValues);
        setError('');  
        const newSelectedUserIds = newValues.map((user) => Number(user.id));
        console.log("newSelectedUserIds", newSelectedUserIds);

        // Add new items to selectedUsersToInvite
        const addedItems = newSelectedUserIds.filter(userId => !selectedUsersToInvite.includes(userId));
        setSelectedUsersToInvite(prevSelectedUsers => [...prevSelectedUsers, ...addedItems]);

        const notSelectedIds = coworkers.otherEvaluators.filter(item => !newValues.some(sel => sel.id === item.id)).map(item => item.id);
        console.log("notSelectedIds", notSelectedIds);
        setSelectedUsersToInvite(prevSelectedUsers => prevSelectedUsers.filter(userId => !notSelectedIds.includes(userId)));
        console.log("selectedUsersToInvite", selectedUsersToInvite);
        // Remove items from selectedUsersToInvite
        // const removedItems = selectedUsersToInvite.filter(userId => !newSelectedUserIds.includes(userId));
        // setSelectedUsersToInvite(prevSelectedUsers => prevSelectedUsers.filter(userId => !removedItems.includes(userId)));
    };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Modal.Header>
                <h5 className="modal-title fs-14 mb-0">Select the people who will evaluate</h5>
                <CancelIcon style={{ cursor: "pointer" }} onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={6}>            
                    <Grid item xs={12}>
                        <>
                            {coworkers.length !== 0 ? (
                                <div className='row '>
                                        {coworkers.manager !== undefined ? (
                                            <>
                                                <div className='col-md-3'>
                                                    <h6 className='fs-14 fw-semibold'>Managers</h6>
                                                    <FormGroup
                                                    ListboxProps={{
                                                        sx: { fontSize: 13,
                                                            fontFamily: 'Poppins' },
                                                    }}
                                                    sx={{
                                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                            fontSize: 13,
                                                            fontFamily: 'Poppins', // Add your desired font family
                                                            fontWeight: '500', // Add your desired font weight
                                                        },
                                                    }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    key={"manager_" + coworkers.manager.id}
                                                                    onChange={handleManagerChange}
                                                                    value={coworkers.manager.id}
                                                                    checked={selectedUsersToInvite.includes(coworkers.manager.id)}
                                                                    disabled={invitedUsers.includes(coworkers.manager.id)}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                    name="managers"
                                                                />
                                                            }
                                                            label={<span style={{ fontSize: '13px' }}>{coworkers.manager.name}</span>}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </>
                                        ) : ('')}
                                        
                                        {coworkers.directReportees !== undefined && coworkers.directReportees.length > 0 ? (
                                            <>
                                            <div className='col-md-3'>
                                                <h6 className='fs-14 fw-semibold'>Direct Reportee</h6>
                                                <FormGroup
                                                ListboxProps={{
                                                    sx: { fontSize: 13,
                                                        fontFamily: 'Poppins' },
                                                }}
                                                sx={{
                                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                        fontSize: 13,
                                                        fontFamily: 'Poppins', // Add your desired font family
                                                        fontWeight: '500', // Add your desired font weight
                                                    },
                                                }}
                                                >
                                                    {coworkers.directReportees.map((directReportee) => (
                                                        <FormControlLabel
                                                            key={"directReportee_" + directReportee.id}
                                                            control={
                                                                <Checkbox
                                                                    value={directReportee.id}
                                                                    onChange={handleDirectReporteeChange}
                                                                    checked={selectedUsersToInvite.includes(directReportee.id)}
                                                                    disabled={invitedUsers.includes(directReportee.id)}
                                                                    name="directReportees"
                                                                />
                                                            }
                                                            label={<span style={{ fontSize: '13px' }}>{directReportee.name}</span>}
                                                        />
                                                    ))}
                                                </FormGroup>                                                
                                            </div>
                                            </>
                                        ) : (
                                            ''
                                        )}

                                        {coworkers.peers !== undefined && coworkers.peers.length > 0 ? (
                                            <>
                                                <div className='col-md-3'>
                                                    <h6 className='fs-14 fw-semibold'>Peers</h6>
                                                    <FormGroup>
                                                        {coworkers.peers.map((peer) => (
                                                            <FormControlLabel
                                                                key={"peer_" + peer.id}
                                                                control={
                                                                    <Checkbox
                                                                        value={peer.id}
                                                                        onChange={handlePeerChange}
                                                                        checked={selectedUsersToInvite.includes(peer.id)}
                                                                        disabled={invitedUsers.includes(peer.id)}
                                                                        name="peers"
                                                                    />
                                                                }
                                                                label={<span style={{ fontSize: '13px' }}>{peer.name}</span>}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </div> 
                                            </>
                                        ) : ('')}
                                         
                                        {coworkers.otherEvaluators !== undefined && coworkers.otherEvaluators.length > 0 ? (
                                            <>                                  
                                                <div className='col-md-3'>
                                                    <h6 className='fs-14 fw-semibold'>Others</h6>
                                                    <FormGroup>
                                                        <Autocomplete
                                                            fullWidth
                                                            disablePortal
                                                            multiple                                              
                                                            onChange={handleAutocompleteChange}
                                                            getOptionDisabled={(option) => selectedUsersToInvite.includes(option.id)}
                                                            id="combo-box-demo"
                                                            options={coworkers.otherEvaluators}
                                                            ListboxProps={{
                                                                sx: { fontSize: 13,
                                                                    fontFamily: 'Poppins' },
                                                              }}
                                                              sx={{
                                                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                                    fontSize: 13,
                                                                    fontFamily: 'Poppins', // Add your desired font family
                                                                    fontWeight: '500', // Add your desired font weight
                                                                    width: 300 
                                                                },
                                                              }}
                                                            size='small'
                                                            renderInput={(params) => <TextField {...params} label="Other Evaluators" />}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </>
                                        ) : ('-')}
                                </div>
                            ) : ('')}
                        </>
                    </Grid>                     
                
                </Grid><p className="text-danger text-end">{error && error}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button size='small' type="reset" sx={{ marginRight: 3.5 }} variant="outlined" onClick={handleClose}> Close </Button>
                <Button style={{marginRight:15}} size='small' type="submit" variant='contained' onClick={handleInvitation}> Submit </Button>
            </Modal.Footer>
    </Modal>
  )
}

InviteCoworker.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
export default InviteCoworker