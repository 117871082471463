import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import axios from 'axios'
import Swal from 'sweetalert2'
import Add from './Add'
import UserLimit from './UserLimit'
import UploadExcel from './UploadExcel'

import AddGuest from './AddGuest'
import UploadExcelGuest from './UploadExcelGuest'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showGuest, setShowGuest] = useState(false);
    const [showexcelform, setShowexcelform] = useState(false);
    const [showExcelGuestform, setShowExcelGuestform] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const [editGuestId, setEditGuestId] = useState(0);
    const [users, setUsers] = useState([]);
    const [guests, setGuests] = useState([]);
    const [usedUserCount, setUsedUserCount] = useState(0);
    const [allowedUserCount, setAllowedUserCount] = useState(0);

    
    const [userLimitPopupShow, setUserLimitPopupShow] = useState(false);
    useEffect(() => {
        if(user.role !== 'Admin'){
            navigate('/dashboard')
        }
        getList();
        getUsersLimit();
    }, [])

    useEffect(() => {
        getGuestList(); // call this based on tab clicked.
    }, [])

    const getList = async() => {
        await axios.get(`/api/users?role=User&parentId=${user.id}`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
                setUsedUserCount(result.data.length)
            }
        })
    }

    const getGuestList = async() => {
        await axios.get(`/api/guests`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setGuests(result.data)
            }
        })
    }

    const getUsersLimit = async() => {
        await axios.get(`/api/users/getUsersLimit`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setAllowedUserCount(result.data)
            }
        })
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }
    const handleUpload = () => {
        setEditId(0);
        setPopupType('UploadExcel');
        setShowexcelform(true);
    }
    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    const handleAddGuest = () => {
        setEditGuestId(0);
        setPopupType('Add');
        setShowGuest(true);
    }
    const handleEditGuest = (id) => {
        setEditGuestId(id);
        setPopupType('Edit');
        setShowGuest(true);
    }
    const handleUploadGuest = () => {
        setEditGuestId(0);
        setPopupType('UploadExcel');
        setShowExcelGuestform(true);
    }


    const handleClose = () => setShow(false);
    const handleCloseExcel = () => setShowexcelform(false);
    const handleCloseGuest = () => setShowGuest(false);
    const handleCloseGuestExcel = () => setShowExcelGuestform(false);
    const handleUserLimitPopupClose = () => setUserLimitPopupShow(false);
    
    const handleLimit = () => {
        setUserLimitPopupShow(true);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/users/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        getList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleDeleteGuest = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/guests/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        getGuestList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleStatus = (e, id) => {       
        var status = '1';
        if(e.target.checked === true) {
            status = '1'
        }
        else {
            status = '0'
        }
        axios.put(`api/users/updateStatus?id=${id}`, {status}).then(res => {                
        })
        .catch(err => {
        })
    }
    const handleUserLogin = (email) => {
        // Send a POST request to your API endpoint with the email as data
        axios.post('/api/auth/another/login', { email: email, isSuperAdmin: user.isSuperAdmin })
          .then((res) => {
            if (res.data.success) {
                console.log("res.data.dataanother/login user", res.data.data)
                // res.data.data.isSuperAdmin = '0';
                // res.data.data.isAdmin = '0';
                // if( res.data.data.role === 'User'){
                //     res.data.data.isAdmin = '1';
                // }

              localStorage.clear();
              localStorage.setItem('authUser', JSON.stringify(res.data.data));
              navigate('/dashboard');
            } else {
              Swal.fire(res.data.message, '', 'info');
            }
          })
          .catch((error) => {
            Swal.fire(error.message, '', 'info');
          });
      };

    const columns = [
        { id: 'name', label: 'Name', minWidth: 120 },
        { id: 'email', label: 'Email', minWidth: 150 },
        { id: 'manager', label: 'Manager'},
        { id: 'phone', label: 'Phone Number'},
        // { id: 'role', label: 'Role'},
        { id: 'status', label: 'Status'},
        { id: 'action', label: 'Action'},
        { id: 'login', label: 'Login'}
    ]

    const columnsGuest = [
        { id: 'name', label: 'Name', minWidth: 120 },
        { id: 'email', label: 'Email', minWidth: 150 },
        { id: 'action', label: 'Action'}
    ]

    return (
        <UserLayout>

            <Tabs>
                {/* <TabList> */}
                    <Tab><h1>Users</h1></Tab>
                    {/* <Tab><h1>Guest Users</h1></Tab> */}
                {/* </TabList> */}

                <TabPanel>
                    <div className="row">
                        <div className='col-md-4 col-6 mb-3'></div>
                        <div className='col-md-8 col-12 text-md-end mb-3'>
                            <Button size='small' variant='contained' sx={{ marginRight: 3.5 }} onClick={ ((usedUserCount < allowedUserCount) ? handleUpload : handleLimit) }>Upload Excel</Button>
                            <Button size='small' variant='contained' onClick={ ((usedUserCount < allowedUserCount) ? handleAdd : handleLimit) }>Add User</Button>
                            { Math.round(((70/100)*allowedUserCount)) <= usedUserCount ? 
                                (<p className="mt-2 text-danger" style={{fontSize:'12px'}}>*Your max users limit is about to expire, you can upgrade your plan to add more users.</p>)
                            : "" }
                        </div>
                    </div>
                    <Grid container spacing={6}>            
                        <Grid item xs={12}>
                            <Card>
                                <TableStickyHeader columns={columns} rows={users} setEditId={setEditId} setPopupType={setPopupType} setShow={setShow} handleEdit={handleEdit} handleDelete={handleDelete} handleStatus={handleStatus} handleUserLogin={handleUserLogin}/>
                            </Card>
                        </Grid>           
                    </Grid>
                </TabPanel>
                <TabPanel>
                    <div className="row">
                        <div className='col-md-4 col-6 mb-3'></div>
                        <div className='col-md-8 col-12 text-md-end mb-3'>
                            <Button size='small' variant='contained' sx={{ marginRight: 3.5 }} onClick={handleUploadGuest}>Upload Excel</Button>
                            <Button size='small' variant='contained' onClick={handleAddGuest }>Add Guest User</Button>
                        </div>
                    </div>
                    <Grid container spacing={6}>            
                        <Grid item xs={12}>
                            <Card>
                                <TableStickyHeader columns={columnsGuest} rows={guests} setEditId={setEditGuestId} setPopupType={setPopupType} setShow={showGuest} handleEdit={handleEditGuest} handleDelete={handleDeleteGuest}/>
                            </Card>
                        </Grid>           
                    </Grid>
                </TabPanel>
            </Tabs>

            <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/>
            <UploadExcel show={showexcelform} setShow={setShowexcelform} handleClose={handleCloseExcel} getList={getList}/>


            <AddGuest type={popupType} show={showGuest} setShow={setShowGuest} handleClose={handleCloseGuest} getList={getGuestList} editId={editGuestId} setEditId={setEditGuestId}/>
            <UploadExcelGuest show={showExcelGuestform} setShow={setShowExcelGuestform} handleClose={handleCloseGuestExcel} getList={getGuestList}/>

            <UserLimit userLimitPopupShow={userLimitPopupShow} setUserLimitPopupShow={setUserLimitPopupShow} handleUserLimitPopupClose={handleUserLimitPopupClose}/>

        </UserLayout>
    )
}