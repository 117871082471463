import React, { useEffect, useState }  from 'react';
import UserLayout from "../Layout/UserLayout";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import SquareIcon from "@mui/icons-material/Square";
import CircleIcon from "@mui/icons-material/Circle";
import { Box } from '@mui/material';

export default function Survey() {
    const [count, setCount] = useState(5); // Initial count value
    const [labels, setLabels] = useState([]);
    const [generateLabels, setGenerateLabels] = useState(true);
    const [selectedIcon, setSelectedIcon] = useState('Circle'); // State to hold selected icon

    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [surveyRatings, setSurveyRatings] = useState([]);
    const [settingName, setSettingName] = useState('');

    const navigate = useNavigate();
    const {settingId} = useParams();

    // Validation states
    const [settingNameError, setSettingNameError] = useState('');
    const [settingCountError, setSettingCountError] = useState('');
    const [labelsError, setLabelsError] = useState('');

    const [value, setValue] = useState(0)
    const [hover, setHover] = useState(-1)

    const handleCountChange = (event) => {
        if(event.target.value === '' || event.target.value === '0'){
            setLabelsError(false)
            setGenerateLabels(false)
        }
        else{
            setGenerateLabels(true)
        }
        let newCount = event.target.value > 0 ? parseInt(event.target.value, 10) : ''
        setCount(newCount);
    };

    const handleYesClick = () => {
        setGenerateLabels(true);
    };

    const handleNoClick = () => {
        setGenerateLabels(false);
        setLabels([]);
        setLabelsError('');
    };

    const handleLabelChange = (index, event) => {
        const newLabels = [...labels];
        newLabels[index] = event.target.value;
        setLabels(newLabels);
    };

    const handleIconChange = (event) => {
        setSelectedIcon(event.target.value);
    };

    const validate = () => {
        let isValid = true;

        if (settingName.trim() === '') {
            setSettingNameError('Setting name is required');
            isValid = false;
        } else {
            setSettingNameError('');
        }
        if (count == '') {
            setSettingCountError('Count is required');
            isValid = false;
        } else {
            setSettingCountError('');
        }
        if ((count !== 0 || count !== '') && (labels.length === 0 || (labels.length === 1 && labels[0] === null)) && generateLabels) {
            setLabelsError('Label name is required Or select Rating with Labels as No');
            isValid = false;
        } else {
            setLabelsError('');
        }

        return isValid;
    };

    const handleSave = async (e) => {
        e.preventDefault();

        // Validate the form
        if (!validate()) {
            return;
        }

        // Create an array of survey settings with labels
        const labelsArry = labels.map((label, index) => (label));
        const surveySettings = {
            rating: count,
            labels: labelsArry,
            createdBy: user.id,
            name: settingName,
            ratingIcon: selectedIcon // Add selected icon to the settings
        }
        
        // console.log("surveySettingssurveySettings",surveySettings)
        // return
        let url = `api/surveyratingsettings/create`
        if(settingId){
            url = `api/surveyratingsettings/update?id=${settingId}`
        }
        axios.post(url, surveySettings)
            .then((res) => {
                console.log(res.data)
                if (res.data.success) {
                    Swal.fire(res.data.message, "", "success").then((result) => {
                        navigate('/settings_survey')
                    });
                } else {
                    Swal.fire(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                Swal.fire(error.response.data.message, "", "error");
            });
    };

    useEffect(() => {
        if(settingId){
            getSetting();
        }
    }, []);

    const getSetting = async () => {
        await axios.get(`/api/surveyratingsettings/singleSurveySetting?id=${settingId}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                if(result.data){
                    setSettingName(result.data.name)
                    setCount(result.data.rating)
                    setSelectedIcon(result.data.ratingIcon)
                    if(result.data.setting_labels.length > 0){
                        const lablesList = result.data.setting_labels.map((labels)=>(labels.label))
                        setGenerateLabels(true)
                        setLabels(lablesList)
                    }
                }
                else{
                    navigate('/settings_survey')
                }
            } else {
                // setIsError(true);
            }
        });
    };


    const selectEmptyIcon = () => {
        switch (selectedIcon) {
            case "Square":
                return <SquareIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            case "Circle":
                return <CircleIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
            default:
                return <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />;
        }
    }
    const selectRatingIcon = () => {
        switch (selectedIcon) {
            case "Square":
                return <SquareIcon />;
            case "Circle":
                return <CircleIcon />;
            default:
                return <StarIcon />;
        }
    }
    const getLabelText = (value) => {
        return `${value} ${selectedIcon}${value !== 1 ? "s" : ""}, ${labels[value]}`;
    }
    return (
        <UserLayout>
            
            <div className="row">
                <div className="col-md-6">
                    <h1 className="mb-3">Add Settings</h1>
                </div>
            </div>
            <div className='card p-3'>
                <div className='row d-flex align-items-center'>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-lg-6 col-md-6">
                                    <Typography variant="body" sx={{ marginBottom: 2 }}>
                                        <h6 className="fs-14">Setting Name : </h6>
                                    </Typography>
                                </div>
                                <div className="col-lg-6 col-md-6 text-md-end">
                                    <TextField
                                        fullWidth
                                        label="Setting Name"
                                        name="name"
                                        size='small'
                                        value={settingName}
                                        onChange={(e) => setSettingName(e.target.value)}
                                        listboxprops={{
                                            sx: { fontSize: 13,
                                                fontFamily: 'Poppins' },
                                            }}
                                            sx={{
                                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                fontSize: 13,
                                                fontFamily: 'Poppins', // Add your desired font family
                                                fontWeight: '500', // Add your desired font weight
                                            },
                                            }}
                                        error={!!settingNameError}
                                        helperText={settingNameError}
                                    />
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">    
                        <div className="mb-3">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-lg-6 col-md-6">
                                    <Typography variant="body" sx={{ marginBottom: 2 }}>
                                        <h6 className="fs-14">Rating Icon : </h6>
                                    </Typography>
                                </div>
                                <div className="col-lg-6 col-md-6 text-md-end">
                                    <RadioGroup className='d-flex align-items-center justify-content-end' row value={selectedIcon} onChange={handleIconChange}>
                                        <FormControlLabel name="ratingIcon" value="Circle" control={<Radio color="primary" />} label={<span className='fs-13'>Circle</span>} />
                                        <FormControlLabel name="ratingIcon" value="Square" control={<Radio color="primary" />} label={<span className='fs-13'>Square</span>} />
                                        <FormControlLabel name="ratingIcon" value="Star" control={<Radio color="primary" />} label={<span className='fs-13'>Star</span>} />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-lg-6 col-md-6">
                                    <Typography variant="body" sx={{ marginBottom: 2 }}>
                                        <h6 className="fs-14">Rating  : </h6>
                                    </Typography>
                                </div>
                                <div className="col-lg-6 col-md-6 text-md-end">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id="count"
                                        label="Count"
                                        variant="outlined"
                                        name="rating"
                                        type="number"
                                        value={count}
                                        listboxprops={{
                                            sx: { fontSize: 13,
                                                fontFamily: 'Poppins' },
                                            }}
                                            sx={{
                                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                fontSize: 13,
                                                fontFamily: 'Poppins', // Add your desired font family
                                                fontWeight: '500', // Add your desired font weight
                                            },
                                            }}
                                        onChange={handleCountChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><span className='fs-14'>out of</span></InputAdornment>,
                                            inputProps: { min: 1 }
                                        }}  
                                        error={!!settingCountError}
                                        helperText={settingCountError}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-lg-6 col-md-6">
                                    <Typography variant="body" sx={{ marginBottom: 2 }}>
                                        <h6 className="fs-14">Rating with Labels : </h6>
                                    </Typography>
                                </div>
                                <div className="col-lg-6 col-md-6 text-md-end">
                                    <div className="button-wrap">
                                        <input className="hidden radio-label" type="radio" name="label" id="yes-button" defaultChecked={generateLabels?true:false} onClick={handleYesClick} />
                                        <label className="button-label" htmlFor="yes-button">
                                            <span className="fs-13 fw-semibold">Yes</span>
                                        </label>
                                        <input className="hidden radio-label" type="radio" name="label" id="no-button" defaultChecked={generateLabels?false:true} onClick={handleNoClick} />
                                        <label className="button-label" htmlFor="no-button">
                                            <span className="fs-13 fw-semibold">No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        {generateLabels && (
                            <div className='row d-flex align-items-center'>
                            {Array.from({ length: count }, (_, index) => (
                                <div className='col-4' key={'col-4-'+index}>
                                    <div className="mb-3" key={index}>
                                        <div className="row d-flex align-items-center justify-content-between">
                                            <div className="col-md-4">
                                                <Typography variant="body" sx={{ marginBottom: 2 }}>
                                                    <h6 className='fs-14'>Label {index + 1} : </h6>
                                                </Typography>
                                            </div>
                                            <div className="col-md-8 text-end">
                                                <TextField id={`label-${index}`}
                                                    fullWidth
                                                    listboxprops={{
                                                        sx: { fontSize: 13,
                                                            fontFamily: 'Poppins' },
                                                        }}
                                                        sx={{
                                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                            fontSize: 13,
                                                            fontFamily: 'Poppins', // Add your desired font family
                                                            fontWeight: '500', // Add your desired font weight
                                                        },
                                                    }}
                                                label="Type here" size='small' variant="outlined" value={labels[index] || ""} onChange={(event) => handleLabelChange(index, event)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            ))}  
                            <span className='error_msg'>{labelsError}</span>                          
                        </div>
                        )}
                    </div>
                    <div className="d-flex align-items-center mt-3">
                        <div className='mx-auto d-flex align-items-center justify-content-center'>
                            Sample Output: 
                            <Box
                                sx={{
                                    width: 200,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: '5px',
                                }}
                                >
                                <Rating
                                    name="hover-feedback"
                                    value={value-1}
                                    max={count}
                                    getLabelText={getLabelText}
                                    onChange={(event, newValue) => {
                                        setValue(newValue-1);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover-1);
                                    }}
                                    emptyIcon={selectEmptyIcon()}
                                    icon={selectRatingIcon()}
                                />
                            </Box>
                        </div>
                        <div className='text-end'>
                        <Button variant="contained" className="" onClick={handleSave}>Save</Button>
                        </div>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-2'>
                            {value !== null && (
                                <Box sx={{fontSize:'12px', marginLeft: '30px', textAlign:'left'}}>{labels[hover !== -1 ? hover : value]}</Box>
                            )}
                        </div>
                    </div>
                </div>


            </div>
            
        </UserLayout>
    );
}
