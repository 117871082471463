import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';

export default function SelectPlan() {    
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const verification_token = searchParams.get("id");
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [selectedPlanPrice, setSelectedPlanPrice] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const signingUpUser = localStorage.getItem('signingUpUser');
    const newUser = JSON.parse(signingUpUser);
    
    const loggedInUser = localStorage.getItem('authUser');
    const authUser = JSON.parse(loggedInUser);

    useEffect(() => {
        getList()
        // if(verification_token == '' || newUser === null){
        if(verification_token == ''){
            navigate('/')
        }
        else{
            // Check verification token is valid
        }
    }, [])

    useEffect(() => {
        if (plans.length > 0) {
            const freePlan = plans.find(plan => parseFloat(plan.price) === 0.00);
            if (freePlan) {
                setSelectedPlan(freePlan.id);
                setSelectedPlanPrice(freePlan.price);
            }
        }
    }, [plans]);

    const getList = async() => {
        await axios.get('/api/plans?isPublic=1')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setPlans(result.data)
            }
        })
    }

    const handleChange = (id,price) => {
        setSelectedPlan(id)
        setSelectedPlanPrice(price)
        // options.amount = price
        // console.log(newUser)
    }

    const handleSubmit = async () => {  
        setIsSubmitting(true) 
        let userId ; 
        let userEmail ; 
        let userName ; 
        if(authUser){
            userId = authUser.id;
            userEmail = authUser.email;
            userName = authUser.name;
        }
        if(newUser){
            userId = newUser.id;
            userEmail = newUser.email;
            userName = newUser.name;
        }
        // setTimeout(() => {            
        //     setIsSubmitting(false) 
        // }, 1000);
        axios.post(`/api/plans/create-checkout-session`, {
            userId: userId,
            email: userEmail,
            name: userName,
            planId: selectedPlan,
            verification_token: verification_token,
        })
        .then((response) => {
            console.log("payment response===",response.data)
            
            if(response.data.success){
                if (response.data.session.url) {
                    window.location.href = response.data.session.url;
                }
            }
            else{                
                setIsSubmitting(false) 
                Swal.fire(response.data.message, '', 'info')
            }

        })
        .catch((err) => {            
            setIsSubmitting(false) 
            console.log(err.message)
            Swal.fire(err.message, '', 'info')
        });
    }

    const handleFreeSubmit = () => { 
        setIsSubmitting(true) 
        axios.post('/api/auth/selectPlan', {planId: selectedPlan, verification_token: verification_token}).then((res) =>{
            if(res.data.success){
                Swal.fire('Registration Successful!', '', 'success').then(result => {
                    localStorage.setItem('authUser', JSON.stringify(res.data.data));
                    navigate('/dashboard');
                })                
            }
            else{
                setIsSubmitting(false)
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            setIsSubmitting(false)
            Swal.fire(error.message, '', 'info')        
        });
    }
    
    return (
        
            <section className="py-1">
                <div className="container py-4">
                    <div className="row text-center mb-5">
                        <div className="col-lg-7 mx-auto">                          
                            <img className="mb-4" src="./images/logo.png" alt="..." width="150"/>
                            <h2 className="mb-1">Choose Your subscription plan</h2>                           
                        </div>
                    </div>
                    <div className="grid py-3">
                        {
                            plans.length > 0 ? 
                            plans.map(plan => (
                                <label className="card ss-label rounded" key={"plan_id"+plan.id}>
                                    <input type="radio" name="plan" className="radio" value={plan.id} onChange={() => handleChange(plan.id,plan.price)} checked={selectedPlan === plan.id} />                            
                                    <span className="plan-details">
                                        <span className="plan-type">{plan.name}</span>
                                        <span className="plan-cost text-center border-bottom border-2">{plan.currency=='usd'?"$":"₹"}{plan.price}
                                            <span className="slash">/</span><span className="plan-cycle">month
                                            </span>
                                        </span>
                                        <span className="fs-13 my-3 text-center">{plan.description}</span>
                                        <span className="fw-semibold text-center">Upto {plan.noOfUsers} Users </span>
                                    </span>
                                </label>                              
                            ))
                            : ''
                        }
                    </div>
                    <div >
                        <div className="row d-flex justify-content-center align-items-center py-5">
                            <div className="col-auto" style={{display: selectedPlan > 0 && (selectedPlanPrice > 0 || newUser !== null) ? 'block' : 'none'}}>
                            {
                                isSubmitting ? 
                                    <button className="btn btn-secondary disabled_btn" >Processing...</button>
                                    : 
                                    <button className="btn btn-theme" type="submit" onClick={selectedPlanPrice > 0 ? handleSubmit : handleFreeSubmit }>Subscribe</button>
                            }
                            </div>
                        {
                            newUser === null ?
                            <div className='text-end col-auto'>
                                <Link to="/profile?activeTab=info" className="btn btn-secondary">Back</Link>
                            </div>
                            :''
                        } 
                        </div>  
                    </div>                 
                </div>                
            </section>     
        
    )
}