// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ThemeComponent from '../../theme/ThemeComponent'
import { useState } from 'react'
import themeConfig from '../../configs/themeConfig'
// Styled component for Blank Layout component
const BlankLayoutWrapper = styled(Box)(({ theme }) => ({
  height: '100vh',

  // For V1 Blank layout pages
  '& .content-center': {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5)
  },

  // For V2 Blank layout pages
  '& .content-right': {
    display: 'flex',
    minHeight: '100vh',
    overflowX: 'hidden',
    position: 'relative'
  }
}))

const BlankLayout = ({ children }) => {
    const initialSettings = {
        themeColor: 'primary',
        mode: themeConfig.mode,
        contentWidth: themeConfig.contentWidth
      }
      
    const [settings, setSettings] = useState({ ...initialSettings })
  return (
    <ThemeComponent settings={settings}>
        <BlankLayoutWrapper className='layout-wrapper'>
        <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
            {children}
        </Box>
        </BlankLayoutWrapper>
    </ThemeComponent>
  )
}

export default BlankLayout
