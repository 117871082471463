import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import SearchableSelect from './SearchableSelect';

const validationSchema = yup.object({
    managerId: yup.number().required('Manager is required'),
    name: yup.string('Enter your name').required('Name is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    // phone: yup.number().min(10, "Must be more than 10 characters")
});

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {
    const [users, setUsers] = useState([]);
    const initialValues = {
        id: editId,
        managerId: '',
        name: '',
        email: '',
        phone: '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        formik.resetForm();
        getUsers();
        if(editId > 0){
            axios.get(`api/user?id=${editId}`).then(res => {
                // console.log("res--------------",res.data.data);
                if(res.data.success){
                    formik.setFieldValue('id', res.data.data.id)
                    formik.setFieldValue('managerId', res.data.data.managerId)
                    formik.setFieldValue('name', res.data.data.name)
                    formik.setFieldValue('email', res.data.data.email)
                    formik.setFieldValue('phone', parseInt(res.data.data.phone))
                }
            })
        }
        else{
            // formik.setFieldValue('id', 0)
            // formik.setFieldValue('managerId', '')
            // formik.setFieldValue('name', '')
            // formik.setFieldValue('email', '')
            // formik.setFieldValue('phone', '')
            formik.resetForm();
        }
        
    },[show]);

    const getUsers = async() => {
        await axios.get('/api/users?isAdminUsers=1')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }
    
    const handleSubmit = (values) =>{
        if(editId > 0){
            axios.put(`api/users/update`, values).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                        // formik.setValues(initialValues)       
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/users/create`, values).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        // formik.setValues(initialValues)
                        formik.resetForm();
                        getList();
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">{type} User</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={6}>
                            <SearchableSelect users={users} formik={formik}/>
                            {/* <TextField fullWidth select
                                listboxprops={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                size='small'
                                label='Select Manager'
                                id='managerId'
                                value={formik.values.managerId}
                                onChange={(e) => formik.setFieldValue('managerId', e.target.value)}
                                error={formik.touched.managerId && Boolean(formik.errors.managerId)}
                                helperText={formik.touched.managerId && formik.errors.managerId}
                            >
                                <MenuItem value="">Please Select</MenuItem>
                                {
                                    users.length > 0 ? 
                                    users.map(user => (
                                        <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                                    ))
                                    : ''
                                }                         
                            </TextField> */}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField fullWidth
                                listboxprops={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                size='small'
                                id='name'
                                label='Name' 
                                placeholder='Name' 
                                value={formik.values.name} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField fullWidth
                                listboxprops={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                size='small'
                                id='email'
                                label='Email' 
                                placeholder='example@example.com' 
                                value={formik.values.email} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth
                                listboxprops={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                }}
                                size='small'
                                type='number'
                                id='phone'
                                label='Phone Number'
                                value={formik.values.phone} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>
                        
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='small' type='reset' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleClose}>
                        Close
                    </Button>
                    <Button size='small' type="submit" variant='contained'>
                        {type == 'Add' ? 'Add' : 'Update'}
                    </Button>              
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};