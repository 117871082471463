import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';

// ** MUI Imports
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    name: yup.string('Enter your name').required('Name is required')
});

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {
    const initialValues = {
        id: editId,
        name: '' 
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if(editId > 0){
            axios.get(`api/singleCompetency?id=${editId}`).then(res => {
                // console.log(res);
                if(res.data.success){
                    formik.setFieldValue('id', res.data.data.id)
                    formik.setFieldValue('name', res.data.data.name)
                }
            })
        }
        else{
            formik.setFieldValue('id', 0)
            formik.setFieldValue('name', '')
        }
    },[show]);

    //   

    const handleSubmit = (values) =>{
        if(editId > 0){
            axios.put(`api/competencies/update`, values).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();                        
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/competencies/create`, values).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">{type} Competency</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid item xs={12} sm={12}>
                        <TextField fullWidth
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            id='name'
                            size='small'
                            label='Compentency Name' 
                            placeholder='Compentency Name' 
                            value={formik.values.name} 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='reset' sx={{ marginRight: 3.5 }} size='small' variant='outlined' onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant='contained' size='small'>
                        {type == 'Add' ? 'Add' : 'Update'}
                    </Button>               
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};