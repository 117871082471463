import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import axios from 'axios'
import Swal from 'sweetalert2'

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const [surveySettings, setSurveySettings] = useState([]);

    useEffect(() => {
        if(user.role !== 'Admin'){
            navigate('/dashboard')
        }
        getList();
    }, [])

    const getList = async() => {
        await axios.get(`/api/surveyratingsettings?createdBy=${user.id}`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setSurveySettings(result.data)
            }
        })
    }

    const handleEdit = (id) => {
        navigate('/edit_setting/'+id)
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/surveyratingsettings/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        getList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleStatus = (e, id) => {       
        var status = '1';
        if(e.target.checked === true) {
            status = '1'
        }
        else {
            status = '0'
        }
        axios.put(`api/users/updateStatus?id=${id}`, {status}).then(res => {                
        })
        .catch(err => {
        })
    }

    const columns = [
        { id: 'name', label: 'Name', minWidth: 120 },
        { id: 'ratingIcon', label: 'Rating Icon'},
        { id: 'rating', label: 'Ratings'},
        // { id: 'status', label: 'Status'},
        { id: 'action', label: 'Action'},
    ]

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-4 col-6 mb-3'>
                    <h1 className=''>Rating Defaults</h1>
                </div>
                <div className='col-md-8 col-12 text-md-end mb-3'>
                    <Link to="/add_setting" size='small' className='ss_btn'>Add Setting</Link>
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        <TableStickyHeader columns={columns} rows={surveySettings} handleDelete={handleDelete} handleStatus={handleStatus} handleEdit={handleEdit}/>
                    </Card>
                </Grid>           
            </Grid>
        </UserLayout>
    )
}