import React from 'react';
import {Chart, 
  ArcElement, 
  // Tooltip, Legend, Title
} from 'chart.js';
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, 
  // Tooltip,// Legend, Title
  );
const MaxValue = 7;
const DoughnutChart = ({chartData}) => {
  const data = {
    labels: ['Self', 'Others', 'Average'],
    datasets: [

      {
        // label:"Self",
        data: [chartData.self, (MaxValue-chartData.self).toFixed(1)],
        backgroundColor: ['#eb5151','#eee'],
        borderColor: "#D1D6DC",
        borderWidth: 1,
        radius: '100%',
        // maintainAspectRatio: true,
      },
      {
        // label:"Others",
        data: [chartData.other,(MaxValue-chartData.other).toFixed(1)],
        backgroundColor: ['#2e689a','#eee'],
        borderColor: "#D1D6DC",
        borderWidth: 1,
        radius: '100%',
        // maintainAspectRatio: true,
      },
      {
        // label:"Average",
        data: [chartData.average,(MaxValue-chartData.average).toFixed(1)],
        backgroundColor: ['#1d232d','#eee'],
        borderColor: "#D1D6DC",
        borderWidth: 1,
        radius: '100%',
        // maintainAspectRatio: true,
      }
    ]
  };

  const options = {
      plugins: {
        datalabels: {
          display: true,
          color:'white',
          formatter: function (value, context) {
            if(context.dataIndex === 0){
              return value
            }
            return ''
          },
          // align: 'bottom',
          // backgroundColor: '#ccc',
          // borderRadius: 3,
          // font: {
          //   size: 18,
          // },
        },
        legend: {
          display: true,
          onClick: (e) => e.stopPropagation(),
          labels: {
            usePointStyle: true,
            generateLabels (chart){
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const {labels: {pointStyle, color}} = chart.legend.options;
                return data.labels.map((label, i) => {
                  // const meta = chart.getDatasetMeta(0);
                  // const style = meta.controller.getStyle(i);
                  let fillStyle = ''
                  if(label === 'Self'){
                    fillStyle = '#eb5151'
                  }
                  if(label === 'Others'){
                    fillStyle = '#2e689a'
                  }
                  if(label === 'Average'){
                    fillStyle = '#1d232d'
                  }
                  return {
                    text: label,
                    fillStyle: fillStyle,
                    strokeStyle: fillStyle,
                    fontColor: color,
                    // lineWidth: style.borderWidth,
                    // pointStyle: pointStyle,
                    // hidden: !chart.getDataVisibility(i),
  
                    // Extra data used for toggling the correct item
                    index: i
                  };
                })
              }
              return []
            }
          }
        },
        tooltip: {
          enabled: false
        },
        title: {
          display: true,
          font: {
            size: 14,
          },
          text: chartData.domain,
          position: 'bottom',
        }
      },
      rotation: -90,
      circumference: 180,
      // cutout: "40%",      
      responsive: true,
      maintainAspectRatio: false
  }

  return (
    <Doughnut data={data} options={options} />
  );
}

export default DoughnutChart;

