export const Services = () => {
    return (
    <section className="bg-light py-5">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 ">
                    <div className="img-gradient"><img className="img-fluid shadow rounded" src="./images/simplify-6.jpg" alt=""/></div>
                </div>
                <div className="col-md-6 mb-5 mb-md-0">
                    <p className="h6 text-theme text-uppercase">Why PeopleAssay?</p>
                    <h2 className="mb-3">Simplify Feedback, Amplify Results</h2>
                    <p className="text-muted fs-14">Streamline your feedback processes, drive actionable insights, and foster a culture of continuous improvement with PeopleAssay's intuitive platform. From customizable templates to advanced analytics, we've got everything you need to unlock your organization's full potential.</p>
                </div>
            </div>
            {/*<div className="text-center map-bg bg-transparent">
                <div className="card-body p-lg-5">
                    <div className="p-4 py-0">
                        <div className="row">
                            <div className="col-lg-9 mx-auto">
                                <p className="h6 text-theme text-uppercase">Why PeopleAssay?</p>
                                <h2 className="mb-3">Simplify Feedback, Amplify Results</h2>
                                <p className="text-muted">Streamline your feedback processes, drive actionable insights, and foster a culture of continuous improvement with PeopleAssay's intuitive platform. From customizable templates to advanced analytics, we've got everything you need to unlock your organization's full potential.</p>
                            </div>
                        </div>
                        {/* <div className="row gy-3">
                            <div className="col-lg-4">
                                <img src="./images/1.png" alt="..."/>
                                <h5>Quality coordinator</h5>
                                <p className="text-muted mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elite.</p>
                            </div>
                            <div className="col-lg-4">
                                <img src="./images/2.png" alt="..."/>
                                <h5>Financing management</h5>
                                <p className="text-muted mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elite.</p>
                            </div>
                            <div className="col-lg-4">
                                <img src="./images/3.png" alt="..."/>
                                <h5>Insurance invest</h5>
                                <p className="text-muted mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elite.</p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>*/}
        </div>
    </section>
    )
}

export default Services;