import React, { useEffect, useState } from "react";
import UserLayout from "../Layout/UserLayout";
import axios from "axios";
import Add from "./Add";
import EditQuestion from "./../Question/EditQuestion"
import Swal from "sweetalert2";

import {Grid, Card, Accordion, AccordionDetails, AccordionSummary, Typography, MenuItem, TextField, Button, IconButton, Tooltip} from "@mui/material";
import { Link } from "react-router-dom";
import Competency from "../Common/Competency";

export default function List() {
    const authUser = localStorage.getItem("authUser");
    const role = JSON.parse(authUser).role;
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState("Add");
    const [editId, setEditId] = useState(0);
    const [selectedUser, setSelectedUser] = useState([]);
    const [competencies, setCompetencies] = useState([]);
    const [users, setUsers] = useState([]);
    // const [showQuestion, setShowQuestion] = useState(false);
    // const [editQuestionId, setEditQuestionId] = useState(0);


    useEffect(() => {
        getList();
    }, [selectedUser]);

    useEffect(() => {
        getUsersList();
    }, []);

    const getList = async () => {
        let apiUrl = "/api/competencies";

        if (role === "SuperAdmin") {
            apiUrl = `/api/competencies?userId=${selectedUser}`;
        }

        await axios.get(apiUrl).then(function (response) {
            let result = response.data;
            if (result.success) {
                setCompetencies(result.data);
            } else {
                // Handle error or set isError state if needed.
            }
        });
    };
    const getUsersList = async () => {
        await axios.get(`/api/users?role=Admin&parentId=2`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setUsers(result.data);
                // console.log(result.data);
            } else {
                // setIsError(true);
            }
        });
    };

    // const handleSelectChange = (e) => {
    //     const selectedUserId = e.target.value;
    //     // const selectedUser1 = users.find(user => user.id === selectedUserId);
    //     setSelectedUser(selectedUserId);
    //     console.log("selectedUser", selectedUserId);
    // };

    const handleAdd = () => {
        setEditId(0);
        setPopupType("Add");
        setShow(true);
    };    
    const handleClose = () => setShow(false);

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }
    // const handleQuestionEdit = (id) => {
    //     setEditQuestionId(id);
    //     setShowQuestion(true);
    // }
    // const handleQuestionClose = () => setShowQuestion(false);
    return (
        <UserLayout>
            <div className="row">
                <div className="col-md-8 col-6 mb-3">
                    <h1 className="">Competencies</h1>
                </div>
                {/* <div className="col-md-4">
                    {role === "SuperAdmin" && (
                        <TextField fullWidth select
                            label='Select Company'
                            id='ComapanyId'
                            value={selectedUser ? selectedUser.id : ""}
                            onChange={handleSelectChange}
                        >
                            <MenuItem selected hidden>Please Select</MenuItem>
                            <MenuItem value="2">Super Admin</MenuItem>
                            {
                                users.length > 0 ? 
                                users.map(user => (
                                    <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                                ))
                                : ''
                            }
                        </TextField>
                    )}                    
                </div> */}
                <div className="col-md-4 col-6 text-end mb-3">
                    <Button size='small' variant="contained" onClick={() => handleAdd()}>
                        Add Competency
                    </Button>                   
                </div>
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <Competency competencies={competencies} getList={getList}/>
                    </Card>
                </Grid>
            </Grid>            
            <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId} />                    
            {/* <EditQuestion showQuestion={showQuestion} setShowQuestion={setShowQuestion} handleQuestionClose={handleQuestionClose} getList={getList} editQuestionId={editQuestionId} setEditQuestionId={setEditQuestionId} />                     */}
        </UserLayout>
    );
}
