import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function About() {
    const state = {
        // margin: 30,
        responsiveClass: true,
        nav: true,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    };
    return (
        <>
            <Header/>
            <section className="py-5">
            
                {/* <div className="container-xxl my-5 pt-4 page-header">
                    <div className="container my-5 pt-5 pb-4">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb text-uppercase">
                                <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div> */}
            
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-md-6 mb-5 mb-md-0">
                            <p className="h6 text-theme text-uppercase">Who We Are</p>
                            <h2 className="mb-3">Meet the Team Behind PeopleAssay</h2>
                            <p className="text-muted fs-14 mb-3">At PeopleAssay, we're on a mission to revolutionize the way organizations approach feedback and development. Meet the passionate team driving innovation and empowering growth every step of the way.</p>
                        </div>
                        <div className="col-md-6 ">
                            <div className="row g-0 about-bg rounded overflow-hidden">
                                <div className="col-6 text-start">
                                    <img className="img-fluid w-100" src="./images/bred/about-1.jpg" />
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid img_width mt_15" src="./images/bred/about-2.jpg"/>
                                </div>
                                <div className="col-6 text-end">
                                    <img className="img-fluid img_width" src="./images/bred/about-3.jpg"/>
                                </div>
                                <div className="col-6 text-end">
                                    <img className="img-fluid w-100" src="./images/bred/about-4.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mb-4">
                        <div className="col-md-12">
                            <OwlCarousel className='ss_carousel' dots={false} responsive={state.responsive} items={3} autoplay ={true} touchDrag={true} lazyLoad={true}>
                                <div className="item">
                                    <div className="card border border_radius">
                                        <img src="./images/avatars/1.png" class="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="py-2 text-center">
                                                <p className="fs-13 fw-semibold mb-0">Sandeep Sharma</p>
                                                <span className="fs-11 text-muted">SOFTWARE ENGINEER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card border border_radius">
                                        <img src="./images/avatars/3.png" class="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="py-2 text-center">
                                                <p className="fs-13 fw-semibold mb-0">Sandeep Sharma</p>
                                                <span className="fs-11 text-muted">SOFTWARE ENGINEER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card border border_radius">
                                        <img src="./images/avatars/4.png" class="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="py-2 text-center">
                                                <p className="fs-13 fw-semibold mb-0">Sandeep Sharma</p>
                                                <span className="fs-11 text-muted">SOFTWARE ENGINEER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card border border_radius">
                                        <img src="./images/avatars/1.png" class="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="py-2 text-center">
                                                <p className="fs-13 fw-semibold mb-0">Sandeep Sharma</p>
                                                <span className="fs-11 text-muted">SOFTWARE ENGINEER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card border border_radius ">
                                        <img src="./images/avatars/2.png" class="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="py-2 text-center">
                                                <p className="fs-13 fw-semibold mb-0">Sandeep Sharma</p>
                                                <span className="fs-11 text-muted">SOFTWARE ENGINEER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="card border border_radius">
                                        <img src="./images/avatars/3.png" class="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <div className="py-2 text-center">
                                                <p className="fs-13 fw-semibold mb-0">Sandeep Sharma</p>
                                                <span className="fs-11 text-muted">SOFTWARE ENGINEER</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div> */}
                </div>
                <div className="bg-theme map-bg py-5">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-lg-9 mx-auto text-white">
                                <p className="h6 text-uppercase">Our Approach</p>
                                <h2 className="mb-3">Innovation, Simplified</h2>
                                <p className="fs-14">Discover our unique approach to feedback and development. From customizable templates to AI-driven insights, we're committed to simplifying the feedback process and delivering actionable results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="container">
                        <div className="row align-items-center mb-4">
                            <div className="col-lg-9 mx-auto text-center">
                                <p className="h6 text-theme text-uppercase">Our Success Stories</p>
                                <h2 className="mb-3">See the Impact of PeopleAssay</h2>
                                <p className="text-muted fs-14">Explore how organizations across industries are using PeopleAssay to drive growth, foster collaboration, and unlock the full potential of their teams. Read our success stories and join the journey to success.</p>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-md-12'>
                                <OwlCarousel className='ss_carousel' dots={false} responsive={state.responsive} items={3} autoplay ={true} touchDrag={true} lazyLoad={true}>
                                    <div className="item">
                                        <div className="card border">
                                            <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                            <div className="card-body">
                                                <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                                <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                <div className="d-grid">
                                                    <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="card border">
                                            <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                            <div className="card-body">
                                                <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                                <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                <div className="d-grid">
                                                    <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="card border">
                                            <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                            <div className="card-body">
                                                <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                                <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                <div className="d-grid">
                                                    <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="card border">
                                            <img src="./images/hero-imgg.jpg" class="card-img-top blogcard_img" alt="..." />
                                            <div className="card-body">
                                                <h5 class="card-title mb-4 fs-15">Lorem ipsum dolor sit amet</h5>
                                                <p class="card-text text-muted fs-13">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                <div className="d-grid">
                                                    <Link href="#" class="btn btn-theme fs-14 fw-semibold">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 mx-auto">
                                <Link href="#" className="btn read_btn fw-semibold py-3 w-100">Read More Success Stories</Link>
                            </div>
                        </div>
                    </div>
                </section>

            <Footer/>            
        </>
        
    )     
}