// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import BlankLayout from '../Layout/BlankLayout'
import FooterIllustrationsV1 from './FooterIllustration'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from "axios";
import Swal from 'sweetalert2';
import { useEffect } from 'react'
// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({    
    [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const validationSchema = yup.object({
    otp: yup
      .string('Enter your verification code')
      .required('Code is required')
});

export default function Verification() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const verification_token = searchParams.get("id");

    useEffect(() => {
        
        if(verification_token == ''){
            navigate('/')
        }
        else{
            // Check verification token is valid
        }
    }, [])

    
    const initialValues = {
        verification_token: verification_token,
        otp: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        axios.post('/api/auth/validateVerificationCode', values).then((res) =>{
            if(res.data.success){
                localStorage.setItem('signingUpUser', JSON.stringify(res.data.data));
                Swal.fire(res.data.message, '', 'success').then(result => {
                    navigate("/select_plan?id="+res.data.verification_token)
                })  
                
                
                const signingUpUser = localStorage.getItem('signingUpUser');
                const newUser = JSON.parse(signingUpUser);
                console.log("newUser===",newUser)

            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    return (
        
            <BlankLayout>
            <Box className='content-center'>
                <Card sx={{ zIndex: 1 }}>
                    <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
                        <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src="./images/logo.png" alt="..." width="150"/>                           
                        </Box>
                        <Box sx={{ mb: 6 }}>
                            <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                            Please Enter Your Code Here
                            </Typography>
                            <Typography variant='body2'>We have just sent verification code to your email id</Typography>
                        </Box>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <TextField autoFocus fullWidth 
                                id='otp' 
                                label='Enter your code here' 
                                sx={{ marginBottom: 4 }}
                                value={formik.values.otp} 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                error={formik.touched.otp && Boolean(formik.errors.otp)}
                                helperText={formik.touched.otp && formik.errors.otp}
                            />
                            <Button 
                                fullWidth
                                type='submit'
                                size='large'
                                variant='contained'
                                sx={{ marginBottom: 7 }}
                                >
                                Submit
                            </Button>
                        </form>
                    </CardContent>
                </Card>
                <FooterIllustrationsV1 />
            </Box>
        </BlankLayout>
        
        
        
    )
}