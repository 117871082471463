// ** React Imports
import { useState } from 'react'
import {styled, Switch, IconButton, Tooltip, Grid, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom'
import EditQuestion from "../Question/EditQuestion"
import Swal from 'sweetalert2'
import axios from 'axios'
// import Link from '@mui/material/Link'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Question = ({questions, surveyId, competencyId, getList, ...rest}) => {
  // ** States
  const authUser = localStorage.getItem('authUser');
  const authUserId = JSON.parse(authUser).id;
  
  const [showQuestion, setShowQuestion] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState(0);

  const location = useLocation();
  const current_path = location.pathname;

  const handleQuestionEdit = (id) => {
      setEditQuestionId(id);
      setShowQuestion(true);
  }
  const handleQuestionClose = () => setShowQuestion(false);
   
  const handleQuestionStatus = (e, questionId) => {
    var status = '1';
    if(e.target.checked === true) {
        status = '1'
    }
    else {
        status = '0'
    }
    axios.put(`api/questions/updateStatus?id=${questionId}`, {status}).then(res => {                
    })
    .catch(err => {
    })
  }

  const handleSurveyQuestionStatus = (e, surveyId, competencyId, questionId) => {
    var status = '1';
    var url = `api/questions/updateSurveyQuestionStatus?id=${questionId}&surveyId=${surveyId}&competencyId=${competencyId}`
    if(e.target.checked === true) {
        status = '1'
    }
    else {
        status = '0'
    }
    axios.put(url, {status}).then(res => {                
    })
    .catch(err => {
    })
  }

  const handleQuestionDelete = (questionId) => {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) { 
          axios.delete(`/api/questions/delete?id=${questionId}`).then((res) =>{
              if(res.data.success){                             
                Swal.fire('Deleted!', '', 'success').then(result => { 
                    getList();
                })
              } 
              else{
                Swal.fire(res.data.message, '', 'info')
              }
          }).catch((error) => {
              console.log(error);                     
              Swal.fire(error.message, '', 'info')           
          });
      } else if (result.isDenied) {
        Swal.fire('Cancelled!', '', 'info')
      }
    }) 
  }
  

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
      { questions && questions.length > 0 ?
        <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15, flexWrap: 'wrap' }} >
          <Grid sx={{ width: '25%' }}>
            <Typography className='fw-semibold'>Question Type</Typography>
          </Grid>
          <Grid sx={{ width: '35%' }}>
            <Typography className='fw-semibold'>Question</Typography>
          </Grid>
          <Grid sx={{ width: '30%' }}></Grid>
        </li>
        :''}
        { questions && questions.length > 0 ?    
        
            questions.map((question) => (
                    
              <li key={question.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15, flexWrap: 'wrap' }} >
                <Grid style={{ width: '25%' }}>
                  {question.question_type && question.question_type.name ? question.question_type.name : question.question.question_type.name}
                </Grid>
                <Grid style={{ width: '35%' }}>{question.name ? question.name : question.question.name}</Grid>                
                <Grid style={{ width: '40%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap'}}>
               
                {current_path === '/surveys' && authUserId === question.userId  ? 
                  <IOSSwitch size='small' onChange={(e) => handleQuestionStatus(e, question.id)} defaultChecked={question.status === '1' ? true : false}/> : ''
                }
                {current_path === '/surveys' ? 
                    <IOSSwitch size='small' onChange={(e) => handleSurveyQuestionStatus(e, surveyId, competencyId, question.question.id)} defaultChecked={question.status === '1' ? true : false}/> :""
                }

                {current_path === '/competencies'  ? 
                  <>
                    <IOSSwitch size='small' onChange={(e) => handleQuestionStatus(e, question.id)} defaultChecked={question.status === '1' ? true : false}/> 
                    
                    { authUserId === question.userId ? 
                      <>
                        <Tooltip onClick={() => handleQuestionEdit(question.id)} title="Edit Question" size='small' className='ss_edit' sx={{ marginLeft: 2, marginRight: 2 }}>
                          <IconButton size='small'><EditIcon /></IconButton>
                        </Tooltip>

                        <Tooltip onClick={() => handleQuestionDelete(question.id)} title="Delete Question" size='small' className='ss_delete' sx={{ marginRight: 2 }}> 
                          <IconButton size='small'><DeleteIcon /></IconButton> 
                        </Tooltip>
                      </> 
                    : "" }
                  </>
                : '' }
                </Grid>
              </li>
            ))
        : '' 
        }
      </Grid>
    </Grid>

    <EditQuestion showQuestion={showQuestion} setShowQuestion={setShowQuestion} handleQuestionClose={handleQuestionClose} getList={getList} editQuestionId={editQuestionId} setEditQuestionId={setEditQuestionId} />                    
    </>
    
  )
}

export default Question
