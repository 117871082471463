import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';

function PaymentSuccess() {
    const { paymentIntentId } = useParams();

    // useEffect(() => {
    //     console.log("paymentIntentId==",paymentIntentId)
    //     createSubscription()
    //   }, [paymentIntentId])

    //   const createSubscription = async() => {
    //     console.log("paymentIntentIddddddddddddddddddddddddddd ",paymentIntentId)
    //     await axios.post(`/api/plans/createSubscription`,{paymentIntentId:paymentIntentId})
    //     .then(function (response) {
    //         // let result = response.data;
    //         console.log("res response===",response)
    //     })
    //   }

  return (
    <div>Success</div>
  )
}

export default PaymentSuccess;