import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";

import { Grid, TextField, Button, MenuItem, Radio, RadioGroup, FormControlLabel, FormControl,} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    surveyId: yup.string().required("Survey is required"),
    type: yup.string().required("Type is required"),
  });
export const StartSession = ({ show, handleClose, getSessions, ...rest }) => {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const [surveys, setSurveys] = useState([]);
    const [surveySettings, setSurveySettings] = useState([]);

    useEffect(() => {
        getList();
        getSurveySettingList();
    }, []);

    const getList = async () => {
        await axios.get("/api/surveys").then(function (response) {
            let result = response.data;
            if (result.success) {
                setSurveys(result.data);
            } else {
                // setIsError(true);
            }
        });
    };
    const getSurveySettingList = async () => {
        await axios.get(`/api/surveyratingsettings?createdBy=${user.id}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                let idFound = 0;
                for (const item of result.data) {
                    if (item.createdBy !== user.id) {
                      idFound = item.id;
                      break;
                    }
                }
                formik.values.surveyRatingSettingId = idFound
                setSurveySettings(result.data);
            } else {
                // setIsError(true);
            }
        });
    };

    const initialValues = {
        name: '',
        surveyId: '',
        type: '',
        surveyRatingSettingId:''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        axios.post(`api/sessions/start`, values).then(res => {
            if(res.data.success){
                Swal.fire(res.data.message, '', 'success').then(result => {
                    getSessions();
                    handleClose();
                })                
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')
        });
    };
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md">

            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">Start New Session</h5>
                    <CancelIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <TextField autoFocus fullWidth 
                                size='small'
                                id='name'
                                name="name"
                                label='Enter name' 
                                value={formik.values.name} 
                                onChange={formik.handleChange} 
                                ListboxProps={{
                                    sx: { fontSize: 13,
                                        fontFamily: 'Poppins' },
                                  }}
                                  sx={{
                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                        fontSize: 13,
                                        fontFamily: 'Poppins', // Add your desired font family
                                        fontWeight: '500', // Add your desired font weight
                                    },
                                  }}                                  
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                              }}
                              sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                              }}
                            variant={"outlined"}
                            style={{width: "100%"}}
                            fullWidth
                            select 
                            label='Select Survey' 
                            name='surveyId' 
                            size='small'
                            value={formik.values.surveyId}
                            onChange={formik.handleChange}
                            error={formik.touched.surveyId && Boolean(formik.errors.surveyId)}
                            helperText={formik.touched.surveyId && formik.errors.surveyId}
                            >
                                <MenuItem value="" disabled> Please Select </MenuItem>
                                {surveys.length > 0 ? (
                                    surveys.map((survey) => (
                                        <MenuItem key={survey.id} value={survey.id}>
                                        {survey.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        No surveys available
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup 
                                row 
                                aria-labelledby="demo-row-radio-buttons-group-label" 
                                name="type"                                
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                error={formik.touched.type && Boolean(formik.errors.type)}
                                >
                                    <FormControlLabel value="180" control={<Radio />} label={<span style={{ fontSize: '14px', fontWeight: 600 }}>180 Assessment</span>} />
                                    <FormControlLabel value="360" control={<Radio />} label={<span style={{ fontSize: '14px', fontWeight: 600 }}>360 Assessment</span>} />
                                </RadioGroup>
                                {formik.touched.type && formik.errors.type && (
                                <div style={{ color: "red" }}>{formik.errors.type}</div>
                                )}
                            </FormControl>
                            {formik.values.type === '180' && (
                                <div className="notes">
                                {/* Content to show when 180 Assessment is selected */}
                                <p className="fs-12 mb-0 ms-2">
                                    <span className="fw-bold me-1">Note:</span>
                                    Involves self-assessment and feedback from Supervisors/Managers.</p>
                                </div>
                            )}
                            {formik.values.type === '360' && (
                                <div className="notes">
                                {/* Content to show when 360 Assessment is selected */}
                                <p className="fs-12 mb-0 ms-2">
                                <span className="fw-bold me-1">Note:</span>
                                Gathers feedback from multiple stakeholders (e.g., supervisors, peers, direct reportees) for a comprehensive view.</p>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                              }}
                              sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                              }}
                            variant={"outlined"}
                            style={{width: "100%"}}
                            fullWidth
                            select 
                            label='Select Survey Setting' 
                            name='surveyRatingSettingId' 
                            size='small'
                            value={formik.values.surveyRatingSettingId}
                            onChange={formik.handleChange}
                            error={formik.touched.surveyRatingSettingId && Boolean(formik.errors.surveyRatingSettingId)}
                            helperText={formik.touched.surveyRatingSettingId && formik.errors.surveyRatingSettingId}
                            >
                                <MenuItem value="" disabled> Please Select </MenuItem>
                                {surveySettings.length > 0 ? (
                                    surveySettings.map((setting) => (
                                        <MenuItem key={setting.id} value={setting.id}>{setting.name}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        No setting available
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button size='small' type="reset" sx={{ marginRight: 3.5 }} variant="outlined" onClick={handleClose}> Close </Button>
                    <Button size='small' type="submit" variant="contained"> Start </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default StartSession;
StartSession.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
