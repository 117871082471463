import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";

export default function Pricing() {
    return (
        <>
            <Header/>
            <section className="py-5">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-9 mx-auto text-center">
                            <p className="h6 text-theme text-uppercase">Flexible Pricing Plans</p>
                            <h2 className="mb-3">Find the Plan That Fits Your Needs</h2>
                            <p className="text-muted fs-14 mb-3">Choose from our flexible pricing plans and get started with PeopleAssay today. Whether you're a small team or a large enterprise, we've got a plan that's right for you.</p>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center mb-5">
                        <div class="col-md-3 col-sm-6">
                            <div class="pricingTable">
                                <div class="pricingTable-header">
                                    <i class="fa fa-adjust"></i>
                                    <div class="price-value fw-bold"> $10.00 <span class="month fw-500">per month</span> </div>
                                </div>
                                <h3 class="heading my-3">BASIC</h3>
                                <div class="pricing-content">
                                    <ul>
                                        <li>Upto 50 Users / Monthly Package</li>
                                    </ul>
                                </div>
                                <div class="text-center">
                                    <Link href="#" className="btn btn-theme fw-semibold">Subscribe</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="pricingTable">
                                <div class="pricingTable-header">
                                    <i class="fa fa-adjust"></i>
                                    <div class="price-value fw-bold"> $25.00 <span class="month fw-500">per month</span> </div>
                                </div>
                                <h3 class="heading my-3">Standard</h3>
                                <div class="pricing-content">
                                    <ul>
                                        <li>Upto 100 Users / Monthly Package</li>
                                    </ul>
                                </div>
                                <div class="text-center">
                                    <Link href="#" className="btn btn-theme fw-semibold">Subscribe</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <div class="pricingTable">
                                <div class="pricingTable-header">
                                    <i class="fa fa-adjust"></i>
                                    <div class="price-value fw-bold"> $50.00 <span class="month fw-500">per month</span> </div>
                                </div>
                                <h3 class="heading my-3">PREMIUM</h3>
                                <div class="pricing-content">
                                    <ul>
                                        <li>Upto 250 Users / Monthly Package</li>
                                    </ul>
                                </div>
                                <div class="text-center">
                                    <Link href="#" className="btn btn-theme fw-semibold">Subscribe</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-theme map-bg py-5">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-lg-9 mx-auto text-white">
                                <p className="h6 text-uppercase">Start Your Journey with PeopleAssay</p>
                                <h2 className="mb-3">Experience the Power of Feedback</h2>
                                <p className="fs-14">Ready to see what PeopleAssay can do for you? Sign up for a free trial or schedule a personalized demo and experience the power of feedback firsthand.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 mx-auto">
                                <Link href="#" className="btn btn-secondary reg_btn w-100 py-3">Get Started!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>            
        </>
        
    )     
}