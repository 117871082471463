import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import Add from './Add'
import { Button } from '@mui/material'

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [compaines, setCompaines] = useState([]);
    
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);

    useEffect(() => {
        if(user.role !== 'SuperAdmin'){
            navigate('/dashboard')
        }
        getList();
    }, [])

    const getList = async() => {
        await axios.get(`/api/users?role=Admin&parentId=2`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setCompaines(result.data)
                console.log('data', result.data);
            }
        })
    }

    const handleUserLogin = (email) => {
        // Send a POST request to your API endpoint with the email as data
        axios.post('/api/auth/another/login', { email: email, isSuperAdmin: user.isSuperAdmin })
          .then((res) => {
            if (res.data.success) {
                console.log("res.data.dataanother/login compnay", res.data.data)
                // res.data.data.isSuperAdmin = '0';
                // res.data.data.isAdmin = '0';
                // if( res.data.data.role === 'Admin'){
                //     res.data.data.isSuperAdmin = '1';
                // }
              localStorage.clear();
              localStorage.setItem('authUser', JSON.stringify(res.data.data));
              navigate('/dashboard');
            } else {
              Swal.fire(res.data.message, '', 'info');
            }
          })
          .catch((error) => {
            Swal.fire(error.message, '', 'info');
          });
      };

    const handleDelete = (id) => {
        Swal.fire({
            // title: 'Are you sure?',
            // text: 'This action will permanently erase all data associated with this company, and recovery will be impossible.',
            text:'This action will permanently erase all data associated with this company, are you sure you want to continue?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/users/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success').then(result => { 
                            getList();
                        })
                    }
                    else{
                        Swal.fire(res.data.message, '', 'info')
                    }
                    
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }
    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }
    const handleClose = () => setShow(false);

    const handleStatus = (e, id) => {       
        var status = '1';
        if(e.target.checked === true) {
            status = '1'
        }
        else {
            status = '0'
        }
        axios.put(`api/users/updateStatus?id=${id}`, {status}).then(res => {
            if(!res.data.success){
                Swal.fire(res.data.message, '', 'info').then(result => { 
                    getList();
                })
            }              
        })
        .catch(err => {
            Swal.fire(err.message, '', 'info')
        })
    }

    const columns = [
        { id: 'companyName', label: 'Company Name', minWidth: 120 },
        { id: 'name', label: 'Contact Person', minWidth: 120 },
        { id: 'email', label: 'Email', minWidth: 150 },
        // { id: 'phone', label: 'Phone Number'},
        { id: 'user_plan', label: 'Plan'},
        { id: 'status', label: 'Status'},
        { id: 'action', label: 'Action'},
        { id: 'login', label: 'Login'}
    ]

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-4 col-6 mb-3'>
                    <h1 className=''>Companies</h1>
                </div>
                <div className='col-md-8 col-12 text-md-end mb-3'>
                    <Button size='small' variant='contained' onClick={ (handleAdd) }>Add Comapny</Button>
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        <TableStickyHeader columns={columns} rows={compaines} handleUserLogin ={handleUserLogin} handleEdit={handleEdit} handleDelete={handleDelete} handleStatus={handleStatus}/>
                    </Card>
                </Grid>           
            </Grid>
            <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/>
        </UserLayout>
    )
}