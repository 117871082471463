import React, { useEffect, useState }  from 'react';
import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import Button from '@mui/material/Button'
import Add from './Add'
import Swal from 'sweetalert2'
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const role = JSON.parse(authUser).role;
    const [show, setShow] = useState(false);
    const [popupType, setPopupType] = useState('Add');
    const [editId, setEditId] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getList();
    }, [selectedUser]);
    useEffect(() => {
        getUsersList();
    }, []);

    const getList = async() => {
        let apiUrl = "/api/questions";

        if(role === 'SuperAdmin'){
            apiUrl = `/api/questions?userId=${selectedUser}`;
        }
        await axios.get(apiUrl)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setQuestions(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }

    const getUsersList = async () => {
        await axios.get(`/api/users?role=Admin&parentId=2`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setUsers(result.data);
                // console.log(result.data);
            } else {
                // setIsError(true);
            }
        });
    };
    
    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    const handleClose = () => setShow(false);

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/questions/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        getList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleStatus = (e, id) => {       
        var status = '1';
        if(e.target.checked === true) {
            status = '1'
        }
        else {
            status = '0'
        }
        axios.put(`api/questions/updateStatus?id=${id}`, {status}).then(res => {                
        })
        .catch(err => {
        })
    }

    const handleSelectChange = (e) => {
        const selectedUserId = e.target.value;
        // const selectedUser1 = users.find(user => user.id === selectedUserId);
        setSelectedUser(selectedUserId);
        console.log("selectedUser", selectedUserId);
    };

    const columns = [];
    columns.push({ id: 'name', label: 'Name', minWidth: 150 });
    columns.push({ id: 'competency', label: 'Competency', minWidth: 150 });
    columns.push({ id: 'survey', label: 'Survey', minWidth: 150 });
    columns.push({ id: 'question_type', label: 'Question Type' });
    if(role === 'SuperAdmin'){
        columns.push({ id: 'user', label: 'Company Name' });
    }
    columns.push({ id: 'status', label: 'Status' });
    columns.push({ id: 'action', label: 'Action'});

    return (
        <UserLayout>
            <div className="row">
                <div className='col-md-4'>
                    <h1 className='mb-3'>Questions</h1>
                </div>
                <div className='col-md-4'>
                {role === "SuperAdmin" && (
                        <TextField fullWidth select
                            label='Select Company'
                            id='ComapanyId'
                            value={selectedUser ? selectedUser.id : ""}
                            onChange={handleSelectChange}
                            >
                            <MenuItem selected hidden>Please Select</MenuItem>
                            <MenuItem value="2">Super Admin</MenuItem>
                            {
                                users.length > 0 ? 
                                users.map(user => (
                                    <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                                ))
                                : ''
                            }
                        </TextField>
                        )}
                </div>
                <div className='col-md-4 text-end'>
                    <Button variant='contained' sx={{ marginRight: 3.5 }} onClick={()=>handleAdd()}>
                        Add Question
                    </Button>
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        <TableStickyHeader columns={columns} rows={questions} setEditId={setEditId} setPopupType={setPopupType} setShow={setShow} handleEdit={handleEdit} handleDelete={handleDelete} handleStatus={handleStatus}/>
                    </Card>
                </Grid>           
            </Grid>
            {/* <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/> */}
        </UserLayout>
    )
}