import { Autocomplete, TextField, MenuItem } from "@mui/material";

// Ensure that the "users" array is available in the component scope.

const SearchableSelect = ({ users, formik }) => {
    return (
        <Autocomplete
            id="managerId"
            options={users}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => formik.setFieldValue('managerId', value ? value.id : "")}
            value={users.find(user => user.id === formik.values.managerId) || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    label="Select Manager"
                    variant="outlined"
                    size="small"
                    error={formik.touched.managerId && Boolean(formik.errors.managerId)}
                    helperText={formik.touched.managerId && formik.errors.managerId}
                    sx={{
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            fontSize: 13,
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        listboxprops: {
                            sx: {
                                fontSize: 13,
                                fontFamily: 'Poppins',
                            },
                        },
                    }}
                />
            )}
        />
    );
};

export default SearchableSelect;
