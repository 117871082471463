import React, { useEffect, useState }  from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Link } from 'react-router-dom';


const validationSchema = yup.object({
    name: yup.string('Enter your name').required('Name is required'),
    companyName: yup.string('Enter your company name').required('Company Name is required'),
    planId: yup.string('Select Plan').required('Plan is required'),
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    // phone: yup.number()
    // .min(10, "Must be more than 10 characters")
});

export const Add = ({type, show, setShow, handleClose, getList, editId, setEditId, ...rest}) => {
    const [plans, setPlans] = useState([]);
    const initialValues = {
        id: editId,
        name: '',
        companyName: '',
        planId: '',
        email: '',
        // phone: '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        formik.resetForm();
        getPlans();
        if(editId > 0){
            axios.get(`api/user?id=${editId}`).then(res => {
                // console.log("res--------------",res.data.data);
                if(res.data.success){
                    formik.setFieldValue('id', res.data.data.id)
                    formik.setFieldValue('name', res.data.data.name)
                    formik.setFieldValue('companyName', res.data.data.companyName)
                    formik.setFieldValue('email', res.data.data.email)
                    formik.setFieldValue('planId', res.data.data.user_plan.plan.id)
                    // formik.setFieldValue('phone', res.data.data.phone)
                }
            })
        }
        else{
            formik.resetForm();
        }
        
    },[editId]);

    const getPlans = async() => {
        await axios.get('/api/plans?isFree=0')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setPlans(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }
    
    const handleSubmit = (values) =>{
        if(editId > 0){
            axios.put(`api/users/updateCompany`, values).then(res => {
                if(res.data.success){
                    setShow(false);                    
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        getList();     
                    })
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }
        else{
            axios.post(`api/users/createCompany`, values).then(res => {
                if(res.data.success){
                    setShow(false);
                    Swal.fire(res.data.message, '', 'success').then(result => {
                        formik.resetForm();
                        getList();
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });
        }        
    }
    
    return (

        <Dialog open={show} onClose={handleClose}>
            <DialogTitle className='d-flex align-items-center justify-content-between mb-0 modal_header_p lg'>
                <h5 className="modal-title fs-14 mb-0">{type} Company</h5>
                <CancelIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
            </DialogTitle>
            <hr className='ss_hr'></hr>
            
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <DialogContent>
                        <Grid container spacing={6}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth
                                    listboxprops={{
                                        sx: { fontSize: 13,
                                            fontFamily: 'Poppins' },
                                    }}
                                    sx={{
                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                            fontSize: 13,
                                            fontFamily: 'Poppins', // Add your desired font family
                                            fontWeight: '500', // Add your desired font weight
                                        },
                                    }}
                                    size='small'
                                    id='companyName'
                                    label='Company Name' 
                                    placeholder='Company Name' 
                                    value={formik.values.companyName} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                    helperText={formik.touched.companyName && formik.errors.companyName}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField fullWidth
                                    listboxprops={{
                                        sx: { fontSize: 13,
                                            fontFamily: 'Poppins' },
                                    }}
                                    sx={{
                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                            fontSize: 13,
                                            fontFamily: 'Poppins', // Add your desired font family
                                            fontWeight: '500', // Add your desired font weight
                                        },
                                    }}
                                    size='small'
                                    id='name'
                                    label='Name' 
                                    placeholder='Name' 
                                    value={formik.values.name} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextField fullWidth
                                    listboxprops={{
                                        sx: { fontSize: 13,
                                            fontFamily: 'Poppins' },
                                    }}
                                    sx={{
                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                            fontSize: 13,
                                            fontFamily: 'Poppins', // Add your desired font family
                                            fontWeight: '500', // Add your desired font weight
                                        },
                                    }}
                                    disabled={editId <= 0 ? false:true}
                                    size='small'
                                    id='email'
                                    label='Email' 
                                    placeholder='example@example.com' 
                                    value={formik.values.email} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                                <TextField fullWidth
                                    listboxprops={{
                                        sx: { fontSize: 13,
                                            fontFamily: 'Poppins' },
                                    }}
                                    sx={{
                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                            fontSize: 13,
                                            fontFamily: 'Poppins', // Add your desired font family
                                            fontWeight: '500', // Add your desired font weight
                                        },
                                    }}
                                    size='small'
                                    type='number'
                                    id='phone'
                                    label='Phone Number'
                                    value={formik.values.phone} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </Grid> */}
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth select
                                    listboxprops={{
                                        sx: { fontSize: 13,
                                            fontFamily: 'Poppins' },
                                    }}
                                    sx={{
                                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                            fontSize: 13,
                                            fontFamily: 'Poppins', // Add your desired font family
                                            fontWeight: '500', // Add your desired font weight
                                        },
                                    }}
                                    size='small'
                                    label='Select Plan'
                                    id='planId'
                                    value={formik.values.planId}
                                    onChange={(e) => formik.setFieldValue('planId', e.target.value)}
                                    error={formik.touched.planId && Boolean(formik.errors.planId)}
                                    helperText={formik.touched.planId && formik.errors.planId}
                                >
                                    <MenuItem value="">Please Select</MenuItem>
                                    {
                                        plans.length > 0 ? 
                                        plans.map(plan => (
                                            <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
                                        ))
                                        : ''
                                    }                         
                                </TextField>
                            </Grid>
                        </Grid>
                </DialogContent>
                <hr className='ss_hr'></hr>
                <DialogActions className='modal_footer_p'>
                    <Button size='small' type='reset' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleClose}>
                        Close
                    </Button>
                    <Button size='small' type="submit" variant='contained'>
                        {type == 'Add' ? 'Add' : 'Update'}
                    </Button>              
                </DialogActions>
            </form>
        </Dialog>
        // <Modal show={show} onHide={handleClose} dialogClassName="">
        //     <form autoComplete="off" onSubmit={formik.handleSubmit}>
        //         <Modal.Header>
        //             <h5 className="modal-title fs-14 mb-0">{type} Company</h5>
        //             <CancelIcon style={{cursor: 'pointer'}} onClick={handleClose}/>
        //         </Modal.Header>
        //         <Modal.Body>
        //             <Grid container spacing={6}>
        //                 <Grid item xs={12} sm={6}>
        //                     <TextField fullWidth
        //                         listboxprops={{
        //                             sx: { fontSize: 13,
        //                                 fontFamily: 'Poppins' },
        //                         }}
        //                         sx={{
        //                             '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
        //                                 fontSize: 13,
        //                                 fontFamily: 'Poppins', // Add your desired font family
        //                                 fontWeight: '500', // Add your desired font weight
        //                             },
        //                         }}
        //                         size='small'
        //                         id='companyName'
        //                         label='Company Name' 
        //                         placeholder='Company Name' 
        //                         value={formik.values.companyName} 
        //                         onChange={formik.handleChange}
        //                         onBlur={formik.handleBlur}
        //                         error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        //                         helperText={formik.touched.companyName && formik.errors.companyName}
        //                     />
        //                 </Grid>
        //                 <Grid item xs={6} sm={6}>
        //                     <TextField fullWidth
        //                         listboxprops={{
        //                             sx: { fontSize: 13,
        //                                 fontFamily: 'Poppins' },
        //                         }}
        //                         sx={{
        //                             '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
        //                                 fontSize: 13,
        //                                 fontFamily: 'Poppins', // Add your desired font family
        //                                 fontWeight: '500', // Add your desired font weight
        //                             },
        //                         }}
        //                         size='small'
        //                         id='name'
        //                         label='Name' 
        //                         placeholder='Name' 
        //                         value={formik.values.name} 
        //                         onChange={formik.handleChange}
        //                         onBlur={formik.handleBlur}
        //                         error={formik.touched.name && Boolean(formik.errors.name)}
        //                         helperText={formik.touched.name && formik.errors.name}
        //                     />
        //                 </Grid>
        //                 <Grid item xs={6} sm={6}>
        //                     <TextField fullWidth
        //                         listboxprops={{
        //                             sx: { fontSize: 13,
        //                                 fontFamily: 'Poppins' },
        //                         }}
        //                         sx={{
        //                             '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
        //                                 fontSize: 13,
        //                                 fontFamily: 'Poppins', // Add your desired font family
        //                                 fontWeight: '500', // Add your desired font weight
        //                             },
        //                         }}
        //                         size='small'
        //                         id='email'
        //                         label='Email' 
        //                         placeholder='example@example.com' 
        //                         value={formik.values.email} 
        //                         onChange={formik.handleChange}
        //                         onBlur={formik.handleBlur}
        //                         error={formik.touched.email && Boolean(formik.errors.email)}
        //                         helperText={formik.touched.email && formik.errors.email}
        //                     />
        //                 </Grid>
        //                 <Grid item xs={12} sm={6}>
        //                     <TextField fullWidth
        //                         listboxprops={{
        //                             sx: { fontSize: 13,
        //                                 fontFamily: 'Poppins' },
        //                         }}
        //                         sx={{
        //                             '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
        //                                 fontSize: 13,
        //                                 fontFamily: 'Poppins', // Add your desired font family
        //                                 fontWeight: '500', // Add your desired font weight
        //                             },
        //                         }}
        //                         size='small'
        //                         type='number'
        //                         id='phone'
        //                         label='Phone Number'
        //                         value={formik.values.phone} 
        //                         onChange={formik.handleChange}
        //                         onBlur={formik.handleBlur}
        //                         error={formik.touched.phone && Boolean(formik.errors.phone)}
        //                         helperText={formik.touched.phone && formik.errors.phone}
        //                     />
        //                 </Grid>
                        
        //             </Grid>
        //         </Modal.Body>
        //         <Modal.Footer>
        //             <Button size='small' type='reset' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleClose}>
        //                 Close
        //             </Button>
        //             <Button size='small' type="submit" variant='contained'>
        //                 {type == 'Add' ? 'Add' : 'Update'}
        //             </Button>              
        //         </Modal.Footer>
        //     </form>
        // </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};