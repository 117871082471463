export const Footer = () => {
    return (
        <footer className="map-bg bg-light">
     <div className="container py-3 border-bottom z-index-20 position-relative">
         <div className="row pt-5 gy-4">
             <div className="col-lg-3 col-md-6"><img className="mb-3" src="./images/logo.png" alt="" width="150"/>
                 <ul className="list-unstyled text-muted">
                     <li><a className="reset-anchor fs-13 fw-500" href="mailto:info@peopleassay.com">info@peopleassay.com</a></li>
                     <li><a className="reset-anchor fs-13 fw-500" href="tel:+001234567890">+ 00 123 456 7890</a></li>
                 </ul>
                 <ul className="list-inline text-muted">
                     <li className="list-inline-item"><a className="reset-anchor" href="#!"><i className="fab fa-facebook-f"></i></a></li>
                     <li className="list-inline-item"><a className="reset-anchor" href="#!"><i className="fab fa-twitter"></i></a></li>
                     <li className="list-inline-item"><a className="reset-anchor" href="#!"><i className="fab fa-linkedin-in"></i></a></li>
                 </ul>
             </div>
             <div className="col-lg-3 col-md-6">
                 <h4>About us</h4>
                 <ul className="list-unstyled text-muted mb-0">
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">Support center</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">Customer support</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">About us</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">copyright</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">popular campaign</a></li>
                 </ul>
             </div>
             <div className="col-lg-3 col-md-6">
                 <h4>Our information</h4>
                 <ul className="list-unstyled text-muted mb-0">
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">Support center</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">Customer support</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">About us</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">copyright</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">popular campaign</a></li>
                 </ul>
             </div>
             <div className="col-lg-3 col-md-6 mb-4">
                 <h4>Policy</h4>
                 <ul className="list-unstyled text-muted mb-0">
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">Support center</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">Customer support</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">About us</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">copyright</a></li>
                     <li className="mb-1"><a className="fs-13 fw-500 reset-anchor" href="#!">popular campaign</a></li>
                 </ul>
             </div>
         </div>
     </div>
     <div className="container py-4 z-index-20 position-relative">
         <div className="row text-center">
             <div className="col-lg-6 p-lg-0 text-lg-start">
                 <p className="text-muted fs-13 fw-500 mb-0">© 2023 People Assay, All Rights Reserved.</p>
             </div>
             <div className="col-lg-6 p-lg-0 text-lg-end">
                 <p className="text-muted fs-13 fw-500 mb-0">designed by <a className="text-theme reset-anchor" href="https://actiknow.com/" target="_blank">Actiknow Consulting Pvt. Ltd.</a>
                 </p>
             </div>
         </div>
     </div>
 </footer>
    )
}

export default Footer;