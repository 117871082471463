import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Button from '@mui/material/Button'
import Add from './Add'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Swal from 'sweetalert2'

export default function List() {
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState(0);
    const [popupType, setPopupType] = useState('Add');

    useEffect(() => {
        if(user.role !== 'SuperAdmin'){
            navigate('/dashboard')
        }
        getList();
    }, [])

    const getList = async() => {
        await axios.get('/api/plans')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setPlans(result.data)
            }
        })
    }

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    // const handleDelete = (id) => {
    //     Swal.fire({
    //         title: 'Are you sure you want to delete?',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!'
    //       }).then((result) => {
    //         if (result.isConfirmed) { 
    //             axios.delete(`/api/plans/delete?id=${id}`).then((res) =>{
    //                 if(res.data.success){                             
    //                     Swal.fire('Deleted!', '', 'success').then(result => { 
    //                         getList();
    //                     })
    //                 } 
                    
    //             }).catch((error) => {
    //                 console.log(error);                     
    //                 Swal.fire(error.message, '', 'info')           
    //             });
    //         } else if (result.isDenied) {
    //           Swal.fire('Cancelled!', '', 'info')
    //         }
    //     }) 
    // }

    const handleClose = () => setShow(false);

    const columns = [
        { id: 'name', label: 'Name', minWidth: 150 },
        { id: 'noOfUsers', label: 'No of Users', minWidth: 130 },
        { id: 'price', label: 'Price ($)'},
        { id: 'description', label: 'description', minWidth: 250 },
        { id: 'status', label: 'Status'},
        { id: 'action', label: 'Action'}
    ]
    const handleSyncPlans = async () => {
        await axios.get('/api/syncPlans')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                Swal.fire('Synced!', '', 'success').then(res => { 
                    // setPlans(result.data)
                    getList();
                })
            }
        })
    }
    return (
        <UserLayout>
            {/* <h1 className='mb-3'></h1> */}
            <div className="row">
                <div className='col-md-8 col-6 mb-3'>
                    <h1 className=''>Subscription Plans</h1>
                </div>                
                <div className='col-md-4 col-6 text-end mb-3'>
                    <Button size='small' variant='contained' onClick={()=>handleSyncPlans()}>
                        Sync Plans
                    </Button>
                    {/* <Button size='small' variant='contained' sx={{ marginRight: 3.5 }} onClick={()=>handleAdd()}>
                        Add Plan
                    </Button> */}
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        {/* <TableStickyHeader columns={columns} rows={plans} handleEdit={handleEdit} handleDelete={handleDelete}/> */}
                        <TableStickyHeader columns={columns} rows={plans} handleEdit={handleEdit}/>
                    </Card>
                </Grid>           
            </Grid>
            <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList} editId={editId} setEditId={setEditId}/>
        </UserLayout>
    )
}