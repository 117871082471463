import React, {  useCallback, useEffect, useState } from "react";
import UserLayout from '../Layout/UserLayout'
import Card from "@mui/material/Card";
import { useParams } from 'react-router-dom';
import axios from "axios";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import TableStickyHeader from '../Common/TableStickyHeader'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const Reminder = (req, res) => {        
    let { sessionId } = useParams();
    const [surveyEvalutionUsers, setSurveyEvalutionUsers] = useState([]);  
    const [sessionName, setSessionName] = useState('');    
    const [users, setUsers] = useState([]);
    const [reviewers, setReviewers] = useState([]);
    const [userFilter, setUserFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [reviewerFilter, setReviewerFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [reviewer, setReviewer] = useState(null);
    const [user, setUser] = useState(null);
    const [status, setStatus] = useState(null);
    

    const handleReminder = async (e,userId,managerId,userName) => {
        const selectedValue = e.target.value;
        const dataToSubmit = [];
        dataToSubmit.push({
            userId: userId,
            managerId: managerId,
            userName: userName,
            type:selectedValue
        });
        // console.log('dataToSubmit',dataToSubmit);
        // return false;
        try {
            const response = await axios.post("/api/userselfevaluation/reminder", { reminder: dataToSubmit });
            Swal.fire(response.data.message, "", "success").then((result) => {

             });
        } catch (error) {
            console.error(error);
        }
    }

    const getList = async(query='') => {
        await axios.get(`/api/usersurveyevaluations?sessionId=${sessionId}${query}`)
        .then(function (response) {
            setIsLoading(false)
            let result = response.data;
            if(result.success){
                setSurveyEvalutionUsers(result.data)
                setSessionName(result.data[0] ? result.data[0].session.name : '')
                console.log('data', result.data);
            }
        })
    }

    const getUsers = async() => {
        // await axios.get('/api/users?isAdminUsers=1&toFilter=1')
        await axios.get('/api/userselfevaluation/getUsersForEvaluation?sessionId='+sessionId)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }
    const getReviewers = async() => {
        await axios.get(`/api/users/getReviewers?sessionId=${sessionId}`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                const userNamesArray = result.data.map(item => item.manager_name);
                setReviewers(userNamesArray)
            }
            else {
                // setIsError(true);
            }
        })
    }
    const sessionStatus = [
        { label: 'Completed', id: 1 },
        { label: 'Pending', id: 0 }
    ];

    useEffect(() => {
        getList();
        getUsers();
        getReviewers();
    }, []);

    const columns = [
        { id: 'user_name', label: 'User Name', minWidth: 120 },
        // { id: 'createdAt', label: 'start Date', minWidth: 150 },
        // { id: 'selfEndedAt', label: 'End Date'},
        { id: 'manager_name', label: 'Reviewer Name'},
        { id: 'createdAt', label: 'start Date'},
        { id: 'updatedAt', label: 'End Date'},
        { id: 'status', label: 'Status'},
        { id: 'reminder', label: 'Send Reminder'}
    ]
    const handleUserChange = (event, value) => {
        const currentFilter = value?value.id:''
        setIsLoading(true)
        setUser(value ? value.label : null)
        setUserFilter(currentFilter)
        getList(`&userId=${currentFilter}&managerId=${reviewerFilter}&status=${statusFilter}`)
    }

    const handleReviewerChange = (event, value) => {
        const currentFilter = value?value.id:''
        setIsLoading(true)
        setReviewer(value ? value.label : null)
        setReviewerFilter(currentFilter)
        getList(`&managerId=${currentFilter}&userId=${userFilter}&status=${statusFilter}`)
    }

    const handleStatusChange = (event, value) => {  
        const currentFilter = value?value.id:''
        setIsLoading(true)
        setStatus(value ? value.label : null)
        setStatusFilter(currentFilter)        
        getList(`&status=${currentFilter}&userId=${userFilter}&managerId=${reviewerFilter}`)
    }
  
    return (
        <UserLayout>
            
                <>
                    <div className="row d-flex align-items-center">
                        <div className='col-md-6 mb-3'>            
                            <h1 className='mb-3'>Reminder for {sessionName}</h1>
                        </div>
                        <div className='col-md-2 mb-3'>
                        <Autocomplete
                            fullWidth
                            disablePortal
                            id="Users-box"
                            options={users}
                            value={user}
                            size='small'
                            onChange={handleUserChange}
                            ListboxProps={{ sx: { fontSize: 13, fontFamily: 'Poppins'}, }}
                            sx={{ '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            renderInput={(params) => <TextField {...params} label="Users" />}
                        />
                        </div>
                        <div className='col-md-2 mb-3'>
                        <Autocomplete
                            fullWidth
                            disablePortal
                            id="Reviewers-box"
                            options={reviewers}
                            value={reviewer}
                            size='small'
                            onChange={handleReviewerChange}
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            renderInput={(params) => <TextField {...params} label="Reviewers"/>}
                        />
                        </div>
                        <div className='col-md-2 mb-3'>
                        <Autocomplete
                            fullWidth
                            disablePortal
                            id="Status-box"
                            options={sessionStatus}
                            value={status}
                            size='small'
                            onChange={handleStatusChange}
                            ListboxProps={{
                                sx: { fontSize: 13,
                                    fontFamily: 'Poppins' },
                            }}
                            sx={{
                                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                    fontSize: 13,
                                    fontFamily: 'Poppins', // Add your desired font family
                                    fontWeight: '500', // Add your desired font weight
                                },
                            }}
                            renderInput={(params) => <TextField {...params} label="Status" />}
                        />
                        </div>
                    </div>
                    <Grid container spacing={6}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={6}>  
                                    <Grid item xs={12}>
                                        <Card>
                                            <TableStickyHeader columns={columns} rows={surveyEvalutionUsers} handleReminder={handleReminder} isLoading={isLoading}/>
                                        </Card>
                                    </Grid>      
                                </Grid>
                            </Grid>
                    </Grid>
                </>
            
        </UserLayout>
    );
};

export default Reminder;
