import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardNavigationCenter from './components/CardNavigationCenter'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'

export default function GuestSurvey() {
    const {surveyData} = useParams();  
    const [surveyDatails, setSurveyDatails] = useState({}); 

    useEffect(() => {
        getList();
    }, [surveyData]);

    const getList = async() => {
        console.log("surveyData==",surveyData)
        await axios.get(`/api/guests/getGuestSurveyDetails?surveyData=${surveyData}`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setSurveyDatails(result.data)
                console.log("surveyDatails==",surveyDatails)
            }
            else {
                // setIsError(true);
            }
        })
    }

    return (
        <UserLayout>            
            <Grid container spacing={6}>            
                <Grid item xs={12} md={5}>
                    <h1 className='mb-3'>Survey</h1>
                </Grid> 
            </Grid>
            {/* {surveyDatails.surveyId ?  */}
                <Grid container spacing={6}>            
                    <Grid item xs={12} md={12}>
                        <CardNavigationCenter currentSessionId={0} currentSurveyUserId={0} surveyId={parseInt(surveyDatails.surveyId)}/>
                    </Grid>       
                </Grid>
            {/* :""} */}
            
                      
        </UserLayout>
    )
}