import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardNavigationCenter from './components/CardNavigationCenter'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'
import Loader from "../Loader/Loader";

export default function Survey() {
    const authUser = localStorage.getItem('authUser');
    const loginUser = JSON.parse(authUser);
    const navigate = useNavigate();
    const {id, sessionId} = useParams();
    const [selfEvaluations, setSelfEvaluations] = useState([]);
    const [currentSessionId, setCurrentSessionId] = useState('');
    const [surveyUser, setSurveyUser] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        sessionId  && sessionId !== undefined ? setCurrentSessionId(sessionId) : setCurrentSessionId('');
        id  && id !== undefined ? getSessionDetails(id):setSurveyUser([]);
        
        getSelfEvaluationList();
    }, [sessionId,id])

    const getSelfEvaluationList = async () => {
        let url = `/api/self_evaluations`;
        if(sessionId  && sessionId !== undefined){
            url = `/api/userselfevaluations?manager=${loginUser.id}`;
        }
        await axios.get(url).then(function (response) {
            let result = response.data;
            if (result.success) {                
                setSelfEvaluations(result.data);
                // console.log("result.data====",result.data)
                if(result.data && sessionId === undefined){
                    setCurrentSessionId(result.data[0].sessionId)
                }
            } else {
                // setIsError(true);
            }
        });
    };

    const getSessionDetails = async (userId) => {
        await axios.get(`/api/user?id=${userId}`).then(function (response) {
            let result = response.data;
            if (result.success) {   
                console.log("user=====",result.data)             
                setSurveyUser(result.data);
            } else {
                // setIsError(true);
            }
        });
    };

     

    return (
        <>
        <Loader loading={loading} />
        <UserLayout>            
            <Grid container className='d-flex align-items-center' spacing={6}>            
                <Grid item xs={12} md={5}>
                    <h1 className='mb-3'>{surveyUser && surveyUser.name ? "Questionnaire for "+surveyUser.name : "Self Evaluation" }</h1>
                </Grid>
                <Grid item xs={12} md={3} className='text-end'>
                    <label className="fs-14">Please Select Session:</label>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField fullWidth select
                        size='small'
                        ListboxProps={{
                            sx: { fontSize: 13,
                                fontFamily: 'Poppins' },
                          }}
                          sx={{
                            '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: 13,
                                fontFamily: 'Poppins', // Add your desired font family
                                fontWeight: '500', // Add your desired font weight
                            },
                          }}
                        label='Sessions'
                        id='sessionId'
                        value={currentSessionId}
                        onChange={(e) => setCurrentSessionId(e.target.value)}
                        disabled ={sessionId && sessionId !== undefined ? true : false}
                    >
                        {selfEvaluations.length > 0 ? 
                            selfEvaluations.map(selfEvaluation => (
                                <MenuItem key={selfEvaluation.sessionId} value={selfEvaluation.sessionId}>
                                    {selfEvaluation.session.name}
                                </MenuItem>
                            )) : (
                                <MenuItem value="" disabled>
                                    No sessions available
                                </MenuItem>
                            )
                        }
                    </TextField>
                </Grid>  
            </Grid>

            {
                currentSessionId > 0 ?
                <Grid container spacing={6}>            
                    <Grid item xs={12} md={12}>
                        <CardNavigationCenter currentSessionId={currentSessionId} currentSurveyUserId={id} surveyId={0} loading={loading} setLoading={setLoading}/>
                    </Grid>       
                </Grid>
                : ''
            }
            
                      
        </UserLayout>
        </>
    )
}