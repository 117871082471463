import React, { useEffect, useState }  from 'react';
import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import TableStickyHeader from '../Common/TableStickyHeader'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2'
import axios from 'axios';
import { StartSession } from './StartSession';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
}));

export default function List() {
    const [show, setShow] = useState(false);
    const [sessions, setSessions] = useState([]);
    const handleClose = () => setShow(false);

    useEffect(() => {
        const fetchData = async () => {
            await getList();
            // await getLastSession();
        };
    
        fetchData();
      }, []);

      const getList = async () => {
        try {
            const response = await axios.get('/api/sessions');
            let result = response.data;
            if (result.success) {
              setSessions(result.data);
            } 
        } catch (error) {
            console.error('Error fetching sessions:', error);
        }
    };
    
    const handleEndSession = (id) => {
      Swal.fire({
        title: "Are you sure you want to end this session?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
    }).then((result) => {
        if (result.isConfirmed) {
          axios.post(`api/sessions/end`, {id:id}).then(res => {
            if(res.data.success){
              Swal.fire(res.data.message, '', 'success').then(result => {
                  getList();
              })                
            } 
            else{
                Swal.fire(res.data.message, '', 'error')
            }
          }).catch((error) => {
              Swal.fire(error.message, '', 'info')
          });
        } else if (result.isDenied) {
          Swal.fire("Cancelled!", "", "info");
      }
  });
    };
    // const top100Films = [
    //   { label: 'The Shawshank Redemption', year: 1994 },
    //   { label: 'The Godfather', year: 1972 },
    //   { label: 'The Godfather: Part II', year: 1974 },
    //   { label: 'The Dark Knight', year: 2008 },
    //   { label: '12 Angry Men', year: 1957 },
    //   { label: "Schindler's List", year: 1993 },
    //   { label: 'Pulp Fiction', year: 1994 },
    // ];
    
    const columns = [];
    columns.push({ id: 'surveyName', label: 'Survey Name', minWidth: 150 });
    columns.push({ id: 'name', label: 'Session Name', minWidth: 150 });
    columns.push({ id: 'startDate', label: 'Start Date', minWidth: 150 });
    columns.push({ id: 'endDate', label: 'End Date', minWidth: 150 });
    columns.push({ id: 'type', label: 'Type', minWidth: 150 });
    columns.push({ id: 'action', label: 'Action'});

    return (
        <UserLayout>
            <div className="row d-flex align-items-center">
                <div className='col-md-8 col-12 mb-3'>
                    <h1>Session Manager</h1>
                </div>
                <div className='col-md-4 col-12 text-md-end mb-3'>
                    <Button size='small' variant='contained' onClick={() => setShow(true)}>
                        Start New Session
                    </Button>                    
                </div>
            </div>
            <Grid container spacing={6}>            
                <Grid item xs={12}>
                    <Card>
                        <TableStickyHeader columns={columns} rows={sessions}  handleEndSession ={handleEndSession}/>
                    </Card>
                </Grid>           
            </Grid>
            <StartSession show={show} handleClose={handleClose} getSessions={getList} />
        </UserLayout>
    )
}