import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import { useState, useEffect } from "react";
import axios from "axios";
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Swal from "sweetalert2";
import { Link, useNavigate } from 'react-router-dom'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';
import PollIcon from '@mui/icons-material/Poll';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CategoryIcon from '@mui/icons-material/Category';
import EventIcon from '@mui/icons-material/Event';  

import { Box, CardContent, Typography } from '@mui/material';


export default function Dashboard() {

    const navigate = useNavigate();
    const authUser = localStorage.getItem("authUser");
    const user = JSON.parse(authUser);
    const [cardData, setCardData] = useState([]);

    const [surveyevaluation, setSurveyevaluation] = useState([]);
    useEffect(() => {
        getCardDetails();
        getSurveyEvaluationList();
    }, []);

    
    const columns2 = [];
    columns2.push({ id: 'name', label: 'Session Name', minWidth: 150 });
    columns2.push({ id: 'startDate', label: 'Start Date', minWidth: 150 });
    columns2.push({ id: 'type', label: 'Type', minWidth: 150 });
    columns2.push({ id: 'action', label: 'Action'});

    const getCardDetails = async () => {
        try {
            const response = await axios.get('/api/getCardDetails');
            let result = response.data;
            // console.log("result==",result)
            if (result.success) {
              setCardData(result.data);
            } 
        } catch (error) {
            console.error('Error fetching sessions:', error);
        }
    };

    const getSurveyEvaluationList = async () => {
        await axios.get(`/api/userselfevaluations?manager=${user.id}`).then(function (response) {
            let result = response.data;
            if (result.success) {
                setSurveyevaluation(result.data);
            } else {
            }
        });
    };
    const handleManagerStatus = async (userId,managerStatus,sessionId) => {
        const dataToSubmit = [];
        dataToSubmit.push({
            userId: userId,
            managerStatus: managerStatus,
            managerId: user.id,
            sessionId: sessionId
        });
        try {

            if(managerStatus === 'reject'){
                Swal.fire({
                    title: 'Are you sure you want to reject?',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                  }).then( async (result) => {
                    if (result.isConfirmed) { 
                        const response = await axios.post("/api/userselfevaluation/update", { survey: dataToSubmit });
                        console.log(response.data.message);
                        Swal.fire(response.data.message, "", "success").then((result) => {
                            if(response.data.message=='Rejected') {
                                // refresh list
                                getSurveyEvaluationList()
                            }
                        });
                      } else if (result.isDenied) {
                        Swal.fire('Cancelled!', '', 'info')
                      }
                  })

            }
            else{
                const response = await axios.post("/api/userselfevaluation/update", { survey: dataToSubmit });
                console.log(response.data.message);
                Swal.fire(response.data.message, "", "success").then((result) => {
                    if(response.data.message=='Accepted') {
                        navigate(`/survey/${userId}/${sessionId}`)
                    }
                });
            }

        } catch (error) {
            console.error(error);
        }
    }
    const handleManagerContinue = async (userId,managerStatus,sessionId) => {
        navigate(`/survey/${userId}/${sessionId}`)
    }

    return (
        <UserLayout>
                <h1 className='mb-3'>Dashboard</h1>

                { user.role !== 'User' && (
                    <Grid container spacing={2} alignItems="center" className="mb-3">
                    { user.role === 'SuperAdmin' ? 

                            <Grid item className='col'>
                                <Link to="/companies" style={{ color: "#555", textDecoration: 'none' }}>
                                    <Card variant="outlined" sx={{ border: 'none', boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.1)', borderRadius: '12px', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }} className="card-body cardbody">
                                        <CardContent>
                                            <Typography className="small fw-semibold text-secondary mb-2">Total Companies</Typography>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Typography variant="h4" className="fw-bolder">{cardData.totalCompanies}</Typography>
                                                <Box className="mb-2 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                    <BusinessIcon fill="#fff" />
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        :
                        <>
                            <Grid item className='col'>
                                <Link to="/users" style={{ color: "#555", textDecoration: 'none' }}>
                                    <Card variant="outlined" sx={{ border: 'none', boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.1)', borderRadius: '12px', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }} className="card-body cardbody">
                                        <CardContent>
                                            <Typography className="small fw-semibold text-secondary mb-2">Total Users</Typography>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Typography variant="h4" className="fw-bolder">{cardData.totalUsers}</Typography>
                                                <Box className="mb-2 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                    <PeopleAltIcon fill="#fff" />
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                            <Grid item className='col'>
                                <Link to="/sessions" style={{ color: "#555", textDecoration: 'none' }}>
                                    <Card variant="outlined" sx={{ border: 'none', boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.1)', borderRadius: '12px', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }} className="card-body cardbody">
                                        <CardContent>
                                            <Typography className="small fw-semibold text-secondary mb-2">Active/Total Sessions</Typography>
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Typography variant="h4" className="fw-bolder">{cardData && (cardData.activeSessions || cardData.totalSessions) ? cardData.activeSessions +"/"+ cardData.totalSessions : ""}</Typography>
                                                <Box className="mb-2 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                    <EventIcon fill="#fff" />
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        {/* <div className="col mb-2">
                            <Link to="/users" style={{color:"#555"}}>
                                <div className="card border-0 shadow card-transform">
                                    <div className="card-body cardbody">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="small fw-semibold text-secondary mb-2">Total Users</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="h2 fw-bolder">{cardData.totalUsers}</p>
                                            <div className="mb-2 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                <PeopleAltIcon fill="#fff"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>                                
                        <div className="col mb-2">
                            <Link to="/sessions" style={{color:"#555"}}>
                                <div className="card border-0 shadow card-transform">
                                    <div className="card-body cardbody">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="small fw-semibold text-secondary mb-2">Active/Total Sessions</p>
                                            
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="h2 fw-bolder">{cardData.activeSessions}/{cardData.totalSessions}</p>
                                            <div className="mb-2 fs-22 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                <PeopleAltIcon fill="#fff"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div> */}
                        </>
                    }

                        <Grid item className='col'>
                            <Link to="/surveys" style={{ color: "#555", textDecoration: 'none' }}>
                                <Card variant="outlined" sx={{ border: 'none', boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.1)', borderRadius: '12px', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }} className="card-body cardbody">
                                    <CardContent>
                                        <Typography className="small fw-semibold text-secondary mb-2">Total Surveys</Typography>
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography variant="h4" className="fw-bolder">{cardData.totalSurveys}</Typography>
                                            <Box className="mb-2 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                <PollIcon fill="#fff" />
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        <Grid item className='col'>
                            <Link to="/competencies" style={{ color: "#555", textDecoration: 'none' }}>
                                <Card variant="outlined" sx={{ border: 'none', boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.1)', borderRadius: '12px', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }} className="card-body cardbody">
                                    <CardContent>
                                        <Typography className="small fw-semibold text-secondary mb-2">Total Competencies</Typography>
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography variant="h4" className="fw-bolder">{cardData.totalCompetencies}</Typography>
                                            <Box className="mb-2 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                <CategoryIcon fill="#fff" />
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        <Grid item className='col'>
                            <Link to="/competencies" style={{ color: "#555", textDecoration: 'none' }}>
                                <Card variant="outlined" sx={{ border: 'none', boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.1)', borderRadius: '12px', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }} className="card-body cardbody">
                                    <CardContent>
                                        <Typography className="small fw-semibold text-secondary mb-2">Total Questions</Typography>
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography variant="h4" className="fw-bolder">{cardData.totalQuestions}</Typography>
                                            <Box className="mb-2 card-icon rounded-circle text-white d-flex align-items-center justify-content-center">
                                                <QuestionMarkIcon fill="#fff" />
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    </Grid> 
                )}
                    

                {/* assessments requests list */}
                {surveyevaluation.length > 0 && (
                    <>
                        {user.role === 'Admin' && (<h2 className='fs-15 mb-3'>Assessment Requests</h2>)}
                        <Grid container spacing={6} className='mb-3'>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={6}>        
                                    {
                                        surveyevaluation.map((value, index) => (
                                            value.status === 0 ? 
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Card className='p-3'>
                                                    <div key={index}>
                                                        <div className="">
                                                            <h6 className='fs-13 fw-semibold'>Request for assessment</h6>
                                                            <hr></hr>                                                    
                                                            <p className='mb-4 fs-12'>You have a request to fill the <strong>{value.user_sessions_survey.name}</strong> survey in <strong>{value.session.name}</strong> session for <strong>{value.user_name.name}</strong>.</p>
                                                                {value.managerStatus === null ? (
                                                                    <div className='text-end'>
                                                                        <Button
                                                                            size='small'
                                                                            variant='contained'
                                                                            sx={{ marginRight: 3.5 }}
                                                                            onClick={() => handleManagerStatus(value.userId, 'accept', value.sessionId)}
                                                                        >
                                                                            Accept
                                                                        </Button>
                                                                        <Button
                                                                            size='small'
                                                                            variant='contained'
                                                                            onClick={() => handleManagerStatus(value.userId, 'reject', value.sessionId)}
                                                                        >
                                                                            Reject
                                                                        </Button>
                                                                    </div>
                                                                ) : value.managerStatus === 1 ? (
                                                                    <div className='text-end'>
                                                                        <Button
                                                                            size='small'
                                                                            variant='contained'
                                                                            onClick={() => handleManagerContinue(value.userId, 'continue', value.sessionId)}
                                                                        >
                                                                            Continue
                                                                        </Button>
                                                                    </div>
                                                                ) : null}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Grid>
                                            :""
                                        ))
                                    }                            
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            
        </UserLayout>
    )
}