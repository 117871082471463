import UserLayout from '../Layout/UserLayout'
// ** React Imports

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import { styled } from '@mui/material/styles'
import MuiTab from '@mui/material/Tab'
// ** Icons Imports
import AccountOutline from 'mdi-material-ui/AccountOutline'
import LockOpenOutline from 'mdi-material-ui/LockOpenOutline'
import InformationOutline from 'mdi-material-ui/InformationOutline'
// ** Demo Tabs Imports
import TabInfo from './components/TabInfo'
import TabAccount from './components/TabAccount'
import TabSecurity from './components/TabSecurity'
import axios from 'axios'
import { useEffect, useState } from 'react'

import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'

// ** Third Party Styles Imports
import 'react-datepicker/dist/react-datepicker.css'

const Tab = styled(MuiTab)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minWidth: 100
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 67
  }
}))

const TabName = styled('span')(({ theme }) => ({
  lineHeight: 1.71,
  fontSize: '0.875rem',
  marginLeft: theme.spacing(2.4),
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

export default function Account() {
    const [value, setValue] = useState('account')
    const authUser = localStorage.getItem('authUser');
    const user = JSON.parse(authUser);
    const [users, setUsers] = useState([]);
    
    
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get("activeTab");

    useEffect(() => {
        getList();
    }, [])
    
    const getList = async() => {
        await axios.get(`/api/user?id=${user.id}`)
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setUsers(result.data)
                if(activeTab !== '' && activeTab !== null){
                    setValue(activeTab)
                }
            }
        })
    }

    const handleChange = (event, newValue) => {
        console.log("newValue",newValue)
        setValue(newValue)
    }
    return (
        <UserLayout>
            <h1 className='mb-3'>My Account</h1>
            <Card>
                <TabContext value={value}>
                    <TabList
                    onChange={handleChange}
                    aria-label='account-settings tabs'
                    sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
                    >
                    <Tab
                        value='account'
                        label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AccountOutline />
                            <TabName>Account</TabName>
                        </Box>
                        }
                    />
                    <Tab
                        value='security'
                        label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LockOpenOutline />
                            <TabName>Security</TabName>
                        </Box>
                        }
                    />
                     {user.role === 'Admin' ?
                            <Tab
                                value='info'
                                label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <InformationOutline />
                                    <TabName>Info</TabName>
                                </Box>
                                }
                            />
                            :""
                    }
                    </TabList>

                    <TabPanel sx={{ p: 0 }} value='account'>
                        <TabAccount users={users}/>
                    </TabPanel>
                    <TabPanel sx={{ p: 0 }} value='security'>
                        <TabSecurity />
                    </TabPanel>
                    {user.role === 'Admin' && (
                         <>
                            <TabPanel sx={{ p: 0 }} value='info'>
                                <TabInfo />
                            </TabPanel>
                        </>
                     )}
                </TabContext>
                </Card>
        </UserLayout>
    )
}