import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
export const Header = () => {
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

            
    /* Method that will fix header after a specific scrollable */
            const isSticky = (e) => {
                const header = document.querySelector('.header');
                const scrollTop = window.scrollY;
                scrollTop >= 100 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
            };
    return (
        <header className="header ">
            
            <nav  className="navbar navbar-expand-lg navbar-light">
                <div className="container"><a className="navbar-brand">
                <img src="./images/logo.png" alt="..." width="150"/></a>
                    <button className="navbar-toggler navbar-toggler-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto d-flex align-items-center">
                            <li className="nav-item me-4">
                                <Link className="nav-link active" to="/">Home</Link>
                            </li>
                            <li className="nav-item me-4">
                                <Link className="nav-link" to="/about">About us</Link>
                            </li>   
                            <li className="nav-item me-4">
                                <Link className="nav-link" to="/features">Features</Link>
                            </li> 
                            <li className="nav-item me-4">
                                <Link className="nav-link" to="/pricing">Pricing</Link>
                            </li>   
                            <li className="nav-item me-4">
                                <Link className="nav-link" to="/faq">FAQ</Link>
                            </li>                    
                            <li className="nav-item me-4">
                                <Link className="nav-link" to="/contact">Contact us</Link>
                            </li>
                            <li className="nav-item me-4">
                                <Link to="/login" className="btn read_btn">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/signup" className="btn btn-theme">Signup</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;