import React, { useEffect, useState }  from 'react';
import axios from "axios";
// ** MUI Imports
import {Grid, Card, MenuItem, TextField, Button, Tooltip, IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams, useNavigate } from 'react-router-dom';

import UserLayout from '../Layout/UserLayout';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2'

const checkQuestionExists = async (name) => {
    let response = await axios.get(`/api/questions/isduplicate?name=${name}`)
    let result = response.data;
    if(result.success && result.exists === false){
        return true
    }
    else {
         return false
    }    
};

// const initialRows = [{ id: 1 }];
// const initialQuestionRows = [{ id: 1 }];
export const Add = () => {     
    const { competencyId } = useParams();
    const navigate = useNavigate();
    const [compentencies, setCompentencies] = useState([]);
    const [compentency, setCompentency] = useState([]);
    const [questionTypes, setQuestionTypes] = useState([]);
    // const [rows, setRows] = useState(initialRows);
    // const [questionRows, setQuestionRows] = useState(initialQuestionRows);
    

    useEffect(() => {          
        formik.setFieldValue('competencyId', competencyId)     
        getQuestionTypes();
        // getList();
        getCompetency();
    }, []);

    const getQuestionTypes = async() => {
        await axios.get('/api/questions/types')
        .then(function (response) {
            let result = response.data;
            if(result.success){
                setQuestionTypes(result.data)
            }
            else {
                // setIsError(true);
            }
        })
    }

    // const getList = async () => {
    //     await axios.get('/api/competencies')
    //     .then(function (response) {
    //         let result = response.data;
    //         if(result.success){
    //             setCompentencies(result.data)
    //             // formik.setFieldValue('competencyId', competencyId)
    //         }
    //         else {
    //             // setIsError(true);
    //         }
    //     })
    // }

    const getCompetency = async () => {
        await axios.get(`api/singleCompetency?id=${competencyId}`).then(function (response) {
            let result = response.data;
            if(result.success){
                setCompentency(result.data)         
                // if(result.data.questions && result.data.questions.length > 0)
                //     formik.setFieldValue('questions', result.data.questions);
            }
            else {
                // setIsError(true);
            }
        })
    }

    // const validationSchema = yup.object({
    //     competencyId: yup.string().required('Competency is required'),
    //     questions: yup.array().of(
    //       yup.object({
    //         questionTypeId: yup.string().required('Question Type is required'),
    //         name: yup.string().required('Name is required')
    //           .test('is-unique', 'Duplicate names are not allowed', function (value) {
    //             const questionsArray = formik.values.questions;
    //             const isDuplicate = questionsArray.filter(question => question.name === value).length > 1;
    //             return !isDuplicate;
    //           })
    //           .test('name check', 'Question already exists', value => checkQuestionExists(value) ),
    //         options: yup.array().of(
    //           yup.object({
    //             choice_name: yup.string().required('Option Name is required'),
    //             score: yup.number().required('Score is required')
    //                         //.positive('Score must be positive'),
    //           })
    //         ).when('questionTypeId', {
    //           is: (typeId) => [3, 4].includes(typeId),
    //           then: yup.array().min(1, 'At least one option is required'),
    //         }),
    //       })
    //     ),
    //   });

    // async function validateOptions(options, questionTypeId) {
    //     try {
    //       const errors = {};
    //       for (let i = 0; i < options.length; i++) {
    //         const option = options[i];
       
    //         if (questionTypeId in [3, 4]) {
    //           if (!option.choice_name) {
    //             errors[`options[<span className="math-inline">\{i\}\]\.choice\_name\`\] \= "Option Name is required"; \} if \(\!option\.score\) \{ errors\[\`options\[</span>{i}].score`] = "Score is required";
    //           }
    //         }
    //       }
       
    //       if (errors) {
    //         throw errors; // Throw errors object to signal validation failure
    //       }
       
    //       return null; // Indicate successful validation
    //     } catch (errors) {
    //       return errors; // Return errors object for handling in the caller
    //     }
    //    }

       const validationSchema = yup.object({
        competencyId: yup.string().required('Competency is required'),
        questions: yup.array().of(
            yup.object({
                questionTypeId: yup.string().required('Question Type is required'),
                name: yup.string().required('Name is required')
                    .test('is-unique', 'Duplicate names are not allowed', function (value) {
                        const questionsArray = formik.values.questions;
                        const isDuplicate = questionsArray.filter(question => question.name === value).length > 1;
                        return !isDuplicate;
                    })
                    .test('name check', 'Question already exists', value => checkQuestionExists(value)),
                options: yup.array().when('questionTypeId', {
                    is: (typeId) => [3, 4].includes(parseInt(typeId)),
                    then: yup.array().of(
                        yup.object().shape({
                            choice_name: yup.string().required('Option Name is required'),
                            score: yup.number().required('Score is required')
                        })
                    ),
                    otherwise: yup.array().notRequired()
                })
            })
        ),
    });
    // const validationSchema = yup.object({
    //     competencyId: yup.string().required('Competency is required'),
    //     questions: yup.array().of(
    //         yup.object({
    //         questionTypeId: yup.string().required('Question Type is required'),
    //         name: yup.string().required('Name is required')
    //             .test('is-unique', 'Duplicate names are not allowed', function (value) {
    //             const questionsArray = formik.values.questions;
    //             const isDuplicate = questionsArray.filter(question => question.name === value).length > 1;
    //             return !isDuplicate;
    //             })
    //             .test('name check', 'Question already exists', value => checkQuestionExists(value)),

                
    //         // options: yup.array().of(
    //         //     yup.object({
    //         //       choice_name: yup.string().test(
    //         //         'choice-name-required',
    //         //         'Option Name is required',
    //         //         (value, context) => {
    //         //           const questionTypeId = context.parent.questionTypeId;
    //         //           return validateOptions([value], questionTypeId).choice_name;
    //         //         }
    //         //       ),
    //         //       // ...
    //         //     })
    //         // )
    //         // yup.array().of(
    //         //     yup.object({
    //         //         choice_name: yup.string().when('$parent.questionTypeId', {
    //         //         is: (typeId) => {
    //         //             console.log('Choice Name - typeId:', typeId);
    //         //             [3, 4].includes(typeId)
    //         //         },
    //         //         then: yup.string().required('Option Name is required'),
    //         //         otherwise: yup.string(), // No validation if not 3 or 4
    //         //         }),
    //         //         score: yup.number().when('$parent.questionTypeId', {
    //         //         is: (typeId) => {
    //         //             console.log('Score - typeId:', typeId);
    //         //             [3, 4].includes(typeId)
    //         //         },
    //         //         then: yup.number().required('Score is required'),
    //         //         otherwise: yup.number(), // No validation if not 3 or 4
    //         //         }),
    //         //     })
    //         //     ),


    //         })
    //     ),
    // });

    const initialValues = {
        competencyId: '',
        questions: [
          {
            questionTypeId: '',
            name: '',
            options: [
              { choice_name: '', score: '' }
            ]
          }
        ]
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });
    

    const handleSubmit = (values) =>{
        console.log(values);
        // return false;
            axios.post(`api/questions/create`, values).then(res => {
                if(res.data.success){
                    Swal.fire(res.data.message, '', 'success').then(result => {                        
                        navigate("/competencies");
                    })                
                } 
                else{
                    Swal.fire(res.data.message, '', 'error')
                }
            }).catch((error) => {
                // console.log(error.response.data)
                Swal.fire(error.response.data.message, '', 'error')
            });      
    }

    const handleAddMoreQuestions = () => {
        const newQuestion = {
          questionTypeId: '',
          name: '',
          options: [
            { choice_name: '', score: '' }
          ]
        };
        formik.setFieldValue('questions', [...formik.values.questions, newQuestion]);
    };

    const handleRemoveQuestion = (questionIndex) => {
        const newQuestions = [...formik.values.questions];
        newQuestions.splice(questionIndex, 1);
        formik.setFieldValue('questions', newQuestions);
    };
    const handleQuestionTypeChange = (e, questionIndex) => {
      const newQuestions = [...formik.values.questions];
      newQuestions[questionIndex].questionTypeId = e.target.value;
      formik.setFieldValue('questions', newQuestions);
    };
    
    const handleQuestionNameChange = async (e, questionIndex) => {
      const newQuestions = [...formik.values.questions];
      newQuestions[questionIndex].name = e.target.value;
      formik.setFieldValue('questions', newQuestions);
    };
    
    const handleAddMoreOptions = (questionIndex) => {
        const newQuestions = [...formik.values.questions];
        newQuestions[questionIndex].options.push({ choice_name: '', score: '' });
        formik.setFieldValue('questions', newQuestions);
    };
      
    const handleRemoveOption = (questionIndex, optionIndex) => {
        const newQuestions = [...formik.values.questions];
        newQuestions[questionIndex].options.splice(optionIndex, 1);
        formik.setFieldValue('questions', newQuestions);
    };

    const handleOptionNameChange = (e, questionIndex, optionIndex) => {
        const newQuestions = [...formik.values.questions];
        newQuestions[questionIndex].options[optionIndex].choice_name = e.target.value;
        formik.setFieldValue('questions', newQuestions);
    };

    const handleOptionScoreChange = (e, questionIndex, optionIndex) => {
        const newQuestions = [...formik.values.questions];
        newQuestions[questionIndex].options[optionIndex].score = e.target.value;
        formik.setFieldValue('questions', newQuestions);
    };

    return (
        <UserLayout>
            <div className="row">
                <div className="col-md-10 mb-3">
                    <h1 className="mb-3">Add Questions for {compentency && compentency.name ? compentency.name : ''}</h1>
                </div> 
                <div className="col-md-2 text-end mb-3">
                    <Button 
                        fullWidth
                        type='button'
                        size='small'
                        variant='contained'
                        sx={{ marginBottom: 5 }}
                        onClick={() => navigate(-1)}
                    >
                        Go Back </Button>
                </div>                
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card sx={{padding: 8}}>
                        <form autoComplete="off" onSubmit={formik.handleSubmit}> 
                            {
                                formik.values.questions.map((question, qkey) => (
                                    <Grid className='d-flex align-items-center' container spacing={5} key={qkey}>
                                        <Grid item xs={12} md={4} sx={{marginBottom: 5}}>
                                            <TextField
                                                fullWidth
                                                ListboxProps={{
                                                    sx: { fontSize: 13,
                                                        fontFamily: 'Poppins' },
                                                  }}
                                                  sx={{
                                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                        fontSize: 13,
                                                        fontFamily: 'Poppins', // Add your desired font family
                                                        fontWeight: '500', // Add your desired font weight
                                                    },
                                                  }} 
                                                select
                                                size='small'
                                                label='Question Type'
                                                id='questionTypeId'
                                                value={question.questionTypeId}
                                                onChange={(e) => handleQuestionTypeChange(e, qkey)}                                                
                                                error={formik.touched.questions && formik.touched.questions[qkey]?.questionTypeId && Boolean(formik.errors.questions?.[qkey]?.questionTypeId)}
                                                helperText={formik.touched.questions && formik.touched.questions[qkey]?.questionTypeId && formik.errors.questions?.[qkey]?.questionTypeId}
                                            >
                                                <MenuItem value={""}>Please Select</MenuItem>
                                                {
                                                    questionTypes.length > 0 ? 
                                                    questionTypes.map(questionType => (
                                                        <MenuItem key={questionType.id} value={questionType.id}>{questionType.name}</MenuItem>
                                                    ))
                                                    : ''
                                                }
                                            </TextField>                                            
                                        </Grid>
                                        <Grid item xs={12} md={7} sx={{marginBottom: 5}}>
                                            <TextField
                                                fullWidth
                                                ListboxProps={{
                                                    sx: { fontSize: 13,
                                                        fontFamily: 'Poppins' },
                                                  }}
                                                  sx={{
                                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                        fontSize: 13,
                                                        fontFamily: 'Poppins', // Add your desired font family
                                                        fontWeight: '500', // Add your desired font weight
                                                    },
                                                  }}
                                                size='small'
                                                label='Question'
                                                id={`questions[${qkey}].name`}  // Adjusted the id attribute
                                                value={question.name}
                                                onChange={(e) => handleQuestionNameChange(e, qkey)}
                                                error={formik.touched.questions && formik.touched.questions[qkey]?.name && Boolean(formik.errors.questions?.[qkey]?.name)}
                                                helperText={formik.touched.questions && formik.touched.questions[qkey]?.name && formik.errors.questions?.[qkey]?.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1} sx={{marginBottom: 5}}>
                                            {
                                                qkey > 0 ? 
                                                <Tooltip size='small' className='ss_delete' title="Delete Question" sx={{ marginRight: 2 }}> 
                                                    <IconButton size='small' onClick={() => handleRemoveQuestion(qkey)}><DeleteIcon /></IconButton> 
                                                </Tooltip>
                                                : ''
                                            }                                            
                                        </Grid>

                                        
                             
                                        {[3, 4].includes(formik.values.questions[qkey].questionTypeId) && (
                                            <>
                                                {formik.values.questions[qkey].options.map((row, index) => (
                                                    <Grid container spacing={2} key={index} sx={{ marginLeft: 2, marginTop: 2}}>
                                                        <Grid id={`choice_${index}`} item xs={12} sm={12}>
                                                            <TextField  
                                                                ListboxProps={{
                                                                    sx: { fontSize: 13,
                                                                        fontFamily: 'Poppins' },
                                                                }}
                                                                sx={{
                                                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                                        fontSize: 13,
                                                                        fontFamily: 'Poppins', // Add your desired font family
                                                                        fontWeight: '500', // Add your desired font weight
                                                                        marginLeft: 2
                                                                    },
                                                                }}                                                   
                                                                size='small'
                                                                xs={6}
                                                                sm={6}
                                                                // sx={{ marginLeft: 2 }}
                                                                id={`choice_name_${index}`}
                                                                label='Option Name'
                                                                placeholder='Option Name'
                                                                onChange={(e) => handleOptionNameChange(e, qkey, index)}
                                                                name={`questions[${qkey}].options[${index}].choice_name`}
                                                                value={row.choice_name}
                                                                // onChange={(e) => formik.handleChange(e)}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.questions && formik.touched.questions[qkey]?.options && formik.touched.questions[qkey].options[index]?.choice_name && Boolean(formik.errors.questions?.[qkey]?.options?.[index]?.choice_name)}
                                                                helperText={formik.touched.questions && formik.touched.questions[qkey]?.options && formik.touched.questions[qkey].options[index]?.choice_name && formik.errors.questions?.[qkey]?.options?.[index]?.choice_name}
                                                            />
                                                            
                                                            <TextField
                                                                size='small'
                                                                xs={6}
                                                                sm={6}
                                                                ListboxProps={{
                                                                    sx: { fontSize: 13,
                                                                        fontFamily: 'Poppins' },
                                                                }}
                                                                sx={{
                                                                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                                                        fontSize: 13,
                                                                        fontFamily: 'Poppins', // Add your desired font family
                                                                        fontWeight: '500', // Add your desired font weight
                                                                        marginLeft: 2
                                                                    },
                                                                }} 
                                                                id={`score_${index}`}
                                                                label='Score'
                                                                placeholder='Score'
                                                                onChange={(e) => handleOptionScoreChange(e, qkey, index)}
                                                                name={`questions[${qkey}].options[${index}].score`}
                                                                value={row.score}
                                                                type="number"
                                                                // onChange={(e) => formik.handleChange(e)}
                                                                onBlur={formik.handleBlur}
                                                                error={formik.touched.questions && formik.touched.questions[qkey]?.options && formik.touched.questions[qkey].options[index]?.score && Boolean(formik.errors.questions?.[qkey]?.options?.[index]?.score)}
                                                                helperText={formik.touched.questions && formik.touched.questions[qkey]?.options && formik.touched.questions[qkey].options[index]?.score && formik.errors.questions?.[qkey]?.options?.[index]?.score}
                                                            />
                                                            {
                                                                index > 0 ? 
                                                                <Tooltip size='small' className='bg-theme text-white' title="Delete Question" sx={{ marginRight: 2 }}> 
                                                                    <IconButton size='small' onClick={() => handleRemoveOption(qkey, index)}><DeleteIcon /></IconButton> 
                                                                </Tooltip>
                                                                : ''
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                ))} 
                                                <Grid item xs={12} md={3} sx={{marginBottom: 5}}>                                  
                                                    <Button sx={{ marginRight: 2, marginLeft: 2 }} variant='outlined' onClick={() => handleAddMoreOptions(qkey)}>
                                                        Add new option
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}



                                   </Grid> 
                                ))
                            }
                            <Link className='fs-13 fw-semibold' sx={{ marginRight: 3.5 }} variant='outlined' onClick={handleAddMoreQuestions}>
                                Add Question
                            </Link>
                            <Grid sx={{marginTop: 5}}>
                                <Button type='submit' variant='contained'>Save</Button>   
                            </Grid>
                            
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </UserLayout>

        
    );
}

export default Add;