import UserLayout from '../Layout/UserLayout'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import CardNavigationCenter from './components/CardNavigationCenter'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MenuItem, TextField } from '@mui/material'

export default function SampleSurvey() {
    const {id} = useParams();   



    return (
        <UserLayout>            
            <Grid container spacing={6}>            
                <Grid item xs={12} md={5}>
                    <h1 className='mb-3'>Sample Format</h1>
                </Grid> 
            </Grid>

            <Grid container spacing={6}>            
                <Grid item xs={12} md={12}>
                    <CardNavigationCenter currentSessionId={0} currentSurveyUserId={0} surveyId={id}/>
                </Grid>       
            </Grid>
            
                      
        </UserLayout>
    )
}